import Global from "@styles/Global";

export const tabBarContainer = {
  backgroundColor: "#F6F7FF",
  width: 80,
  ...Global.shadow,
  zIndex: 10,
  borderRadius: 25,
  height: "90vh",
  border: "solid 2px #DEE0F3",
};

export const tabBarSubContainer = (gutters) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
  };
};

export const tabBarLogoStyle = {
  cursor: "pointer",
  width: 40,
  height: "auto",
  textAlign: "center",
  marginTop: "16px",
};

export const tabBarMenu = {
  display: "flex",
  flexDirection: "column",
};

export const tabBarMenuItem = (match, Palette) => {
  return {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "500",
    color: match ? Palette.blue : null,
  };
};

export const tabBarNotifAndExitContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "16px",
};

export const tabBarNotificationContainer = {
  position: "relative",
};
