// HOSTAZL FACTURATION

import { FaAmbulance, FaCarSide } from "react-icons/fa";

export default {
  VSL: {
    value: "VSL",
    name: "VSL",
    icon: FaCarSide,
  },
  AMBULANCE: {
    value: "AMBULANCE",
    name: "Ambulance",
    icon: FaAmbulance,
  },
};
