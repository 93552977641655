import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import TransportResume from "../../../components/TransportResume";
import Palette from "../../../styles/Palette";
import moment from "moment";
import { toast } from "react-hot-toast";
import Colors from "../../../styles/Colors";
import { useGlobal } from "reactn";
import {
  acceptTransport,
  assignTransport,
  createNotification,
} from "../../../services/Firebase";
import firebase from "firebase/compat/app";
import { useAuth } from "../../../providers/Auth";
import { notificationTypes } from "../../../helpers/notifications";

export default () => {
  const { user } = useAuth();
  const [transport, setTransport] = useGlobal("transportToAssignAndChangePEC");
  const [, setTransportToAssign] = useGlobal("transportToAssign");
  const isVisible = !!transport;

  const [demDate, setDemDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [viewState, setViewState] = useState("EDIT"); // EDIT / CONFIRM
  const [assignType, setAssignType] = useState("NOW"); // NOW / LATER

  const now = moment();
  const currentHour = now.hour();
  const currentMinute = now.minute();

  useEffect(() => {
    console.log(demDate);
  }, [demDate]);

  useEffect(() => {
    if (isVisible) {
      setStartDate(transport.startDate);
    } else {
      setDemDate(null);
      setStartDate(null);
      setViewState("EDIT");
      setAssignType("NOW");
    }
  }, [isVisible, transport]);

  const onPressEdit = async (assignType) => {
    try {
      setAssignType(assignType);
      if (moment(startDate).isSameOrAfter(transport.endDate)) {
        throw new Error(
          "L'heure de prise en charge doit être antérieure à l'heure de rendez-vous"
        );
      }
      if (moment(demDate).isAfter(transport.startDate)) {
        throw new Error(
          "L'heure de démarrage doit être antérieure ou égale à l'heure de prise en charge"
        );
      }
      if (moment(startDate).isSame(transport.startDate)) {
        if (assignType === "NOW") {
          if (transport.goToTransportId) {
            await setTransportToAssign({
              ...transport,
              demDate: moment(transport.demDate)
                .set({ hour: currentHour, minute: currentMinute })
                .toDate(),
              startDate: moment(transport.startDate)
                .set({ hour: currentHour, minute: currentMinute })
                .toDate(),
              regulatedBy: user.id,
              bourseType: firebase.firestore.FieldValue.delete(),
              bourseGroup: firebase.firestore.FieldValue.delete(),
            });
            // await assignTransport(
            //   transport.id,
            //   {
            //     demDate: moment(transport.demDate)
            //       .set({ hour: currentHour, minute: currentMinute })
            //       .toDate(),
            //     startDate: moment(transport.startDate)
            //       .set({ hour: currentHour, minute: currentMinute })
            //       .toDate(),
            //   },
            //   transport
            // );
          } else {
            await setTransportToAssign({
              ...transport,
              demDate,
              regulatedBy: user.id,
              bourseType: firebase.firestore.FieldValue.delete(),
              bourseGroup: firebase.firestore.FieldValue.delete(),
            });
          }

          if (transport.returnTransportId) {
            //TODO: NOTIF HERE RETURN ID
            await acceptTransport(
              transport.returnTransportId,
              { demDate },
              transport
            );
          }
        } else if (assignType === "LATER") {
          await acceptTransport(transport.id, { demDate }, transport);
          // await createNotification(
          //   transport,
          //   "TRANSPORT_ACCEPTED",
          //   "REGULATOR & HOSPITAL"
          // );
          if (transport.returnTransportId) {
            await acceptTransport(
              transport.returnTransportId,
              { demDate },
              transport
            );
            //TODO: NOTIF HERE RETURN
            // await createNotification(
            //   transport,
            //   "TRANSPORT_ACCEPTED",
            //   "REGULATOR & HOSPITAL"
            // );
            toast.success("Transport A/R accepté.");
          } else {
            toast.success("Transport accepté.");
          }
        }
        await setTransport(null);
      } else {
        setViewState("CONFIRM");
      }
    } catch (e) {
      console.log(e);
      // toast.error(e.message);
    }
  };

  const onPressConfirm = async () => {
    try {
      if (assignType === "NOW") {
        await setTransportToAssign({
          ...transport,
          startDate,
          demDate,
          regulatedBy: user.id,
          bourseType: firebase.firestore.FieldValue.delete(),
          bourseGroup: firebase.firestore.FieldValue.delete(),
        });
        await setTransport(null);
      } else {
        if (transport.returnTransportId) {
          await acceptTransport(
            transport.id,
            {
              startDate,
              demDate,
            },
            transport
          );
          //TODO: NOTIF HERE RETURN ID
          await acceptTransport(
            transport.returnTransportId,
            {
              startDate,
              demDate,
            },
            transport
          );
          toast.success("Transport A/R accepté et modifié.");
          await setTransport(null);
        }
        await acceptTransport(
          transport.id,
          {
            startDate,
            demDate,
          },
          transport
        );
        toast.success("Transport accepté et modifié.");
        await setTransport(null);
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };

  if (!isVisible) return null;

  if (viewState === "EDIT")
    return (
      <Modal
        showPrevButton={false}
        onClose={() => setTransport(null)}
        style={{ width: 500 }}
        onTitle={() => (
          <div
            className={"containerCenter"}
            style={{
              fontSize: 20,
              fontWeight: 500,
            }}
          >
            <div style={{ marginRight: 10 }}>Voulez-vous attribuer le</div>
            <div style={{ color: Palette.red }}>
              Transport {transport.id?.slice(0, 5)}
            </div>
          </div>
        )}
        onFooter={() => (
          <div
            className={"containerCenter"}
            style={{ width: 166, justifyContent: "center" }}
          >
            <Button
              text={"Maintenant"}
              containerStyle={{ marginBottom: 10 }}
              fullWidth
              onClick={() => onPressEdit("NOW")}
            />
            <Button
              text={"Plus tard"}
              variant={"primaryInfo"}
              containerStyle={{ marginBottom: 10 }}
              fullWidth
              onClick={() => onPressEdit("LATER")}
            />
            <Button
              text={"Retour"}
              variant={"secondary"}
              fullWidth
              onClick={() => setTransport(null)}
            />
          </div>
        )}
      >
        <div
          className={"container"}
          style={{ marginBottom: 20, flex: 1, alignItems: "center" }}
        >
          <TransportResume
            editable
            transport={transport}
            onChange={setStartDate}
            onChangeDem={setDemDate}
            variant={"MODAL"}
            containerStyle={{ width: 400 }}
          />
        </div>
      </Modal>
    );

  if (viewState === "CONFIRM")
    return (
      <Modal
        style={{ width: 334 }}
        showPrevButton={false}
        onClose={() => setViewState("EDIT")}
        onTitle={() => (
          <div
            className={"containerCenter"}
            style={{
              fontSize: 15,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Etes-vous sûr de vouloir changer l’horaire de PEC ?
          </div>
        )}
        onFooter={() => (
          <div
            className={"containerCenter"}
            style={{ width: 166, justifyContent: "center" }}
          >
            <Button
              text={"Modifier"}
              containerStyle={{ height: 40, marginBottom: 10 }}
              fullWidth
              onClick={onPressConfirm}
            />

            <Button
              onClick={() => setViewState("EDIT")}
              text={"Annuler"}
              variant={"secondary"}
              containerStyle={{ height: 40 }}
              fullWidth
            />
          </div>
        )}
      >
        <div
          className={"containerRowCenter"}
          style={{ marginBottom: 20, flex: 1, alignItems: "center" }}
        >
          <div className={"containerCenter"}>
            <div style={{ fontSize: 12, color: Colors.text.secondary }}>
              AVANT
            </div>
            <div style={{ fontSize: 15, fontWeight: 500, color: Palette.red }}>
              {moment(transport?.startDate).format("HH:mm")}
            </div>
          </div>
          <img
            src={"/icons/arrow.png"}
            style={{
              marginLeft: 10,
              marginRight: 10,
              width: 12,
              height: 14,
              transform: "rotate(90deg)",
            }}
          />
          <div className={"containerCenter"}>
            <div style={{ fontSize: 12, color: Colors.text.secondary }}>
              APRES
            </div>
            <div style={{ fontSize: 15, fontWeight: 500, color: Palette.red }}>
              {moment(startDate).format("HH:mm")}
            </div>
          </div>
        </div>
      </Modal>
    );
};
