import Button from "../Button";
import Palette from "../../styles/Palette";
import Colors from "../../styles/Colors";
import React from "react";
import { useAuth } from "../../providers/Auth";
import { RiShareForwardFill } from "react-icons/ri";
import firebase from "firebase/compat/app";

export default ({ transport, setAssignMode, containerStyle, actionStyle }) => {
  const { carType, driver } = transport;
  const { user } = useAuth();
  const { uid } = firebase.auth().currentUser;

  return (
    <div
      className={"container flex"}
      style={{
        ...containerStyle,
        position: "relative",
      }}
    >
      <div style={{ fontSize: 17, fontWeight: 500 }}>Véhicule</div>
      <div className={"containerCenter"} style={{ fontSize: 13, flex: 1 }}>
        <div className={"containerRowCenter"}>
          <img
            src={`/icons/${carType === "AMBULANCE" ? "ambulance" : "car"}.png`}
            style={{ width: 30, height: 30 }}
          />
          {user?.role === "REGULATOR" && (
            <div className={"containerCenter"} style={{ marginLeft: 20 }}>
              <div>{carType === "AMBULANCE" ? "Ambulance" : "VSL"}</div>
              {driver && (
                // TODO: AJOUTER EQUIPE/EQUIPIER au lieu de juste le nom du driver
                <div style={{ color: Palette.red }}>{driver.name}</div>
              )}
            </div>
          )}
        </div>
        {user?.role === "REGULATOR" ? (
          driver && (
            <div style={{ color: Colors.text.secondary, marginTop: 10 }}>
              {driver.plateNumber}
            </div>
          )
        ) : (
          <div style={{ fontSize: 18, marginTop: 10 }}>
            {carType === "AMBULANCE" ? "Ambulance" : "VSL"}
          </div>
        )}
      </div>
      {user?.role === "REGULATOR" &&
        transport.status === "ACCEPTED" &&
        transport.regulatedBy === uid && (
          <Button
            tooltip={"Assigner"}
            onClick={() => setAssignMode(true)}
            variant="secondarySuccess"
            size="smallIcon"
            IconLeft={RiShareForwardFill}
            containerStyle={{
              position: "absolute",
              top: 0,
              right: 0,
              ...actionStyle,
            }}
          />
        )}
    </div>
  );
};
