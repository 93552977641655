import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Palette from "../../../../styles/Palette";
import InputPlacesAuto from "../../../../components/InputPlacesAuto";
import Input from "../../../../components/Input";
import SelectAddressType from "../../../../components/SelectAddressType";

export default forwardRef(
  (
    { type = "START", containerStyle, place = {}, setPlace, patient = {} },
    ref
  ) => {
    const [addressType, setAddressType] = useState(
      place?.addressType || "HOME"
    );
    const [name, setName] = useState(place.name || "");
    const [address, setAddress] = useState(place.address || "");
    const [complement, setComplement] = useState(place.complement || "");
    const [service, setService] = useState(place.service || "");
    const [establishment, setEstablishment] = useState(
      place.establishment || ""
    );
    const [chamber, setChamber] = useState(place.chamber || "");
    const [postalCode, setPostalCode] = useState(place.postalCode || "");
    const [city, setCity] = useState(place.city || "");
    const [phoneNumber, setPhoneNumber] = useState(place.phoneNumber || "");
    const [moreNotes, setMoreNotes] = useState(place.moreNotes || "");

    const validateInputs = () => {
      const placeTypeName = type === "START" ? "de départ" : "de l'arrivée";
      if (!name) throw new Error(`L'intitulé ${placeTypeName} est requis`);
      if (!address) throw new Error(`L'adresse ${placeTypeName} est requise`);
      if (!postalCode)
        throw new Error(`Le code postal ${placeTypeName} est requis`);
      if (!city) throw new Error(`La ville ${placeTypeName} est requise`);
    };

    const handleAutocomplete = ({
      name,
      address,
      streetNumber,
      route,
      city,
      postalCode,
      phoneNumber,
    }) => {
      setName(name || "");
      setAddress(address || `${streetNumber || ""} ${route || ""}`);
      setCity(city || "");
      setPostalCode(postalCode || "");
      setPhoneNumber(phoneNumber || "");
    };

    const deps = [
      name,
      address,
      complement,
      service,
      postalCode,
      city,
      phoneNumber,
      moreNotes,
      addressType,
    ];

    useImperativeHandle(
      ref,
      () => ({
        validateInputs,
      }),
      deps
    );

    useEffect(() => {
      setPlace({
        name,
        address,
        complement,
        service,
        establishment,
        chamber,
        postalCode,
        city,
        phoneNumber,
        moreNotes,
        addressType,
      });
    }, deps);

    return (
      <div className={"container flex"} style={containerStyle}>
        <div
          style={{
            fontSize: 20,
            fontWeight: 600,
            color: Palette.red,
            marginBottom: 10,
          }}
        >
          {type === "START" ? "Départ" : "Arrivée"}
        </div>
        <div className={"containerRow"}>
          <div className={"container flex"}>
            <div className={"containerRow"} style={{ marginBottom: 10 }}>
              <InputPlacesAuto
                className={"flex"}
                types={["establishment"]}
                placeholder={"Intitulé"}
                style={{ marginRight: 5 }}
                value={name}
                onChange={setName}
                onSelect={handleAutocomplete}
                iconRight={"/icons/search.png"}
                suggestions={
                  patient
                    ? [
                        {
                          ...patient.address,
                          name: "Domicile",
                          phoneNumber: patient.phoneNumber,
                        },
                      ]
                    : []
                }
              />
              <SelectAddressType
                onSelect={setAddressType}
                selected={addressType}
              />
            </div>
            <InputPlacesAuto
              types={["address"]}
              placeholder={"Rue"}
              style={{ marginBottom: 10 }}
              value={address}
              onChange={setAddress}
              onSelect={handleAutocomplete}
              iconRight={"/icons/search.png"}
            />
            <div className={"containerRow"} style={{ marginBottom: 10 }}>
              <Input
                required
                value={postalCode}
                onChange={setPostalCode}
                placeholder={"Code postal"}
              />
              <Input
                required
                value={city}
                onChange={setCity}
                placeholder={"Ville"}
                style={{ marginLeft: 10 }}
              />
            </div>
            <Input
              placeholder={"Téléphone"}
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </div>
          <div
            className={"container flex"}
            style={{
              marginLeft: 20,
            }}
          >
            {addressType !== "ESTABLISHMENT" && (
              <Input
                value={complement}
                onChange={setComplement}
                placeholder={"Complément"}
                style={{ marginBottom: 10 }}
              />
            )}
            {addressType === "ESTABLISHMENT" && (
              <>
                <Input
                  placeholder={"Service"}
                  style={{ marginBottom: 10 }}
                  value={service}
                  onChange={setService}
                />
                <div className={"containerRow"} style={{ marginBottom: 10 }}>
                  <Input
                    placeholder={"Batiment"}
                    style={{ marginRight: 10 }}
                    value={establishment}
                    onChange={setEstablishment}
                  />
                  <Input
                    placeholder={"Chambre"}
                    value={chamber}
                    onChange={setChamber}
                  />
                </div>
              </>
            )}
            <Input
              multiline
              placeholder={"Autres informations..."}
              value={moreNotes}
              onChange={setMoreNotes}
            />
          </div>
        </div>
      </div>
    );
  }
);
