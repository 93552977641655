import React, { useState } from "react";
import Global from "../../styles/Global";
import { DateRangePicker } from "react-dates";

export default ({
  containerStyle,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <div
      className={"flex"}
      style={{
        ...Global.item,
        position: "relative",
        maxWidth: 320,
        justifyContent: "center",
        ...containerStyle,
      }}
    >
      <DateRangePicker
        startDate={startDate} // momentPropTypes.momentObj or null,
        startDateId="startDate" // PropTypes.string.isRequired,
        endDate={endDate} // momentPropTypes.momentObj or null,
        endDateId="endDate" // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) => {
          if (startDate?.isBefore(endDate)) {
            setStartDate(startDate);
          }
          if (endDate?.isAfter(startDate)) {
            setEndDate(endDate);
          }
        }} // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={(focusedInput) => {
          console.log(focusedInput);
          setFocusedInput(focusedInput);
        }} // PropTypes.func.isRequired,
      />
    </div>
  );
};
