import React from "react";
import "./style.css";
import { useHistory } from "react-router-dom";

export default function LayoutAuth({ containerStyle = {}, children }) {
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
        background: "linear-gradient(0deg, #FBFBFD 33.93%, #F5F6FA 100%)",
        ...containerStyle,
      }}
    >
      <img
        onClick={() => history.push("/")}
        style={{
          cursor: "pointer",
          height: 42,
          position: "absolute",
          top: 15,
          left: 100,
        }}
        src="/logoFull.png"
      />
      <div
        style={{
          width: 820,
          maxHeight: "90vh",
        }}
      >
        <div
          style={{
            paddingLeft: 140,
            paddingRight: 140,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
