import React from "react";
import { STATUS } from "../helpers/status";

export default ({ transport, user }) => {
  let finalStatus = transport.status;
  let displayName = STATUS[transport?.status]?.name || "";

  if (transport?.regulatedBy === "BOURSE" && transport?.createdBy !== user) {
    finalStatus = "BOURSE";
    displayName = STATUS[finalStatus]?.name || "";
  } else if (transport?.status === "CANCELED" && transport?.cancelBy) {
    let cancelLabel = "";

    switch (transport?.cancelBy) {
      case "HOSPITAL":
        cancelLabel = "hôpital";
        break;
      case "REGULATOR":
        cancelLabel = "ambulancier";
        break;
    }

    finalStatus = "CANCELED";
    displayName += cancelLabel;
  }

  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: 600,
        padding: 5,
        borderRadius: 5,
        fontSize: 15,
        ...STATUS[finalStatus]?.style,
      }}
    >
      {displayName}
    </div>
  );
};
