import {
  addPatient,
  updateTransport,
  addTransports,
} from "../services/Firebase";
import firebase from "firebase/compat/app";
import moment from "moment";

function generateId() {
  return firebase.firestore().collection("transports").doc().id;
}

// eslint-disable-next-line no-unused-vars
function createSimpleTransports({ transport }) {
  transport.id = generateId();
  let returnTransport = null;
  if (transport.returnDate) {
    const { returnDate, returnEndDate } = Object.assign({}, transport);
    delete transport.returnDate;
    delete transport.returnEndDate;
    returnTransport = {
      ...transport,
      startDate: returnDate,
      endDate: returnEndDate,
      from: transport.to,
      to: transport.from,
      goToTransportId: transport.id,
      isReturn: true,
      id: generateId(),
      hasUndefinedHour: true,
    };
    transport.returnTransportId = returnTransport.id;
  }

  if (returnTransport) {
    return [transport, returnTransport];
  } else {
    return [transport];
  }
}

// eslint-disable-next-line no-unused-vars
function createSerialTransports({ transport: baseTransport, dates }) {
  const transports = [];

  dates.forEach((date) => {
    const transport = Object.assign({}, baseTransport);
    transport.id = generateId();
    const day = moment(date).format("DD/MM/YYYY");
    const startHour = moment(transport.startDate).format("HH:mm");
    const endHour = moment(transport.endDate).format("HH:mm");
    transport.startDate = moment(
      `${day} ${startHour}`,
      "DD/MM/YYYY HH:mm"
    ).toDate();
    transport.endDate = moment(
      `${day} ${endHour}`,
      "DD/MM/YYYY HH:mm"
    ).toDate();

    let returnTransport = null;
    if (transport.returnHour) {
      const { returnHour: returnHourDate } = Object.assign({}, transport);
      delete transport.returnHour;
      const returnHour = moment(returnHourDate).format("HH:mm");
      const returnDate = moment(
        `${day} ${returnHour}`,
        "DD/MM/YYYY HH:mm"
      ).toDate();
      returnTransport = {
        ...transport,
        startDate: returnDate,
        endDate: returnDate,
        from: transport.to,
        to: transport.from,
        returnTransportId: transport.id,
        id: generateId(),
        hasUndefinedHour: true,
      };
      transport.returnTransportId = returnTransport.id;
    }

    if (returnTransport) {
      transports.push(transport, returnTransport);
    } else {
      transports.push(transport);
    }
  });

  return transports;
}

export default async ({
  patient,
  transportInformations,
  from,
  to,
  complement,
  regulatedBy,
  role,
  transportId,
  dates,
}) => {
  const transport = {
    ...transportInformations,
    ...complement,
    patient,
    from,
    to,
  };
  // Check if patient have to be created
  if (patient && !patient.id) {
    const { id } = await addPatient(patient);
    patient.id = id;
  }
  if (transportId !== "new") {
    console.log("update transport id", transportId);
    await updateTransport(transportId, transport);
    // await createNotification(transportId, transport);
  } else {
    let transports = null;
    if (transport.recurringType === "RECURRING") {
      delete transport.recurringType;
      transports = createSerialTransports({ transport, dates });
    } else {
      transports = createSimpleTransports({ transport });
    }
    console.log(role);
    await addTransports(transports, role);
  }
};
