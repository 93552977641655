import { create } from "zustand";

export const useZustandStore = create()((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),
  driverID: "",
  setDriverID: (driverID) => set({ driverID }),
  transports: [],
  setTransports: (transports) => set({ transports }),
  allTransports: [],
  setAllTransports: (allTransports) => set({ allTransports }),
  transport: null,
  setTransport: (transport) => set({ transport }),
  transport2: null,
  setTransport2: (transport2) => set({ transport2 }),
  transportsEnded: [],
  setTransportsEnded: (transportsEnded) => set({ transportsEnded }),
  transportsFromNotifications: [],
  setTransportsFromNotifications: (transportsFromNotifications) =>
    set({ transportsFromNotifications }),
  tripDistanceAndDuration: {},
  setTripDistanceAndDuration: (tripDistanceAndDuration) =>
    set({ tripDistanceAndDuration }),
  nextDriverTransports: [],
  setNextDriverTransports: (nextDriverTransports) =>
    set({ nextDriverTransports }),
  allNextTransports: [],
  setAllNextTransports: (allNextTransports) => set({ allNextTransports }),
  currentTransportyNotifTime: [],
  setCurrentTransportyNotifTime: (currentTransportyNotifTime) =>
    set({ currentTransportyNotifTime }),
  globalRef: {},
  setGlobalRef: (globalRef) => set({ globalRef }),
  isMapDragEnd: false,
  setIsMapDragEnd: (isMapDragEnd) => set({ isMapDragEnd }),
  reverseGeoCodingAdress: "",
  setReverseGeoCodingAdress: (reverseGeoCodingAdress) =>
    set({ reverseGeoCodingAdress }),
  isDrawerOpen: false,
  setIsDrawerOpen: (isDrawerOpen) => set({ isDrawerOpen }),
  refresh: false,
  setRefresh: (refresh) => set({ refresh }),
  driverHandleClick: null,
  setDriverHandleClick: (driverHandleClick) => set({ driverHandleClick }),
  availableDriver: null,
  setAvailableDriver: (availableDriver) => set({ availableDriver }),
  trackedVehiculeTransport: null,
  setTrackedVehiculeTransport: (trackedVehiculeTransport) => set({ trackedVehiculeTransport })
}));
