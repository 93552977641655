import styled from "styled-components";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { RiCloseLine } from "react-icons/ri";
import Palette from "../../styles/Palette";
import Dropdown from "../Dropdown";
import Input from "../Input";
import Loading from "../Loading";
import Global from "../../styles/Global";

const Row = styled.div`
  cursor: pointer;
  transition: 0.25s;
  &:hover {
    transition: 0.25s;
    background-color: rgba(87, 107, 255, 0.1);
    color: #506bff;
  }
`;

export default ({
  containerStyle,
  selectedFilter,
  setSelectedFilter,
  transports,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const { uid } = firebase.auth().currentUser;

  useEffect(() => {
    handleSearch(search);
  }, [search]);

  function handleSearch(text) {
    if (text.length > 1) {
      const tmpResults = [];
      transports?.forEach((t) => {
        if (
          t.patient?.firstName.includes(text) ||
          t.patient?.lastName.includes(text)
        ) {
          const { id, firstName, lastName } = t.patient;
          if (
            !tmpResults.find((r) => r.type === "PATIENT" && r.value.id === id)
          ) {
            tmpResults.push({
              value: { id, firstName, lastName },
              type: "PATIENT",
            });
          }
        } else if (t.from?.name.includes(text)) {
          const { name } = t.from;
          if (
            !tmpResults.find(
              (r) => r.type === "ADDRESS_NAME" && r.value === name
            )
          ) {
            tmpResults.push({ value: name, type: "ADDRESS_NAME" });
          }
        } else if (t.to?.name.includes(text)) {
          const { name } = t.to;
          if (
            !tmpResults.find(
              (r) => r.type === "ADDRESS_NAME" && r.value === name
            )
          ) {
            tmpResults.push({ value: name, type: "ADDRESS_NAME" });
          }
        } else if (t.from?.address.includes(text)) {
          const { address } = t.from;
          if (
            !tmpResults.find(
              (r) => r.type === "ADDRESS_ADDRESS" && r.value === address
            )
          ) {
            tmpResults.push({ value: address, type: "ADDRESS_ADDRESS" });
          }
        } else if (t.to?.address.includes(text)) {
          const { address } = t.to;
          if (
            !tmpResults.find(
              (r) => r.type === "ADDRESS_ADDRESS" && r.value === address
            )
          ) {
            tmpResults.push({ value: address, type: "ADDRESS_ADDRESS" });
          }
        }
      });
      setResults(tmpResults);
    } else {
      setResults([]);
    }
  }

  return (
    <div
      className={"flex"}
      style={{
        ...Global.item,
        position: "relative",
        maxWidth: 320,
        ...containerStyle,
      }}
    >
      <div
        className={"flex containerRowCenter"}
        onClick={() => setDropdownVisible((v) => !v)}
        style={{ cursor: "pointer" }}
      >
        {selectedFilter ? (
          <div>
            {selectedFilter.type === "PATIENT" &&
              `${selectedFilter.value.firstName} ${selectedFilter.value.lastName}`}
            {(selectedFilter.type === "ADDRESS_NAME" ||
              selectedFilter.type === "ADDRESS_ADDRESS") &&
              selectedFilter.value}
          </div>
        ) : (
          <Input
            required
            style={{ height: 40 }}
            containerStyle={{ width: "100%" }}
            value={search}
            onChange={setSearch}
          />
        )}
      </div>
      {selectedFilter && (
        <RiCloseLine
          onClick={() => setSelectedFilter(null)}
          size={32}
          color={Palette.red}
          style={{ position: "absolute", right: 10, cursor: "pointer" }}
        />
      )}
      <Dropdown
        visible={dropdownVisible}
        containerStyle={{ overflow: "auto", maxHeight: 300 }}
      >
        {loading && <Loading />}
        {results?.length > 0 && (
          <div className={"container"} style={{ marginTop: 10 }}>
            {results.map((r) => (
              <Row
                onClick={() => {
                  setSelectedFilter(r);
                  setDropdownVisible(false);
                }}
                key={r.id}
                style={{ padding: 5, borderRadius: 5, flex: 1 }}
              >
                {r.type === "PATIENT" &&
                  `${r.value.firstName} ${r.value.lastName}`}
                {(r.type === "ADDRESS_NAME" || r.type === "ADDRESS_ADDRESS") &&
                  r.value}
              </Row>
            ))}
          </div>
        )}
      </Dropdown>
    </div>
  );
};
