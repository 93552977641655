import Button from "../../Button";
import React, { useState } from "react";
import { extendMoment } from "moment-range";

import Moment from "moment";
import Palette from "../../../styles/Palette";

const moment = extendMoment(Moment);

const Cell = ({ onSelectDate, isSelected, value }) => (
  <div
    onClick={onSelectDate}
    className={"flex containerCenter"}
    style={{ height: 50 }}
  >
    <div
      className={"containerCenter"}
      style={{
        fontWeight: "500",
        cursor: "pointer",
        height: 40,
        width: 40,
        borderRadius: 20,
        color: isSelected ? Palette.white : Palette.black,
        backgroundColor: isSelected ? Palette.blue : "transparent",
      }}
    >
      {value}
    </div>
  </div>
);

const Month = ({ dateRange, selectedDates = [], onSelectDate }) => {
  const startDate = moment(dateRange.start).startOf("week");
  const endDate = moment(dateRange.end).endOf("week");
  const overlappingRange = moment.range(startDate, endDate);
  const weeks = Array.from(overlappingRange.by("weeks"));
  return (
    <div className={"container"}>
      {weeks.slice(0, 1).map((week, i) => {
        const days = Array.from(moment(week).range("week").by("day"));
        return (
          <div key={i} className={"containerRow"}>
            {days.map((day) => {
              return (
                <Cell
                  key={day.format("dddd")}
                  onSelectDate={() => {}}
                  value={day.format("ddd")}
                />
              );
            })}
          </div>
        );
      })}
      {weeks.map((week, i) => {
        const days = Array.from(moment(week).range("week").by("day"));
        return (
          <div key={i} className={"containerRow"}>
            {days.map((day) => {
              const isSelected = !!selectedDates.find(
                (d) => d.format("YYYY-MM-DD") === day.format("YYYY-MM-DD")
              );
              return (
                <Cell
                  key={day.format("DD")}
                  {...{ isSelected }}
                  onSelectDate={() => onSelectDate(day)}
                  value={day.format("DD")}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default function Calendar({ selectedDates, onSelectDate }) {
  const defaultDateRange = moment().range("month");
  const [dateRange, setDateRange] = useState(defaultDateRange);

  const prevMonth = () =>
    setDateRange((d) => moment(d.start.subtract(1, "day")).range("month"));
  const nextMonth = () =>
    setDateRange((d) => moment(d.end.add(1, "day")).range("month"));

  return (
    <div style={{ height: "100%" }}>
      <div style={{ marginBottom: 10 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="secondaryBorder"
            size="icon"
            onClick={prevMonth}
            imgLeft={"/icons/chevronLeft.png"}
            iconStyle={{
              width: 30,
              height: 30,
            }}
          />
          <h3
            style={{
              marginLeft: 10,
              marginRight: 10,
              width: 175,
              textAlign: "center",
              flex: 1,
            }}
          >
            {dateRange.start.format("MMMM YYYY")}
          </h3>
          <Button
            variant="secondaryBorder"
            size="icon"
            onClick={nextMonth}
            imgLeft={"/icons/chevronLeft.png"}
            iconStyle={{
              width: 30,
              height: 30,
              transform: "rotate(180deg)",
            }}
          />
        </div>
      </div>
      <div style={{ height: "calc(100% - 90px)", position: "relative" }}>
        <Month {...{ dateRange, selectedDates, onSelectDate }} />
      </div>
    </div>
  );
}
