import React from 'react';
import LayoutMain from "../layouts/main";
import Global from "../styles/Global";
import styled from "styled-components";

const ContentContainer = styled.div`
  ${Global.containerItem}
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
`;

const List = styled.ul`
  margin-left: 20px;
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const PrivacyPolicy = () => {
  return (
      <div className={"container flex"} style={{ minHeight: "calc(100vh - 120px)" }}>
        <ContentContainer>
          <Title>Politique de Confidentialité de Hostal Transport</Title>
          <Paragraph>Date d&apos;entrée en vigueur : 16 septembre 2024</Paragraph>
          
          <Paragraph>Chez Hostal Transport, nous nous engageons à protéger et à respecter votre vie privée. Cette Politique de Confidentialité décrit la manière dont nous collectons, utilisons, et protégeons les informations personnelles que vous fournissez lorsque vous utilisez notre application mobile et notre site web.</Paragraph>
          
          <Subtitle>1. À propos de Hostal Transport</Subtitle>
          <Paragraph>Hostal Transport est une application mobile et un service web destiné à faciliter les demandes de transport hospitalier (ambulance ou VSL) entre les hôpitaux et les sociétés de transport. Les régulateurs peuvent attribuer des flottes de véhicules pour répondre à ces demandes. Cette politique de confidentialité s&apos;applique à l&apos;ensemble des services fournis par Hostal Transport.</Paragraph>
          
          <Subtitle>2. Les informations que nous collectons</Subtitle>
          <Paragraph>Nous collectons plusieurs types d&apos;informations lorsque vous utilisez notre application et notre site web, notamment :</Paragraph>
          <List>
            <ListItem>Informations personnelles : Cela inclut les noms, adresses e-mail, numéros de téléphone, adresses, et autres informations de contact que vous fournissez lors de votre inscription ou utilisation de nos services.</ListItem>
            <ListItem>Informations liées aux documents de transport : Lorsque vous utilisez l&apos;application mobile, nous vous permettons de prendre des photos des documents liés au transport hospitalier (ex. : autorisations, documents administratifs). Ces documents sont utilisés uniquement dans le cadre du transport demandé.</ListItem>
            <ListItem>Données de localisation : Nous pouvons collecter et utiliser les informations de localisation de votre appareil pour améliorer nos services, comme suivre les véhicules pendant les transports.</ListItem>
            <ListItem>Données d&apos;utilisation : Nous collectons également des informations sur la façon dont vous interagissez avec notre application et notre site (ex. : pages visitées, temps passé, actions réalisées).</ListItem>
          </List>

          <Subtitle>3. Utilisation des informations collectées</Subtitle>
          <Paragraph>Nous utilisons les informations collectées à plusieurs fins, notamment :</Paragraph>
          <List>
            <ListItem>Fournir le service : Pour traiter les demandes de transport et permettre aux régulateurs d&apos;attribuer des véhicules.</ListItem>
            <ListItem>Communication : Pour vous envoyer des notifications importantes concernant les demandes de transport, ou pour vous contacter si nécessaire.</ListItem>
            <ListItem>Vérification et documentation : Les photos prises avec la caméra de votre appareil (ex. : documents liés au transport) sont utilisées uniquement pour des raisons administratives et de vérification dans le cadre des services de transport hospitalier.</ListItem>
            <ListItem>Amélioration des services : Nous analysons les données d&apos;utilisation pour améliorer l&apos;expérience utilisateur, corriger les bugs et optimiser les performances de l&apos;application.</ListItem>
          </List>

          <Subtitle>4. Partage des informations</Subtitle>
          <Paragraph>Hostal Transport ne partage pas vos informations personnelles avec des tiers. Toutes les informations collectées sont exclusivement utilisées dans le cadre des services fournis par Hostal Transport. Nous nous engageons à ne jamais vendre, louer ou divulguer vos informations personnelles à des tiers sans votre consentement, sauf si la loi l&apos;exige.</Paragraph>

          <Subtitle>5. Utilisation de la caméra</Subtitle>
          <Paragraph>L&apos;application Hostal Transport demande l&apos;accès à la caméra de votre appareil pour vous permettre de prendre des photos des documents liés au transport. Ces photos sont utilisées uniquement pour documenter et faciliter le processus de transport hospitalier, et elles ne sont pas partagées avec des tiers.</Paragraph>

          <Subtitle>6. Sécurité des données</Subtitle>
          <Paragraph>Nous prenons la sécurité de vos données très au sérieux. Nous utilisons des mesures techniques et organisationnelles pour protéger les informations personnelles contre tout accès, modification, divulgation ou destruction non autorisés. Cependant, veuillez noter qu&apos;aucune méthode de transmission de données sur Internet ou de stockage électronique n&apos;est totalement sécurisée.</Paragraph>

          <Subtitle>7. Conservation des données</Subtitle>
          <Paragraph>Nous conservons vos informations personnelles aussi longtemps que nécessaire pour vous fournir nos services et pour respecter les obligations légales applicables. Une fois que vos informations ne sont plus nécessaires, nous les supprimons de manière sécurisée.</Paragraph>

          <Subtitle>8. Vos droits</Subtitle>
          <Paragraph>En tant qu&apos;utilisateur, vous disposez des droits suivants concernant vos informations personnelles :</Paragraph>
          <List>
            <ListItem>Accès : Vous avez le droit de demander une copie des informations que nous détenons à votre sujet.</ListItem>
            <ListItem>Correction : Vous pouvez demander la correction de toute information incorrecte ou incomplète.</ListItem>
            <ListItem>Suppression : Vous pouvez demander la suppression de vos informations personnelles, sous réserve des obligations légales de conservation.</ListItem>
            <ListItem>Opposition : Vous pouvez vous opposer au traitement de vos informations personnelles dans certaines circonstances.</ListItem>
          </List>
          <Paragraph>Pour exercer vos droits, vous pouvez nous contacter à l&apos;adresse indiquée ci-dessous.</Paragraph>

          <Subtitle>9. Modifications de la politique de confidentialité</Subtitle>
          <Paragraph>Nous pouvons mettre à jour cette politique de confidentialité de temps en temps pour refléter les changements dans nos pratiques ou les exigences légales. Toute modification sera publiée sur cette page, et nous vous informerons par e-mail ou par notification dans l&apos;application en cas de changements majeurs.</Paragraph>

          <Subtitle>10. Contact</Subtitle>
          <Paragraph>Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, ou si vous souhaitez exercer vos droits, veuillez nous contacter à :</Paragraph>
          <Paragraph>Hostal Transport</Paragraph>
          <Paragraph>Adresse e-mail : ithostal@gmail.com</Paragraph>
          <Paragraph>Adresse postale : 98 AVENUE DE LA MARNE, MONTROUGE - 92120, France (FR)</Paragraph>
          
          <Paragraph style={{ marginTop: 20 }}>En utilisant Hostal Transport, vous acceptez cette politique de confidentialité et consentez à la collecte et à l&apos;utilisation de vos informations comme décrit ci-dessus.</Paragraph>
        </ContentContainer>
      </div>
  );
};

export default PrivacyPolicy;
