const Colors = {
  main: {
    background: "#FBFBFD",
    backgroundTranslucent: "rgba(251,251,253,0.9)",
  },
  input: {
    border: "#AEB3CD",
    placeholder: "rgba(50, 59, 75, 0.75)",
  },
  text: {
    primary: "#02196C",
    secondary: "rgba(2, 25, 108, 0.6)",
  },
};

export default Colors;
