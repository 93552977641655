import ReactLoading from "react-loading";
import Palette from "../styles/Palette";
import React from "react";

export default function Loading({ containerStyle, absolute }) {
  let dynamicStyle = {};
  if (absolute)
    dynamicStyle = {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    };
  return (
    <div
      style={{
        zIndex: 2,
        height: "100%",
        width: "100%",
        ...dynamicStyle,
        ...containerStyle,
      }}
      className={"containerCenter"}
    >
      <ReactLoading
        type={"bubbles"}
        color={Palette.blue}
        height={100}
        width={100}
      />
    </div>
  );
}
