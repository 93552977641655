import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../components/Modal";
import PatientInformations from "../Hospital/EditTransport/PatientInformations";
import Button from "../../../components/Button";
import { toast } from "react-hot-toast";
import {
  addPatient,
  formatPatientData,
  getPatient,
  Patients,
} from "../../../services/Firebase";
import firebase from "firebase/compat/app";

export default ({ history, location }) => {
  const [loading, setLoading] = useState(false);
  const [patient, setPatient] = useState();
  const params = new URLSearchParams(location.search);
  const isVisible = !!params.get("editPatient");
  const patientId = params.get("editPatient");
  const patientInformationsRef = useRef();

  useEffect(() => {
    // if i have a patientId i fetch the patient
    if (patientId) {
      setLoading(true);
      firebase
        .firestore()
        .collection("patients")
        .doc(patientId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            formatPatientData(data).then((formatedPatientData) => {
              setPatient(formatedPatientData);
            });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [patientId]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isVisible && patient) {
      setPatient();
    }
  }, [isVisible, patient]);

  const onPressNext = async () => {
    try {
      patientInformationsRef.current?.validateInputs();
      if (patientId === "new") {
        console.log(patient);
        await addPatient(patient);
        toast.success("Patient créé");
      } else {
        await Patients.doc(patientId).update(patient);
        toast.success("Patient modifié");
      }
      history.goBack();
      setPatient();
    } catch (e) {
      toast.error(e.message);
    }
  };

  if (!isVisible) return null;

  return (
    <Modal
      title={"Modification fiche patient"}
      onFooter={() => (
        <div
          className={"containerRow"}
          style={{ justifyContent: "flex-end", marginTop: 20 }}
        >
          <Button
            onClick={() => history.goBack()}
            text={"Annuler"}
            variant={"secondary"}
            containerStyle={{ height: 40, marginRight: 10 }}
          />
          <Button
            onClick={onPressNext}
            text={patientId === "new" ? "Créer" : "Modifier"}
            containerStyle={{ width: 166, height: 40 }}
          />
        </div>
      )}
    >
      {!loading && (
        <PatientInformations
          ref={patientInformationsRef}
          {...{ patient, setPatient }}
          showHeader={false}
        />
      )}
    </Modal>
  );
};
