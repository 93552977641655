import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import Global from "../../styles/Global";

export const transportStatus = [
  { name: "Toutes les courses", value: ["ALL"] },
  { name: "Courses à venir", value: ["ACCEPTED"] },
  { name: "Courses terminées", value: ["ENDED", "REFUSED"] },
  {
    name: "Courses annulées",
    value: ["CANCELED"],
  },
  { name: "Courses en attente", value: ["PENDING"] },
];

export default ({ containerStyle, selectedStatus, setSelectedStatus }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    console.log(selectedStatus);
  }, [selectedStatus]);
  const handleInputChange = (status) => {
    setSelectedStatus(status);
  };

  return (
    <div
      className={"flex"}
      style={{
        ...Global.item,
        position: "relative",
        maxWidth: 320,
        ...containerStyle,
      }}
    >
      <div
        className={"flex containerRowCenter"}
        onClick={() => setDropdownVisible((v) => !v)}
        style={{ cursor: "pointer" }}
      >
        <div>{selectedStatus.name}</div>
        <img
          src={"/icons/chevronLeft.png"}
          style={{
            position: "absolute",
            top: "calc(50% - 12px)",
            bottom: 0,
            right: 10,
            alignItems: "center",
            width: 24,
            height: 24,
            transform: "rotate(-90deg)",
          }}
        />
      </div>
      <Dropdown visible={dropdownVisible}>
        {transportStatus.map((status, i) => {
          const isLast = i === transportStatus.length - 1;
          return (
            <div
              onClick={() => setSelectedStatus(status)}
              className={"containerRow"}
              style={{
                alignItems: "center",
                cursor: "pointer",
                marginBottom: isLast ? 0 : 5,
              }}
              key={status.value}
            >
              <input
                style={{ marginRight: 5, width: 24, height: 24 }}
                type="checkbox"
                checked={
                  Array.isArray(selectedStatus.value)
                    ? selectedStatus.value.includes(status.value[0])
                    : selectedStatus.value[0] === status.value[0]
                }
                onChange={() => handleInputChange(status)}
              />
              {status.name}
            </div>
          );
        })}
      </Dropdown>
    </div>
  );
};
