import { useZustandStore } from "@helpers/store";
import { useEffect } from "react";

export const useClickOutside = (
  ref,
  callback,
  except,
  addEventListener = true
) => {
  const isMapDragEnd = useZustandStore((state) => state.isMapDragEnd);
  const handleClick = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      event.target !== except &&
      !isMapDragEnd
    ) {
      callback();
    }
  };

  useEffect(() => {
    if (addEventListener) {
      document.addEventListener("click", handleClick);
    }

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};
