import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import moment from "moment";
import Palette from "../../styles/Palette";
import { containerFlexRowCenterCenter } from "../../styles/main";
import { RiCalendarLine } from "react-icons/ri";
import Fonts from "../../styles/fonts";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

export default ({ value, onChange, style }) => {
  const [isFocused, setIsFocused] = React.useState(false);
  return (
    <div>
      <ReactDatePicker
        locale={"fr"}
        selected={value}
        onChange={onChange}
        popperPlacement="bottom-start"
        showPopperArrow={false}
        onCalendarOpen={() => setIsFocused(true)}
        onCalendarClose={() => setIsFocused(false)}
        customInput={
          <div
            style={{
              ...containerFlexRowCenterCenter,
              backgroundColor: Palette.white,
              padding: "0 20px",
              border: `1px solid ${isFocused ? Palette.darkBlue : "#6775A7"}`,
              borderRadius: 5,
              height: 35,
              // width: "fit-content",
              position: "relative",
              cursor: "pointer",
              ...style,
            }}
          >
            <RiCalendarLine
              size={20}
              style={{ marginRight: 10 }}
              color={Palette.blueGrey}
            />
            <div style={{ ...Fonts.bold(14, Palette.blueGrey) }}>
              {moment(value).format("dddd DD MMMM YYYY")}
            </div>
          </div>
        }
      />
    </div>
  );
};
