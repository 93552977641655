import React, { forwardRef, useImperativeHandle } from "react";
import Palette from "../../../../styles/Palette";
import Colors from "../../../../styles/Colors";
import Button from "../../../../components/Button";
import CalendarSelector from "../../../../components/Calendar/Selector";
import { RiDeleteBin4Line } from "react-icons/ri";

export default forwardRef(
  ({ selectedDates, setSelectedDates, containerStyle }, ref) => {
    const validateInputs = () => {
      if (selectedDates.length === 0) {
        throw new Error("Veuillez sélectionner au moins une date.");
      }
    };

    const deps = [selectedDates];

    useImperativeHandle(
      ref,
      () => ({
        validateInputs,
      }),
      deps
    );

    const onSelectDate = (date) => {
      const dateAlreadySelected = !!selectedDates.find(
        (d) => d.format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
      );
      if (dateAlreadySelected) {
        const tmp = selectedDates.filter(
          (d) => d.format("YYYY-MM-DD") !== date.format("YYYY-MM-DD")
        );
        setSelectedDates(tmp);
      } else {
        const tmp = Array.from([...selectedDates, date]);
        setSelectedDates(tmp);
      }
    };

    return (
      <div className={"container flex"} style={containerStyle}>
        <div
          style={{
            fontSize: 20,
            fontWeight: 600,
            color: Palette.red,
            marginBottom: 10,
          }}
        >
          Planning des séries
        </div>
        <div className={"containerRow"} style={{ height: 450 }}>
          <div
            className={"flex container"}
            style={{ marginRight: 20, marginBottom: 10 }}
          >
            <CalendarSelector {...{ selectedDates, onSelectDate }} />
          </div>
          <div className={"flex"} style={{ overflowX: "auto" }}>
            {selectedDates
              .sort((a, b) => (a.isAfter(b) ? 1 : -1))
              .map((d, i) => {
                return (
                  <div
                    key={i}
                    className={"containerRowSpaceBetween"}
                    style={{
                      height: 60,
                      borderBottom: `1px solid ${Palette.grey}`,
                      fontSize: 15,
                      fontWeight: 600,
                      color: Colors.text.primary,
                    }}
                  >
                    <div className={"containerRowCenter"}>
                      <div
                        style={{
                          height: 32,
                          width: 32,
                          borderRadius: 16,
                          backgroundColor: Colors.text.primary,
                          marginRight: 10,
                        }}
                      />
                      {d.format("dddd DD MMMM")}
                    </div>
                    <Button
                      onClick={() => onSelectDate(d)}
                      size={"smallIcon"}
                      variant={"secondaryError"}
                      IconLeft={RiDeleteBin4Line}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
);
