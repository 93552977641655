import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import InputPlacesAuto from "../../../../components/InputPlacesAuto";
import SelectAddressType from "../../../../components/SelectAddressType";
import Input from "../../../../components/Input";
import Colors from "../../../../styles/Colors";

const Place = forwardRef(
  ({ type = "START", containerStyle, place, setPlace, patient }, ref) => {
    const [addressType, setAddressType] = useState(
      place?.addressType || "HOME"
    );
    const [name, setName] = useState(place?.name || null);
    const [address, setAddress] = useState(place?.address || null);
    const [complement, setComplement] = useState(place?.complement || null);
    const [service, setService] = useState(place?.service || null);
    const [establishment, setEstablishment] = useState(
      place?.establishment || null
    );
    const [chamber, setChamber] = useState(place?.chamber || null);
    const [postalCode, setPostalCode] = useState(place?.postalCode || null);
    const [city, setCity] = useState(place?.city || null);
    const [phoneNumber, setPhoneNumber] = useState(place?.phoneNumber || null);
    const [moreNotes, setMoreNotes] = useState(place?.moreNotes || null);

    const validateInputs = () => {
      const placeTypeName = type === "START" ? "de départ" : "de l'arrivée";
      if (!name) throw new Error(`L'intitulé ${placeTypeName} est requis`);
      if (!address) throw new Error(`L'adresse ${placeTypeName} est requise`);
      if (!postalCode)
        throw new Error(`Le code postal ${placeTypeName} est requis`);
      if (!city) throw new Error(`La ville ${placeTypeName} est requise`);
    };

    const handleAutocomplete = ({
      name,
      address,
      streetNumber,
      route,
      city,
      postalCode,
      phoneNumber,
    }) => {
      setName(name || "");
      setAddress(address || `${streetNumber || ""} ${route || ""}`);
      setCity(city || "");
      setPostalCode(postalCode || "");
      setPhoneNumber(phoneNumber || "");
    };

    const deps = [
      name,
      address,
      complement,
      service,
      postalCode,
      city,
      phoneNumber,
      moreNotes,
      addressType,
      establishment,
      chamber,
    ];

    useImperativeHandle(
      ref,
      () => ({
        validateInputs,
      }),
      deps
    );

    useEffect(() => {
      setPlace({
        name,
        address,
        complement,
        service,
        establishment,
        chamber,
        postalCode,
        city,
        phoneNumber,
        moreNotes,
        addressType,
      });
    }, deps);

    return (
      <div className={"container flex"} style={containerStyle}>
        <div className={"containerRow"} style={{ marginBottom: 10 }}>
          <InputPlacesAuto
            className={"flex"}
            types={["establishment"]}
            placeholder={"Intitulé"}
            style={{ marginRight: 5 }}
            value={name}
            onChange={setName}
            onSelect={handleAutocomplete}
            iconRight={"/icons/search.png"}
            suggestions={
              patient
                ? [
                    {
                      ...patient.address,
                      name: "Domicile",
                      phoneNumber: patient.phoneNumber,
                    },
                  ]
                : []
            }
          />
          <SelectAddressType onSelect={setAddressType} selected={addressType} />
        </div>
        <InputPlacesAuto
          types={["address"]}
          placeholder={"Rue"}
          style={{ marginBottom: 10 }}
          value={address}
          onChange={setAddress}
          onSelect={handleAutocomplete}
          iconRight={"/icons/search.png"}
        />
        <div className={"containerRow"} style={{ marginBottom: 10 }}>
          <Input
            required
            value={postalCode}
            onChange={setPostalCode}
            placeholder={"Code postal"}
          />
          <Input
            required
            value={city}
            onChange={setCity}
            placeholder={"Ville"}
            style={{ marginLeft: 10 }}
          />
        </div>
        <Input
          placeholder={"Téléphone"}
          value={phoneNumber}
          onChange={setPhoneNumber}
          style={{ marginBottom: 10 }}
        />
        {addressType !== "ESTABLISHMENT" && (
          <Input
            value={complement}
            onChange={setComplement}
            placeholder={"Complément"}
            style={{ marginBottom: 10 }}
          />
        )}
        {addressType === "ESTABLISHMENT" && (
          <>
            <Input
              placeholder={"Service"}
              style={{ marginBottom: 10 }}
              value={service}
              onChange={setService}
            />
            <div className={"containerRow"} style={{ marginBottom: 10 }}>
              <Input
                placeholder={"Batiment"}
                style={{ marginRight: 10 }}
                value={establishment}
                onChange={setEstablishment}
              />
              <Input
                placeholder={"Chambre"}
                value={chamber}
                onChange={setChamber}
              />
            </div>
          </>
        )}
        <Input
          multiline
          placeholder={"Autres informations..."}
          value={moreNotes}
          onChange={setMoreNotes}
        />
      </div>
    );
  }
);

export default forwardRef(({ from, setFrom, to, setTo, patient }, ref) => {
  const fromRef = useRef();
  const toRef = useRef();

  const validateInputs = () => {
    fromRef.current?.validateInputs();
    toRef.current?.validateInputs();
  };

  useImperativeHandle(
    ref,
    () => ({
      validateInputs,
    }),
    [from, to]
  );

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div className={"flex"}>
        <div
          style={{
            fontSize: 24,
            fontWeight: 500,
            marginBottom: 20,
          }}
        >
          Lieu de prise en charge
        </div>
        <Place
          type={"START"}
          place={from}
          setPlace={setFrom}
          ref={fromRef}
          {...{ patient }}
        />
      </div>
      <div
        style={{
          height: "100%",
          width: 1,
          backgroundColor: Colors.input.border,
          marginLeft: 30,
          marginRight: 30,
        }}
      />
      <div className={"flex"}>
        <div
          style={{
            fontSize: 24,
            fontWeight: 500,
            marginBottom: 20,
          }}
        >
          Lieu de dépôt
        </div>
        <Place
          type={"END"}
          place={to}
          setPlace={setTo}
          ref={toRef}
          {...{ patient }}
        />
      </div>
    </div>
  );
});
