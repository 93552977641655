import React, { useState, useMemo, useEffect } from "react";
import {
  ChevronDown,
  Eye,
  EyeOff,
  Edit,
  Plus,
  ChevronUp,
  X,
  UserRound,
  UserCog,
} from "lucide-react";
import { Checkbox } from "../shadcn/ui/checkbox";
import { DataTable } from "../shadcn/ui/DataTable";
import { DataTablePagination } from "./DataTablePagination";

import { Button } from "../shadcn/ui/button";

export function EmployeeDataTable({
  data,
  activeTab,
  onRowSelection,
  filterDisabled,
}) {
  const [showPasswords, setShowPasswords] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const togglePasswordVisibility = (id) => {
    setShowPasswords((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const filteredData = useMemo(() => {
    switch (activeTab) {
      case "Auxiliaires":
        return data.filter((employee) => employee.type === "AUX");
      case "DEA":
        return data.filter((employee) => employee.type === "DEA");
      default:
        return data;
    }
  }, [data, activeTab]);

  useEffect(() => {
    const selectedIds = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    onRowSelection(selectedIds);
  }, [rowSelection, onRowSelection]);

  const SortableHeader = ({ column, title }) => {
    if (filterDisabled) {
      return <div className="font-medium">{title}</div>;
    }

    return (
      <div className="flex items-center">
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="w-full justify-start px-0 font-medium"
        >
          <span>{title}</span>
          <div className="ml-auto flex items-center">
            {column.getIsSorted() === "asc" ? (
              <ChevronUp className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ChevronDown className="h-4 w-4" />
            ) : null}
            {column.getIsSorted() && (
              <Button
                variant="ghost"
                onClick={(e) => {
                  e.stopPropagation();
                  column.clearSorting();
                }}
                className="p-0 h-8 w-8 ml-1"
              >
                <X className="h-4 w-4" />
              </Button>
            )}
          </div>
        </Button>
      </div>
    );
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "type",
      header: ({ column }) => <SortableHeader column={column} title="TYPE" />,
      cell: ({ row }) => (
        <div className="flex flex-col items-center justify-center">
          {row.original.type === "DEA" ? (
            <>
              <UserCog className="h-6 w-6 text-red-600" />
              <span className="mt-1 text-xs text-red-600 font-medium">DEA</span>
            </>
          ) : (
            <>
              <UserRound className="h-6 w-6 text-gray-600" />
              <span className="mt-1 text-xs text-gray-600 font-medium">
                AUX
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      accessorKey: "id",
      header: ({ column }) => <SortableHeader column={column} title="ID" />,
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
    },
    {
      accessorKey: "sex",
      header: ({ column }) => <SortableHeader column={column} title="SEX" />,
      cell: ({ row }) => <div>{row.getValue("sex")}</div>,
    },
    {
      accessorKey: "nom",
      header: ({ column }) => <SortableHeader column={column} title="NOM" />,
      cell: ({ row }) => <div>{row.getValue("nom")}</div>,
    },
    {
      accessorKey: "prenom",
      header: ({ column }) => <SortableHeader column={column} title="PRÉNOM" />,
      cell: ({ row }) => <div>{row.getValue("prenom")}</div>,
    },
    {
      accessorKey: "dateNaissance",
      header: ({ column }) => (
        <SortableHeader column={column} title="DATE NAISSANCE" />
      ),
      cell: ({ row }) => <div>{row.getValue("dateNaissance")}</div>,
    },
    {
      accessorKey: "securiteSociale",
      header: ({ column }) => (
        <SortableHeader column={column} title="N°SÉCURITÉ SOCIAL" />
      ),
      cell: ({ row }) => <div>{row.getValue("securiteSociale")}</div>,
    },
    {
      accessorKey: "diplome",
      header: ({ column }) => (
        <SortableHeader column={column} title="DIPLÔME" />
      ),
      cell: ({ row }) => (
        <div
          className={
            row.getValue("diplome") === "OK" ? "text-green-500" : "text-red-500"
          }
        >
          {row.getValue("diplome")}
        </div>
      ),
    },
    {
      accessorKey: "permis",
      header: ({ column }) => <SortableHeader column={column} title="PERMIS" />,
      cell: ({ row }) => (
        <div
          className={
            row.getValue("permis") === "OK" ? "text-green-500" : "text-red-500"
          }
        >
          {row.getValue("permis")}
        </div>
      ),
    },
    {
      accessorKey: "cVitale",
      header: ({ column }) => (
        <SortableHeader column={column} title="C.VITALE" />
      ),
      cell: ({ row }) => (
        <div
          className={
            row.getValue("cVitale") === "OK" ? "text-green-500" : "text-red-500"
          }
        >
          {row.getValue("cVitale")}
        </div>
      ),
    },
    {
      accessorKey: "rib",
      header: ({ column }) => <SortableHeader column={column} title="RIB" />,
      cell: ({ row }) => (
        <div
          className={
            row.getValue("rib") === "OK" ? "text-green-500" : "text-red-500"
          }
        >
          {row.getValue("rib")}
        </div>
      ),
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const employee = row.original;
        return (
          <Button variant="ghost" className="h-8 w-8 p-0">
            <Edit className="h-4 w-4" />
          </Button>
        );
      },
    },
  ];

  return (
    <div className="rounded-md border">
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        paginationComponent={DataTablePagination}
        onRowSelectionChange={setRowSelection}
        enableRowSelection={true}
        state={{
          rowSelection,
        }}
        enableSorting={!filterDisabled}
        enableColumnFilters={!filterDisabled}
      />
    </div>
  );
}
