import Palette from "../../styles/Palette";
import Colors from "../../styles/Colors";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button";

export default ({ patient, containerStyle }) => {
  const {
    firstName,
    lastName,
    birthDate,
    id,
    socialSecurityNumber,
    phoneNumber,
    gender,
  } = patient;
  const isWomen = (t) => gender === "female" && t;
  const history = useHistory();
  return (
    <div
      className={"container flex"}
      style={{ ...containerStyle, position: "relative" }}
    >
      <div style={{ fontSize: 17, fontWeight: 500, marginBottom: 5 }}>
        Patient
      </div>
      <div
        className={"container flex"}
        style={{ fontSize: 15, flex: 1, justifyContent: "center" }}
      >
        <div style={{ color: Palette.red }}>
          M{isWomen("me")} {firstName} {lastName}
        </div>
        <div className={"containerRow"}>
          <div style={{ color: Colors.text.secondary, marginRight: 5 }}>
            Date de naissance:
          </div>
          <div>{moment(birthDate).format("l")}</div>
        </div>
        {socialSecurityNumber && (
          <div className={"containerRow"}>
            <div style={{ color: Colors.text.secondary, marginRight: 5 }}>
              N°SS:
            </div>
            <div>{socialSecurityNumber}</div>
          </div>
        )}
        {phoneNumber && (
          <div className={"containerRow"}>
            <div style={{ color: Colors.text.secondary, marginRight: 5 }}>
              Tel:
            </div>
            <div>{phoneNumber}</div>
          </div>
        )}
      </div>
      <Button
        tooltip={"Voir"}
        onClick={() => history.push({ search: `?patient=${id}` })}
        variant="secondary"
        imgLeft={"/icons/view.png"}
        size="smallIcon"
        iconStyle={{
          width: 18,
          height: 18,
        }}
        containerStyle={{ position: "absolute", top: 0, right: 0 }}
      />
    </div>
  );
};
