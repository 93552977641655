import { RiAlarmWarningFill, RiFileWarningFill } from "react-icons/ri";
import Palette from "../styles/Palette";
import { RiAddFill, RiCheckLine } from "react-icons/ri";
import { RxCross2, RxTarget } from "react-icons/rx";
import {
  FiAlertTriangle,
  FiGitPullRequest,
  FiClock,
  FiNavigation,
  FiNavigation2,
  FiZap,
  FiCheck,
} from "react-icons/fi";

export const notificationTypes = {
  transportNotifications: {
    NEW_TRANSPORT: {
      title: "NOUVEAU",
      value: "NEW_TRANSPORT",
      satuts: "PENDING",
      descriptionPush: "Un nouveau transport a été proposé",
      icon: RiAddFill,
      color: Palette.blue,
      backgroundColor: Palette.lightBlue,
    },
    TRANSPORT_ACCEPTED: {
      title: "A REGULER",
      value: "TRANSPORT_ACCEPTED",
      status: "ACCEPTED",
      descriptionPush: "Un transport a été accepté et doit être régulé",
      icon: FiGitPullRequest,
      color: Palette.blue,
      backgroundColor: Palette.lightBlue,
    },
    TRANSPORT_ACCEPTED_BY_REGULATOR: {
      title: "ACCEPTÉ",
      value: "TRANSPORT_ACCEPTED_BY_REGULATOR",
      status: "ACCEPTED",
      descriptionPush: "Un transport a été accepté",
      icon: FiCheck,
      color: Palette.blue,
      backgroundColor: Palette.lightBlue,
    },
    TRANSPORT_ATTRIBUTED_PENDING: {
      title: "ATTRIBUÉ",
      text: "En attente de",
      driver: true,
      value: "TRANSPORT_ATTRIBUTED_PENDING",
      status: "ASSIGNED",
      driverStatus: "PENDING",
      descriptionPush:
        "Un transport a été attribué à un véhicule et est en attente d'acceptation",
      icon: FiClock,
      color: Palette.purple,
      backgroundColor: Palette.lightPurple,
    },
    TRANSPORT_ATTRIBUTED_ACCEPTED: {
      title: "ATTRIBUÉ",
      text: "Accepté par",
      driver: true,
      value: "TRANSPORT_ATTRIBUTED_ACCEPTED",
      status: "ASSIGNED",
      driverStatus: "ACCEPTED",
      descriptionPush:
        "Un transport a été attribué à un véhicule et a été accepté",
      icon: FiZap,
      color: Palette.purple,
      backgroundColor: Palette.lightPurple,
    },
    TRANSPORT_IN_PROGRESS_TO_PATIENT: {
      title: "EN COURS",
      text: "En direction du patient",
      driver: true,
      value: "TRANSPORT_IN_PROGRESS_TO_PATIENT",
      status: "IN_PROGRESS",
      descriptionPush: "Un véhicule est en route vers le patient",
      icon: FiNavigation,
      color: Palette.salmon,
      backgroundColor: Palette.lightSalmon,
    },
    TRANSPORT_IN_PROGRESS_TO_PLACE: {
      title: "EN COURS",
      text: "Vers le lieu de RDV",
      driver: true,
      value: "TRANSPORT_IN_PROGRESS_TO_PLACE",
      status: "IN_PROGRESS",
      descriptionPush: "Un véhicule est en route vers le lieu de RDV",
      icon: FiNavigation2,
      color: Palette.salmon,
      backgroundColor: Palette.lightSalmon,
    },
    TRANSPORT_ENDED: {
      title: "TERMINÉ",
      text: "Réalisé par",
      driver: true,
      entity: "XX-123-XX",
      value: "TRANSPORT_ENDED",
      status: "ENDED",
      descriptionPush: "Un transport a été terminé",
      icon: RiCheckLine,
      color: Palette.green,
      backgroundColor: Palette.lightGreen,
    },

    TRANSPORT_REFUSED: {
      title: "REFUSÉ",
      value: "TRANSPORT_REFUSED",
      status: "REFUSED",
      descriptionPush: "Un transport a été refusé",
      icon: RxCross2,
      color: Palette.red,
      backgroundColor: Palette.lightRed,
    },
    TRANSPORT_CANCELED: {
      title: "ANNULÉ",
      text: "Annulé par le ",
      // entity: "Le partenaire de santé",
      value: "TRANSPORT_CANCELED",
      status: "CANCELED",
      descriptionPush: "Un transport a été annulé",
      icon: FiAlertTriangle,
      color: Palette.red,
      backgroundColor: Palette.lightRed,
    },
    TRANSPORT_BOURSE: {
      title: "BOURSE",
      text: "Proposé dans le groupe",
      entity: "Hostal",
      value: "TRANSPORT_BOURSE",
      status: "BOURSE",
      descriptionPush: "Un transport a été mis en bourse",
      icon: RxTarget,
      color: Palette.yellow,
      backgroundColor: Palette.lightYellow,
    },
  },
  userNotification: {
    // MESSAGE A ENVOYER EN FONCTION DES transportNotifications
  },
};
