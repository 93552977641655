import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "../lib/utils";

const badgeVariants = cva(
  "inline-flex items-center px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "bg-primary",
        pickedUp: "bg-nightBlue",
        notYet: "bg-lightGray"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
);

function Badge({ className, variant, ...props }) {
  const { message, time, index, stepStyle, textStyle } = props;
  return (
    <div className="flex flex-col justify-center relative mb-10">
      <p
        style={{ width: "105px" }}
        className={`absolute -top-8 text-xs px-1 mb-10 pl-2 ${textStyle}`}
      >
        {time}
      </p>
      <div className={stepStyle}>
        <p>{index}</p>
      </div>
      <div
        style={{ position: "absolute", left: 0 }}
        className={cn(badgeVariants({ variant }), className)}
        {...props}
      ></div>

      <p
        style={{
          width: "50px",
          marginTop: "10px",
          fontSize:"0.6rem"
        }}
        className={`absolute top-2 text-* text-center  whitespace-pre-line ${textStyle}`}
      >
        {message}
      </p>
    </div>
  );
}

export { Badge, badgeVariants };
