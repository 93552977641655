import firebase from "firebase/compat/app";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import useDataFromRef from "../../hooks/useDataFromRef";
import LayoutMain from "../../layouts/main";
import {
  formatPatientDoc,
  Patients as PatientRef,
} from "../../services/Firebase";
import Global from "../../styles/Global";
import Palette from "../../styles/Palette";

const PatientRow = styled.div`
  transition: 0.25s;
  &:hover {
    transition: 0.25s;
    background-color: rgba(87, 107, 255, 0.1);
    color: #506bff;
  }
`;

export function PatientList({ patients, containerStyle, onClick, selected }) {
  const history = useHistory();

  return (
    <div
      className={"container flex"}
      style={{
        ...Global.containerItem,
        ...containerStyle,
      }}
    >
      {patients?.length > 0 ? (
        <Table
          tableauHeaderList={[
            "Sexe",
            "Nom",
            "Prénom",
            "Date de naissance",
            "Téléphone",
            "Adresse",
            "Numéro de sécu",
          ]}
          data={patients}
          renderRow={(patient) => {
            const {
              id,
              lastName,
              firstName,
              gender,
              birthDate,
              socialSecurityNumber,
              phoneNumber,
              address,
            } = patient;
            const isSelected = selected && id === selected.id;
            return (
              <PatientRow
                onClick={() =>
                  onClick
                    ? onClick(patient)
                    : history.push({ search: `?patient=${id}` })
                }
                style={{
                  display: "flex",
                  marginLeft: -10,
                  marginRight: -10,
                  padding: 8,
                  borderRadius: 5,
                  alignItems: "center",
                  border: `2px solid ${
                    isSelected ? Palette.blue : "transparent"
                  }`,
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                <div style={{ flex: 1 }}>
                  <img
                    src={"/icons/" + gender + ".png"}
                    style={{ width: 24, height: 24 }}
                  />
                </div>
                <div style={{ flex: 1 }}>{lastName}</div>{" "}
                <div style={{ flex: 1 }}>{firstName}</div>{" "}
                <div style={{ flex: 1 }}>{moment(birthDate).format("ll")}</div>
                <div style={{ flex: 1 }}>{phoneNumber}</div>
                <div style={{ flex: 1 }}>
                  {address?.address}, {address?.postalCode} {address?.city}
                </div>
                <div style={{ flex: 1 }}>{socialSecurityNumber}</div>
              </PatientRow>
            );
          }}
        />
      ) : (
        <div
          className={"container flex"}
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ fontWeight: 600, fontSize: 24 }}>Aucun patient</div>
        </div>
      )}
    </div>
  );
}

export default function Patients() {
  const { uid } = firebase.auth().currentUser;
  const history = useHistory();

  const { data: patients, loading } = useDataFromRef({
    ref: PatientRef.where("createdBy", "==", uid),
    format: formatPatientDoc,
    listener: true,
  });

  return (
    <LayoutMain>
      <div
        className={"container flex"}
        style={{ minHeight: "calc(100vh - 120px)" }}
      >
        <div
          className={"containerRowSpaceBetween"}
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <div
            style={{
              fontSize: 26,
              fontWeight: 600,
            }}
          >
            Patients
          </div>
          <Button
            variant={"secondary"}
            containerStyle={{ border: `2px solid ${Palette.blue}` }}
            onClick={() => history.push({ search: `?editPatient=new` })}
            // imgLeft={"/icons/plus.png"}
            text={"Nouveau patient"}
          />
        </div>
        <PatientList {...{ patients }} />
        {loading && <Loading absolute />}
      </div>
    </LayoutMain>
  );
}
