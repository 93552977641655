import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle
} from "@components/shadcn/ui/card";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
function PatientCard({ patient, isTransportFromTrackingVehicule }) {
  const {
    birthDate,
    socialSecurityNumber,
    phoneNumber,
    firstName,
    lastName,
    id
  } = patient ?? {};
  const navigate = useHistory();
  const handleClick = () => {
    navigate.push(`?patient=${id}`);
  };

  return (
    <Card className="bg-lightGray2 border-4 border-strokGrayLight relative rounded-3xl">
      <CardHeader>
        <div className="flex flex-row">
          <div className="w-10/12">
            <CardTitle className="h-auto text-nightBlue font-bold text-lg">
              Patient :
              <span className="!text-pink">{` ${firstName} ${lastName}`}</span>
            </CardTitle>
            <CardDescription className="h-auto text-lightBlue leading-3 pb-1">
              Date de naissance :
              <span className="!text-nightBlue">
                {isTransportFromTrackingVehicule
                  ? `${moment(birthDate).format("l")}`
                  : `${moment(birthDate.toDate()).format("l")}`}
              </span>
            </CardDescription>
            <CardDescription className="h-auto text-lightBlue leading-3 pb-1">
              N°SS :{" "}
              <span className="!text-nightBlue">{` ${socialSecurityNumber}`}</span>
            </CardDescription>
            <CardDescription className="h-auto text-lightBlue leading-3">
              Numéro :{" "}
              <span className="!text-nightBlue">{` ${phoneNumber}`}</span>
            </CardDescription>
          </div>
          <div className="w-2/12 flex items-end justify-end">
            <button onClick={handleClick}>
              <ArrowRightIcon
                style={{ left: "243px", top: "85px" }}
                className="w-10 h-10  p-2 bottom-8 rounded-lg !bg-skyBlue10 text-skyBlue2"
              />
            </button>
          </div>
        </div>
      </CardHeader>
    </Card>
  );
}

export default PatientCard;
