import React, { useState, useEffect, useCallback } from "react";
import {
  Search,
  Trash2,
  Plus,
  ChevronDown,
  Eye,
  EyeOff,
  Edit,
  ChevronLeft,
  ChevronRight,
  User,
} from "lucide-react";
import LayoutMain from "@layouts/main";
import { fakeDataTable } from "@helpers/fakeData";
import { FaCarSide, FaUser } from "react-icons/fa";
import { VehicleDataTable } from "@components/Table/vehicle-data-table";
import { EmployeeDataTable } from "@components/Table/employee-data-table";
import AmbulancierForm from "@components/Card/AmbulancierForm";
import VehiculeForm from "@components/Card/VehiculeForm";

export default function VehiclesAndEmployees() {
  const [vehicles, setVehicles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [totalEntities, setTotalEntities] = useState(0);
  const [generalActiveTab, setGeneralActiveTab] = useState("Véhicules");
  const [activeTab, setActiveTab] = useState("Tous");
  const [search, setSearch] = useState("");
  const [showPasswords, setShowPasswords] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showAmbulancierForm, setShowAmbulancierForm] = useState(false);
  const [showVehiculeForm, setShowVehiculeForm] = useState(false);

  useEffect(() => {
    if (fakeDataTable.vehicles && fakeDataTable.vehicles.length > 0) {
      setVehicles(fakeDataTable.vehicles);
      console.log("Nombre total de véhicules:", fakeDataTable.vehicles.length);
    }
    if (fakeDataTable.employees && fakeDataTable.employees.length > 0) {
      setEmployees(fakeDataTable.employees);
      console.log("Nombre total d'employés:", fakeDataTable.employees.length);
    }
    setTotalEntities(
      fakeDataTable.vehicles.length + fakeDataTable.employees.length
    );
  }, []);

  useEffect(() => {
    console.log("selectedRows mis à jour:", selectedRows);
  }, [selectedRows]);

  const handleRowSelection = useCallback((selectedIds) => {
    setSelectedRows(selectedIds);
    console.log("Lignes sélectionnées:", selectedIds); // Pour le débogage
  }, []);

  const handleDeleteSelected = useCallback(() => {
    if (generalActiveTab === "Véhicules") {
      setVehicles((prev) =>
        prev.filter((vehicle) => !selectedRows.includes(vehicle.id.toString()))
      );
    } else {
      setEmployees((prev) =>
        prev.filter(
          (employee) => !selectedRows.includes(employee.id.toString())
        )
      );
    }
    setSelectedRows([]);
  }, [generalActiveTab, selectedRows]);

  // Calculer les index des éléments à afficher
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = vehicles
    ? vehicles.slice(indexOfFirstItem, indexOfLastItem)
    : [];
  console.log("Éléments actuels:", currentItems.length);
  console.log("Index du premier élément:", indexOfFirstItem);
  console.log("Index du dernier élément:", indexOfLastItem);

  // Calculer le nombre total de pages
  const totalPages = vehicles ? Math.ceil(vehicles.length / itemsPerPage) : 0;

  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Fonction pour changer le nombre d'éléments par page
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Réinitialiser à la première page lors du changement
  };

  // Calculer le nombre de véhicules pour chaque catégorie
  const totalVehicles = vehicles ? vehicles.length : 0;
  const ambulanceCount = vehicles
    ? vehicles.filter((v) => v.type.toLowerCase() === "ambulance").length
    : 0;
  const vslCount = vehicles
    ? vehicles.filter((v) => v.type.toLowerCase() === "vsl").length
    : 0;

  // Calculer le nombre d'employés pour chaque catégorie
  const totalEmployees = employees ? employees.length : 0;
  const auxiliaryCount = employees
    ? employees.filter((e) => e.type.toLowerCase() === "aux").length
    : 0;
  const deaCount = employees
    ? employees.filter((e) => e.type.toLowerCase() === "dea").length
    : 0;

  const handleOpenAmbulancierForm = () => {
    setShowAmbulancierForm(true);
  };

  const handleCloseAmbulancierForm = () => {
    setShowAmbulancierForm(false);
  };

  const handleOpenVehiculeForm = () => {
    setShowVehiculeForm(true);
  };

  const handleCloseVehiculeForm = () => {
    setShowVehiculeForm(false);
  };

  return (
    <LayoutMain>
      <div className="h-full flex flex-col">
        <div className="bg-slate-100 rounded-t-3xl flex mb-6 justify-between p-6 max-h-20">
          <div className=" items-center ">
            <h1 className="text-2xl  font-bold">
              Véhicules & Employés{" "}
              <span className="ml-1 px-2 py-1 bg-blue-100 text-blue-900 rounded-full text-sm font-normal">
                {totalEntities}
              </span>
            </h1>
          </div>
          <div className="space-x-2">
            <div className="mb-4 flex space-x-2 bg-gray-100 p-1 rounded-lg">
              <button
                className={`p-2 rounded-md transition-colors duration-200 ${
                  generalActiveTab === "Véhicules"
                    ? "bg-white text-blue-600 shadow-sm"
                    : "text-gray-600 hover:bg-gray-200"
                }`}
                onClick={() => setGeneralActiveTab("Véhicules")}
              >
                <FaCarSide size={20} />
              </button>
              <button
                className={`p-2 rounded-md transition-colors duration-200 ${
                  generalActiveTab === "Employés"
                    ? "bg-white text-blue-600 shadow-sm"
                    : "text-gray-600 hover:bg-gray-200"
                }`}
                onClick={() => setGeneralActiveTab("Employés")}
              >
                <User size={20} />
              </button>
            </div>
          </div>
        </div>

        <div className="flex-grow flex flex-col p-6 overflow-hidden">
          <div className="flex items-center mb-4 justify-between">
            <div className="flex items-center">
              <button
                className={`p-2 text-gray-500 hover:text-gray-700 border rounded-md ${
                  selectedRows.length === 0
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                onClick={handleDeleteSelected}
                disabled={selectedRows.length === 0}
              >
                <Trash2 size={20} />
              </button>
              <div className="mx-2 border rounded-md flex items-center max-w-96">
                <button className="p-2 text-gray-500 hover:text-gray-700 border-r-2">
                  <Search className="text-gray-400" size={20} />
                </button>
                <input
                  type="text"
                  placeholder="Rechercher"
                  className="w-40 pl-2 pr-4 py-2"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-gray-100 text-blue-600 rounded-md hover:bg-gray-200 flex items-center mr-2 max-w-56@"
                onClick={handleOpenAmbulancierForm}
              >
                <Plus className="mr-2" size={20} />
                Nouvel ambulancier
              </button>
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center max-w-56"
                onClick={handleOpenVehiculeForm}
              >
                <Plus className="mr-2" size={20} />
                Nouveau véhicule
              </button>
            </div>
          </div>
          {generalActiveTab === "Véhicules" ? (
            <div className="mb-4 flex space-x-4 border-b">
              <button
                className={`pb-2 ${
                  activeTab === "Tous"
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : "text-gray-600"
                }`}
                onClick={() => setActiveTab("Tous")}
              >
                Tous{" "}
                <span className="ml-1 px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs">
                  {totalVehicles}
                </span>
              </button>
              <button
                className={`pb-2 ${
                  activeTab === "Ambulances"
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : "text-gray-600"
                }`}
                onClick={() => setActiveTab("Ambulances")}
              >
                Ambulances{" "}
                <span className="ml-1 px-2 py-1 bg-gray-100 text-blue-800 rounded-full text-xs">
                  {ambulanceCount}
                </span>
              </button>
              <button
                className={`pb-2 ${
                  activeTab === "VSL"
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : "text-gray-600"
                }`}
                onClick={() => setActiveTab("VSL")}
              >
                VSL{" "}
                <span className="ml-1 px-2 py-1 bg-gray-100 text-blue-800 rounded-full text-xs">
                  {vslCount}
                </span>
              </button>
            </div>
          ) : (
            <div className="mb-4 flex space-x-4 border-b">
              <button
                className={`pb-2 ${
                  activeTab === "Tous"
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : "text-gray-600"
                }`}
                onClick={() => setActiveTab("Tous")}
              >
                Tous{" "}
                <span className="ml-1 px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs">
                  {totalEmployees}
                </span>
              </button>
              <button
                className={`pb-2 ${
                  activeTab === "Auxiliaires"
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : "text-gray-600"
                }`}
                onClick={() => setActiveTab("Auxiliaires")}
              >
                Auxiliaires{" "}
                <span className="ml-1 px-2 py-1 bg-gray-100 text-blue-800 rounded-full text-xs">
                  {auxiliaryCount}
                </span>
              </button>
              <button
                className={`pb-2 ${
                  activeTab === "DEA"
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : "text-gray-600"
                }`}
                onClick={() => setActiveTab("DEA")}
              >
                DEA{" "}
                <span className="ml-1 px-2 py-1 bg-gray-100 text-blue-800 rounded-full text-xs">
                  {deaCount}
                </span>
              </button>
            </div>
          )}
          {generalActiveTab === "Véhicules" ? (
            <VehicleDataTable
              data={vehicles}
              activeTab={activeTab}
              onRowSelection={handleRowSelection}
            />
          ) : (
            <EmployeeDataTable
              data={employees}
              activeTab={activeTab}
              onRowSelection={handleRowSelection}
              filterDisabled
            />
          )}
        </div>
      </div>

      {showAmbulancierForm && (
        <AmbulancierForm onClose={handleCloseAmbulancierForm} />
      )}

      {showVehiculeForm && <VehiculeForm onClose={handleCloseVehiculeForm} />}
    </LayoutMain>
  );
}
