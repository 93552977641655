import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";

import { toast } from "react-hot-toast";
import Palette from "../../../styles/Palette";
import {
  assignBourseTransport,
  createNotification,
  Groups,
  updateTransport,
  Users,
} from "../../../services/Firebase";
import { useAuth } from "../../../providers/Auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useDataFromRef from "../../../hooks/useDataFromRef";
import Colors from "../../../styles/Colors";
import { set } from "date-fns";
import { useGlobal } from "reactn";
import { setGlobal } from "reactn";

const AssignBourseModal = ({ transport, setShowAssignBourseModal }) => {
  const [_, setTransport] = useGlobal("transportToCancelOrBourse");
  const [loading] = useState(false);
  const [selected, setSelected] = useState();
  const history = useHistory();

  const { data: selectedGroup } = useDataFromRef({
    ref: selected && Groups.doc(selected),
    listener: true,
    initialState: null,
    simpleRef: true,
    condition: !!selected,
    refreshArray: [selected],
  });

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [type, setType] = useState("ALL");
  const { groups, user } = useAuth();

  const onClose = () => {
    console.log("onClose");
    setTransport(null);
    history.push("/map");
  };

  const handleAssignBourse = async () => {
    try {
      await assignBourseTransport(transport, selected, selectedGroup);

      toast.success(
        `Transport attribué au groupe de bourse ${
          selectedGroup?.name ? selectedGroup.name : "Tous"
        }`
      );
      setShowAssignBourseModal(false);
      setGlobal({ transportToCancelOrBourse: null });
    } catch (error) {
      console.error("Erreur lors de l'attribution à la bourse:", error);
    }
  };

  // const assignBourse = async () => {
  //   const updateData = {
  //     regulatedBy: "BOURSE",
  //     driver: null,
  //     driverStatus: null,
  //     assignedTo: null,
  //     status: "PENDING",
  //   };

  //   const notificationData = {
  //     ...transport,
  //     bourseType: selected === "PUBLIC" ? "PUBLIC" : "PRIVATE",
  //     bourseGroup: selected !== "PUBLIC" ? selected : null,
  //   };

  //   try {
  //     if (transport.returnTransportId) {
  //       const updateReturnData = { ...updateData };
  //       if (selected !== "PUBLIC") {
  //         updateData.bourseGroup = selected;
  //         updateReturnData.bourseGroup = selected;
  //       }

  //       await updateTransport(transport.id, {
  //         ...updateData,
  //         bourseType: notificationData.bourseType,
  //       });
  //       await createNotification(
  //         notificationData,
  //         "TRANSPORT_BOURSE",
  //         "REGULATOR & HOSPITAL"
  //       );

  //       await updateTransport(transport.returnTransportId, {
  //         ...updateReturnData,
  //         bourseType: notificationData.bourseType,
  //       });
  //       await createNotification(
  //         notificationData,
  //         "TRANSPORT_BOURSE",
  //         "REGULATOR & HOSPITAL"
  //       );

  //       console.log(
  //         `Notification créée pour le transport ${transport.id} et ${transport.returnTransportId}`
  //       );
  //     } else {
  //       if (selected !== "PUBLIC") {
  //         updateData.bourseGroup = selected;
  //       }

  //       await updateTransport(transport.id, {
  //         ...updateData,
  //         bourseType: notificationData.bourseType,
  //       });
  //       await createNotification(
  //         notificationData,
  //         "TRANSPORT_BOURSE",
  //         "REGULATOR & HOSPITAL"
  //       );

  //       console.log(`Notification créée pour le transport ${transport.id}`);
  //     }

  //     toast.success(
  //       `Transport attribué au groupe de bourse ${
  //         selectedGroup?.name ? selectedGroup.name : "Tous"
  //       }`
  //     );
  //     setShowAssignBourseModal(false);
  //     setGlobal({ transportToCancelOrBourse: null });
  //   } catch (error) {
  //     console.error("Erreur lors de l'attribution à la bourse:", error);
  //   }
  // };

  console.log("selected", selected);

  useEffect(() => {
    if (selectedGroup) {
      const membersPromise = selectedGroup?.members.map(
        (memberId) =>
          new Promise((resolve, reject) => {
            Users.doc(memberId)
              .get()
              .then((m) => {
                resolve({
                  id: m.id,
                  name: m.get("firstName") || m.get("name"),
                  mail: m.get("mail"),
                  role: m.get("role"),
                });
              })
              .catch(reject);
          })
      );
      Promise.all(membersPromise).then(setSelectedMembers);
    } else {
      setSelectedMembers([]);
    }
  }, [selectedGroup]);

  if (!transport) return null;

  return (
    <Modal title={`Proposer le transport en bourse`}>
      <div className={"container"} style={{ height: 500 }}>
        <div
          className={"containerRow"}
          style={{
            height: 30,
            width: 350,
            fontWeight: 600,
            alignSelf: "center",
            marginBottom: 20,
          }}
        >
          {[
            { name: "Tous", value: "ALL" },
            { name: "Mes groupes", value: "OWNED" },
          ].map((s) => {
            const isSelected = s.value === type;
            return (
              <div
                key={s.value}
                onClick={() => {
                  setType(s.value);
                }}
                className={"flex"}
                style={{
                  cursor: "pointer",
                  color: isSelected ? Palette.blue : Colors.text.secondary,
                  borderBottom: `${isSelected ? 4 : 2}px solid ${
                    isSelected ? Palette.blue : Palette.lightGrey
                  }`,
                  textAlign: "center",
                }}
              >
                {s.name}
              </div>
            );
          })}
        </div>
        <div className={"flex containerRow"}>
          <div className={"container"} style={{ flex: 2, overflowY: "auto" }}>
            {
              // i want to put the TOUS Group who is a PUBLIC group in the header of the list
            }
            <div key={"PUBLIC"}>
              <div
                onClick={() => setSelected("PUBLIC")}
                className={"containerRow"}
                style={{
                  cursor: "pointer",
                  height: 50,
                  alignItems: "center",
                  padding: 10,
                  boxSizing: "border-box",
                  borderRadius: 10,
                  backgroundColor:
                    selected === "PUBLIC"
                      ? Palette.blueTranslucent
                      : "transparent",
                }}
              >
                <div
                  style={{
                    height: 32,
                    width: 32,
                    borderRadius: 16,
                    backgroundColor: Palette.yellow,
                    marginRight: 10,
                  }}
                />
                <div style={{ fontWeight: 500 }}>Tous</div>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  height: 1,
                  backgroundColor: Palette.grey,
                }}
              />
            </div>
            <div className={"container"} style={{ flex: 2, overflowY: "auto" }}>
              {groups
                .filter((g) =>
                  type === "OWNED" ? g.createdBy === user.id : true
                )
                .map((g) => {
                  const isSelected = g.id === selected;
                  return (
                    <div key={g.id}>
                      <div
                        onClick={() => setSelected(g.id)}
                        className={"containerRow"}
                        style={{
                          cursor: "pointer",
                          height: 50,
                          alignItems: "center",
                          padding: 10,
                          boxSizing: "border-box",
                          borderRadius: 10,
                          backgroundColor: isSelected
                            ? Palette.blueTranslucent
                            : "transparent",
                        }}
                      >
                        <div
                          style={{
                            height: 32,
                            width: 32,
                            borderRadius: 16,
                            backgroundColor: g.color,
                            marginRight: 10,
                          }}
                        />
                        <div style={{ fontWeight: 500 }}>{g.name}</div>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          height: 1,
                          backgroundColor: Palette.grey,
                        }}
                      />
                    </div>
                  );
                })}
            </div>
            {selected && (
              <div
                className={"container"}
                style={{
                  flex: 1.5,
                  marginLeft: 10,
                  borderLeft: `1px solid ${Palette.grey}`,
                }}
              >
                <div
                  style={{
                    overflowY: "auto",
                  }}
                  className={"flex container"}
                >
                  {selectedGroup &&
                    selectedMembers?.map((m) => {
                      return (
                        <div key={m.id}>
                          <div
                            className={"containerRow"}
                            style={{
                              boxSizing: "border-box",
                              height: 64,
                              alignItems: "center",
                              justifyContent: "space-between",
                              paddingLeft: 20,
                              fontWeight: 500,
                            }}
                          >
                            <div>
                              <div>{m.name}</div>
                              <div
                                style={{
                                  fontSize: 13,
                                  color: Palette.darkGrey,
                                }}
                              >
                                {m.role === "REGULATOR"
                                  ? "Société de transport"
                                  : "Hôpital"}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                              height: 1,
                              backgroundColor: Palette.grey,
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className={"containerRow"} style={{ paddingLeft: 30 }}>
                  <Button
                    fullWidth
                    onClick={() => {
                      console.log(selected);
                      handleAssignBourse(selected);
                    }}
                    text={"Proposer à ce groupe"}
                    containerStyle={{ marginRight: 10 }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AssignBourseModal;
