import React from "react";
import {
  containerFlexCenterCenter,
  containerFlexRowCenterCenter,
} from "../../styles/main";
import Fonts from "../../styles/fonts";
import vehicles from "../../constants/vehicles";
import Palette from "../../styles/Palette";

export default ({ value = [], onChange }) => {
  return (
    <div
      style={{
        ...containerFlexRowCenterCenter,
        height: 35,
        // minWidth: 450,
        gap: 5,
      }}
    >
      {Object.values(vehicles).map(({ value: val, name, icon: Icon }) => {
        const isSelected = value.includes(val);
        return (
          <div
            onClick={() => {
              if (isSelected) {
                onChange(value.filter((v) => v !== val));
              } else {
                onChange([...value, val]);
              }
            }}
            key={val}
            style={{
              ...containerFlexRowCenterCenter,
              ...Fonts.bold(14, isSelected ? Palette.blueGrey : "#7D7D7D"),
              backgroundColor: isSelected ? Palette.white : "#DADADA",
              padding: "0 10px",
              borderRadius: 5,
              height: "100%",
              flex: 1,
              border: `1px solid ${isSelected ? Palette.blueGrey : "#7D7D7D"}`,
              cursor: "pointer",
            }}
          >
            <Icon
              size={20}
              style={{ marginRight: 10 }}
              color={isSelected ? Palette.blueGrey : "#7D7D7D"}
            />
            {name}
          </div>
        );
      })}
    </div>
  );
};
