import React from "react";
import { createPortal } from "react-dom";
import Global from "../styles/Global";
import Button from "./Button";
import { useHistory } from "react-router-dom";
import Colors from "../styles/Colors";

export const ModalSection = ({ children, containerStyle, row, ...props }) => {
  return (
    <div
      style={{
        ...screenStyles.containerItem,
        width: row && `calc(${100 / row}% - 50px)`,
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${Colors.input.border}`,
        ...containerStyle,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default function Modal({
  children,
  title,
  subTitle,
  containerStyle,
  showPrevButton = true,
  showHeader = true,
  onPrevClick,
  onTitle,
  onRight,
  onClose,
  onHeader,
  onFooter,
  style,
}) {
  const history = useHistory();

  function close() {
    history.push(location.pathname);
  }

  function goBack() {
    history.goBack();
  }

  function Header({ title, subTitle, onRight }) {
    return (
      <div
        style={{
          ...Global.containerItem,
          padding: 0,
          marginBottom: 20,
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ zIndex: 2 }}>
            {showPrevButton ? (
              <Button
                onClick={onPrevClick || goBack}
                variant="secondaryBorder"
                size="icon"
                imgLeft={"/icons/chevronLeft.png"}
                iconStyle={{
                  width: 30,
                  height: 30,
                }}
                containerStyle={{ marginRight: 20 }}
              />
            ) : (
              <div
                style={{
                  width: 30,
                  height: 30,
                  marginRight: 20,
                }}
              />
            )}
          </div>
          <div className={"containerRow flex"}>
            <div className={"container"}>
              {onTitle && onTitle()}
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 40,
                  textAlign: "center",
                  fontSize: subTitle ? 16 : 20,
                  fontWeight: subTitle ? undefined : 600,
                  color: "#02196C",
                  opacity: subTitle ? 0.6 : 1,
                  zIndex: 1,
                }}
              >
                {title}
              </div>
              {subTitle && <h2 style={{ color: "#02196C" }}>{subTitle}</h2>}
            </div>
          </div>
          {onRight ? (
            <div style={{ zIndex: 2 }}>{onRight()}</div>
          ) : (
            <div style={{ width: 30, height: 30, marginLeft: 20 }} />
          )}
        </div>
      </div>
    );
  }

  const modalContainerStyle = {
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.33)",
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const modalStyle = {
    position: "relative",
    display: "flex",
    maxHeight: "90vh",
    width: 800,
    flexDirection: "column",
    background: "white",
    borderRadius: 10,
    padding: 30,
  };

  return createPortal(
    <>
      <div style={{ ...modalContainerStyle }} onClick={onClose || close}>
        <div
          style={{ ...modalStyle, ...style }}
          onClick={(e) => e.stopPropagation()}
        >
          {showHeader && <Header {...{ title, subTitle, onRight }} />}
          {onHeader && onHeader()}
          <div
            style={{
              overflowY: "auto",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              ...containerStyle,
            }}
            className={"hideScrollbars"}
          >
            {children}
          </div>
          {onFooter && onFooter()}
        </div>
      </div>
    </>,
    document.getElementById("modal_root")
  );
}

const screenStyles = {
  containerItem: {
    ...Global.containerItem,
    marginBottom: 10,
  },
};
