import Drawer from "@components/Drawer/Drawer";
import TabBar from "@components/TabBar";
import React from "react";
import { useMediaQuery } from "react-responsive";
import "./style.css";

import { useZustandStore } from "@helpers/store";
import Grid from "@mui/material/Grid2";
export const useGutters = (noPadding) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const calcGutters = isTabletOrMobile ? 50 : 100;
  return !noPadding ? calcGutters : 0;
};

export default function LayoutMain({
  containerStyle = {},
  style,
  children,
  noPadding = false,
  onRight
}) {
  const isDrawerOpen = useZustandStore((state) => state.isDrawerOpen);
  const setIsDrawerOpen = useZustandStore((state) => state.setIsDrawerOpen);
  const setGlobalRef = useZustandStore((state) => state.setGlobalRef);

  return (
    <Grid container>
      <Grid
        size={1}
        sx={{
          position: "absolute",

          zIndex: 10,

          display: "flex",

          height: "100%",

          alignItems: "center",

          justifyContent: "center",

          width: "80px",

          marginLeft: "16px"
        }}
      >
        <TabBar />
        <Drawer
          isOpen={isDrawerOpen}
          onClose={(e) => {
            setIsDrawerOpen(false);
            setGlobalRef(e.target);
          }}
        />
      </Grid>

      <Grid size={1}></Grid>

      <Grid size={11}>{children}</Grid>
    </Grid>
  );
}
