import Palette from "../styles/Palette";
import Colors from "../styles/Colors";
import React from "react";

const transportTypes = [
  {
    text: "Aller - Retour",
    description: "Course vers un établissement de santé",
    value: "SINGLE",
  },
  {
    text: "Série",
    description: "Patient transporté de façon récurrente",
    value: "RECURRING",
  },
];

export default ({ onSelect, selected = 0, containerStyle }) => {
  return (
    <div className={"container"}>
      {transportTypes.map(({ text, description, value }, i) => {
        const isSelected = value === selected;
        return (
          <div
            key={i}
            onClick={() => onSelect(value)}
            style={{
              cursor: "pointer",
              height: 70,
              borderRadius: 5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              border: `2px solid ${
                isSelected ? Palette.blue : Colors.input.border
              }`,
              backgroundColor: Palette.white,
              ...containerStyle,
            }}
          >
            <div className={"containerRow"} style={{ padding: 10 }}>
              <div className={"container flex"}>
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                  }}
                >
                  {text.toUpperCase()}
                </div>
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {description}
                </div>
              </div>
              <div
                style={{
                  width: 70,
                  margin: -10,
                  marginTop: -19,
                  marginBottom: -19,
                  backgroundColor: isSelected
                    ? Palette.blueTranslucent
                    : undefined,
                  borderLeft: `2px solid ${
                    isSelected ? Palette.blue : Colors.input.border
                  }`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: 999,
                    border: `1px solid ${Colors.input.border}`,
                    backgroundColor: Palette.white,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {isSelected && (
                    <div
                      style={{
                        width: "75%",
                        height: "75%",
                        backgroundColor: Palette.blue,
                        borderRadius: 999,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
