import React, { useEffect, useRef, useState } from "react";
import Palette from "../styles/Palette";
import Colors from "../styles/Colors";
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";
import Loading from "./Loading";

export default ({
  required = false,
  fullWidth,
  type = "text",
  style,
  placeholder,
  label,
  onChange = () => {},
  onSelect = () => {},
  value,
  multiline,
  bottom,
  types,
  suggestions = [],
  height = 40,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const ref = useOnclickOutside(() => {
    setDropdownVisible(false);
  });
  const inputRef = useRef();
  const {
    suggestions: { data, loading },
    setValue: setAutocompleteValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types,
      componentRestrictions: { country: "fr" },
    },
    debounce: 300,
  });

  useEffect(() => {
    if (
      value?.length > 0 &&
      !dropdownVisible &&
      document.activeElement === inputRef.current
    ) {
      setDropdownVisible(true);
    }
    setAutocompleteValue(value);
  }, [value]);

  return (
    <div>
      {label && (
        <div style={{ fontStyle: 15, fontWeight: 500, marginBottom: 5 }}>
          {label} {required && "*"}
        </div>
      )}

      <div
        ref={ref}
        style={{
          width: fullWidth ? "calc(100% - 2px)" : undefined,
          height: multiline ? height * 2 - 2 : height,
          borderRadius: 5,
          backgroundColor: Palette.white,
          border: `1px solid ${Colors.input.border}`,
          display: "flex",
          ...style,
        }}
      >
        <div style={{ display: "flex", position: "relative", flex: 1 }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              paddingLeft: 15,
              paddingRight: 15,
              cursor: "pointer",
              justifyContent: "space-between",
            }}
          >
            <input
              {...{ placeholder, type, value }}
              ref={inputRef}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onFocus={() => {
                if (suggestions?.length > 0 || value?.length > 0) {
                  setDropdownVisible(true);
                }
              }}
            />
            <img
              src={"/icons/search.png"}
              style={{
                marginLeft: 10,
                width: 24,
                height: 24,
              }}
            />
          </div>
          {dropdownVisible && (
            <div
              style={{
                border: `1px solid ${Colors.input.border}`,
                minHeight: 50,
                maxHeight: 50 * 3,
                backgroundColor: Palette.white,
                position: "absolute",
                top: bottom ? undefined : "calc(100% + 5px)",
                bottom: !bottom ? undefined : "calc(100% + 5px)",
                borderRadius: 5,
                left: -1,
                right: -1,
                zIndex: 1,
                overflowY: "auto",
              }}
            >
              <div style={{ position: "relative" }}>
                {value?.length > 0
                  ? data.map((item, index) => {
                      const isSelected = item === value;
                      const isLast = index === data?.length - 1;
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            getDetails({ placeId: item.place_id }).then(
                              (res) => {
                                console.log(res);
                                const postalCode =
                                  res.address_components.find((a) =>
                                    a.types.includes("postal_code")
                                  )?.long_name || null;
                                const city =
                                  res.address_components.find((a) =>
                                    a.types.includes("locality")
                                  )?.long_name || null;
                                const streetNumber =
                                  res?.address_components?.find((a) =>
                                    a.types.includes("street_number")
                                  )?.long_name || null;
                                const route =
                                  res?.address_components?.find((a) =>
                                    a.types.includes("route")
                                  )?.long_name || null;
                                const phoneNumber =
                                  res?.formatted_phone_number?.replaceAll(
                                    " ",
                                    ""
                                  ) || null;
                                const name = res?.name || null;

                                onSelect({
                                  name,
                                  postalCode,
                                  city,
                                  streetNumber,
                                  route,
                                  phoneNumber,
                                });
                                setDropdownVisible(false);
                              }
                            );
                          }}
                          style={{
                            display: "flex",
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 15,
                            paddingRight: 15,
                            alignItems: "center",
                            backgroundColor: isSelected
                              ? Palette.blueTranslucent
                              : undefined,
                            cursor: "pointer",
                            borderBottom:
                              !isLast && `1px solid ${Palette.grey}`,
                          }}
                        >
                          {item.description}
                        </div>
                      );
                    })
                  : suggestions.map((item, index) => {
                      const isSelected = item === value;
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            onSelect(item);
                            setDropdownVisible(false);
                          }}
                          style={{
                            height: 50,
                            display: "flex",
                            paddingLeft: 15,
                            paddingRight: 15,
                            alignItems: "center",
                            backgroundColor: isSelected
                              ? Palette.blueTranslucent
                              : undefined,
                            cursor: "pointer",
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                {loading && <Loading />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
