//HOSTAL FACTURATION

import Palette from "./Palette";

const flexBase = {
  display: "flex",
};
const flexRowBase = {
  ...flexBase,
  flexDirection: "row",
};
const flexColumnBase = {
  ...flexBase,
  flexDirection: "column",
};

export const containerFlexMain = {
  backgroundColor: Palette.background,
  ...flexColumnBase,
};

export const containerFlex = {
  ...flexColumnBase,
};
export const containerFlexRow = {
  ...flexRowBase,
};

export const containerFlexStart = {
  ...flexColumnBase,
  justifyContent: "flex-start",
};
export const containerFlexRowStart = {
  ...flexRowBase,
  justifyContent: "flex-start",
};

export const containerFlexEnd = {
  ...flexColumnBase,
  justifyContent: "flex-end",
};
export const containerFlexRowEnd = {
  ...flexRowBase,
  justifyContent: "flex-end",
};

export const containerFlexStartStart = {
  ...flexColumnBase,
  justifyContent: "flex-start",
  alignItems: "flex-start",
};
export const containerFlexRowStartStart = {
  ...flexRowBase,
  justifyContent: "flex-start",
  alignItems: "flex-start",
};

export const containerFlexStartCenter = {
  ...flexColumnBase,
  justifyContent: "flex-start",
  alignItems: "center",
};
export const containerFlexRowStartCenter = {
  ...flexRowBase,
  justifyContent: "flex-start",
  alignItems: "center",
};

export const containerFlexStartEnd = {
  ...flexColumnBase,
  justifyContent: "flex-start",
  alignItems: "flex-end",
};
export const containerFlexRowStartEnd = {
  ...flexRowBase,
  justifyContent: "flex-start",
  alignItems: "flex-end",
};

export const containerFlexEndStart = {
  ...flexColumnBase,
  justifyContent: "flex-end",
  alignItems: "flex-start",
};
export const containerFlexRowEndStart = {
  ...flexRowBase,
  justifyContent: "flex-end",
  alignItems: "flex-start",
};

export const containerFlexEndEnd = {
  ...flexColumnBase,
  justifyContent: "flex-end",
  alignItems: "flex-end",
};
export const containerFlexRowEndEnd = {
  ...flexRowBase,
  justifyContent: "flex-end",
  alignItems: "flex-end",
};
export const containerFlexRowEndCenter = {
  ...flexRowBase,
  justifyContent: "flex-end",
  alignItems: "center",
};

export const containerFlexCenterStart = {
  ...flexColumnBase,
  justifyContent: "center",
  alignItems: "flex-start",
};
export const containerFlexRowCenterStart = {
  ...flexRowBase,
  justifyContent: "center",
  alignItems: "flex-start",
};

export const containerFlexCenterEnd = {
  ...flexColumnBase,
  justifyContent: "center",
  alignItems: "flex-end",
};
export const containerFlexRowCenterEnd = {
  ...flexRowBase,
  justifyContent: "center",
  alignItems: "flex-end",
};

export const containerFlexCenterCenter = {
  ...flexColumnBase,
  justifyContent: "center",
  alignItems: "center",
};
export const containerFlexRowCenterCenter = {
  ...flexRowBase,
  justifyContent: "center",
  alignItems: "center",
};

export const containerFlexSpaceBetween = {
  ...flexColumnBase,
  justifyContent: "space-between",
};
export const containerFlexRowSpaceBetween = {
  ...flexRowBase,
  justifyContent: "space-between",
};

export const containerFlexSpaceBetweenCenter = {
  ...flexColumnBase,
  justifyContent: "space-between",
  alignItems: "center",
};
export const containerFlexRowSpaceBetweenCenter = {
  ...flexRowBase,
  justifyContent: "space-between",
  alignItems: "center",
};

export const containerFlexSpaceAround = {
  ...flexColumnBase,
  justifyContent: "space-around",
};
export const containerFlexRowSpaceAround = {
  ...flexRowBase,
  justifyContent: "space-around",
};

export const containerFlexSpaceEvenly = {
  ...flexColumnBase,
  justifyContent: "space-evenly",
};
export const containerFlexRowSpaceEvenly = {
  ...flexRowBase,
  justifyContent: "space-evenly",
};

export const containerFlexWrap = {
  ...flexColumnBase,
  flexWrap: "wrap",
};
export const containerFlexRowWrap = {
  ...flexRowBase,
  flexWrap: "wrap",
};

export const containerFlexWrapStart = {
  ...flexColumnBase,
  flexWrap: "wrap",
  justifyContent: "flex-start",
};
export const containerFlexRowWrapStart = {
  ...flexRowBase,
  flexWrap: "wrap",
  justifyContent: "flex-start",
};

export const containerFlexWrapEnd = {
  ...flexColumnBase,
  flexWrap: "wrap",
  justifyContent: "flex-end",
};
export const containerFlexRowWrapEnd = {
  ...flexRowBase,
  flexWrap: "wrap",
  justifyContent: "flex-end",
};

export const containerFlexWrapCenter = {
  ...flexColumnBase,
  flexWrap: "wrap",
  justifyContent: "center",
};
export const containerFlexRowWrapCenter = {
  ...flexRowBase,
  flexWrap: "wrap",
  justifyContent: "center",
};

export const containerFlexWrapSpaceBetween = {
  ...flexColumnBase,
  flexWrap: "wrap",
  justifyContent: "space-between",
};
export const containerFlexRowWrapSpaceBetween = {
  ...flexRowBase,
  flexWrap: "wrap",
  justifyContent: "space-between",
};

export const containerFlexWrapSpaceAround = {
  ...flexColumnBase,
  flexWrap: "wrap",
  justifyContent: "space-around",
};
export const containerFlexRowWrapSpaceAround = {
  ...flexRowBase,
  flexWrap: "wrap",
  justifyContent: "space-around",
};

export const containerFlexWrapSpaceEvenly = {
  ...flexColumnBase,
  flexWrap: "wrap",
  justifyContent: "space-evenly",
};
export const containerFlexRowWrapSpaceEvenly = {
  ...flexRowBase,
  flexWrap: "wrap",
  justifyContent: "space-evenly",
};

export const containerFlexItem = {
  ...flexColumnBase,
  backgroundColor: Palette.lightBlue,
  borderRadius: 10,
  padding: 10,
  border: `1px solid ${Palette.halfBlue}`,
};
