import { Card } from "@components/shadcn/ui/card";
import React from "react";
import "react-marquee-text/dist/styles.css";
import RideBadgeTrackingTransport from "./RideBadgeTrackingTransport";

function TransportTrackingCard({ transport }) {
  const { carType, patient, driverStatus } = transport;
  const { name } = transport.driver ?? "";
  const { address: addressFrom } = transport.from;
  const { address: addressTo } = transport.to;
  return (
    <Card className="border-bgGrey border-2 relative my-1 py-[5px]">
      <div className="w-full  flex flex-col py-1 relative">
        <div className="w-full h-auto rounded-3xl flex flex-row px-3 items-center">
          <div className="w-1/12"></div>
          <div
            style={{ width: "22px"}}
            className={carType === "VSL" ? "absolute top-[12px]" : "absolute top-[14px]"}
          >
            {carType === "VSL" && (
              <img
                style={{ alignSelf: "center", width: "25px", height: "14px" }}
                src="/icons/veicule_info_modal/vslcar.svg"
              />
            )}
            {carType === "AMBULANCE" && (
              <img width={22} src="/icons/veicule_info_modal/ambulance.svg" />
            )}
          </div>
          <div className="w-7/12 pl-2 flex flex-col items-start">
            <div className="flex">
              {carType === "AMBULANCE" ? (
                <p
                  style={{
                    height: "fit-content",
                    marginRight: 4,
                    fontSize: 12,
                    fontStyle: "italic",
                    color: "#4951ff",
                    fontWeight: "bold"
                  }}
                >
                  AMB
                </p>
              ) : (
                <p
                  style={{
                    height: "fit-content",
                    marginRight: 4,
                    fontSize: 12,
                    fontStyle: "italic",
                    color: "#FFB960",
                    fontWeight: "bold"
                  }}
                >
                  {carType}
                </p>
              )}

              <p
                style={{
                  height: "fit-content",
                  fontSize: 16,
                  lineHeight: 1,
                  fontWeight: "bold",
                  color: "#02196C",
                  textTransform: "capitalize"
                }}
              >
                {`${name}`}
              </p>
            </div>
          </div>
          <div className="w-3/12">
            <div className="flex justify-center">
              <RideBadgeTrackingTransport
                containerClassName="bg-bgGrey flex flex-row item-center justify-center border-2 border-borderV2 rounded-lg cursor-pointer"
                imgWidth="7px"
                imgSrc="/icons/veicule_info_modal/dot_red.svg"
                text="En course"
                textClassName="whitespace-nowrap text-pink"
              />
            </div>
          </div>
        </div>
        <div className="container flex flex-row">
          <div className="w-1/12"></div>
          <div className="w-10/12 pl-4">
            <div className="flex flex-row items-baseline">
              {transport?.returnTransportId && (
                <img width={15} src="/icons/veicule_info_modal/ar.svg" />
              )}
              <p
                style={{
                  marginRight: 4,
                  fontSize: 12,
                  color: "#02196C60",
                  fontWeight: "bold",
                  fontStyle: "italic",
                  height: "fit-content",
                  lineHeight: 0.8,
                  whiteSpace: "nowrap"
                }}
              >
                Mohammed.K Mohammed.K -
                <span style={{ color: "#DB2C73" }}> Rachid.A</span>
              </p>
            </div>
          </div>
        </div>

        <div className=" px-3 mt-[9px]">
          <div className="flex flex-row items-baseline">
            <div className="flex items-center h-fit">
              <img
                width={11}
                className="mr-1"
                src="/icons/transport_tracking/location_tracking.svg"
              />
              <p
                style={{
                  fontSize: 12,
                  lineHeight: 1,
                  height: "fit-content"
                }}
                className="text-lightBlue"
              >
                {addressFrom}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-baseline">
            <div className="flex items-center">
              <img
                width={11}
                className="mr-1"
                src="/icons/transport_tracking/navigation_tracking.svg"
              />
              <div
                style={{ fontSize: 12, lineHeight: 1.5 }}
                className="text-lightBlue"
              >
                {driverStatus === "RETRIEVING_PATIENT" && (
                  <>
                    En direction du patient
                    <span className="text-festiveFennec">
                      {` ${patient.firstName} ${patient.lastName}`}
                    </span>
                  </>
                )}
                {driverStatus === "DEPOSITING_PATIENT" && (
                  <>
                    Vers le lieu de RDV
                    <span className="text-festiveFennec">{` ${addressTo}`}</span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
export default TransportTrackingCard;
