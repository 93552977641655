import Button from "@components/Button";
import Palette from "@styles/Palette";
import React from "react";
import RideBadge from "./RideBadge";

function TransportTrackingFilter({ searchFilter, handleFilter, filterState }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}
      className="mb-3"
    >
      <div style={{ display: "flex" }} className="w-28 justify-between">
        <Button
          onClick={(e) => {
            searchFilter({ ...searchFilter, carType: "VSL" });
            handleFilter(0);
          }}
          imgTop="icons/veicule_info_modal/vslcar.svg"
          text="VSL"
          variant={filterState[0] ? "activeFilter" : "transportTrackingBtns"}
          iconStyle={{ width: "25px" }}
          style={{ color: Palette.kingNacho }}
          containerStyle={{
            display: "flex",
            flexDirection: "column",
            width: 50,
            height: 50,
            cursor: "pointer",
            justifyContent: "space-around",
            paddingTop: 4
          }}
        />

        <Button
          onClick={(e) => {
            searchFilter({ ...searchFilter, carType: "AMBULANCE" });
            handleFilter(1);
          }}
          imgTop="icons/veicule_info_modal/ambulance.svg"
          text="AMB"
          variant={filterState[1] ? "activeFilter" : "transportTrackingBtns"}
          iconStyle={{ width: "25px" }}
          style={{ color: Palette.skyBlue2 }}
          containerStyle={{
            display: "flex",
            flexDirection: "column",
            width: 50,
            height: 50,
            cursor: "pointer",
            justifyContent: "space-around",
            paddingTop: 4
          }}
        />
      </div>
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className="w-7/12 justify-between"
      >
        <div>
          <p className="text-lightBlue leading-3 font-semibold whitespace-nowrap text-center text-sm">
            Statut du véhicule :
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <RideBadge
            containerClassName="flex flex-row item-center justify-center border-2 border-[#F6F7FF] rounded-lg p-1 mr-2 cursor-pointer"
            imgWidth="10px"
            imgSrc="/icons/veicule_info_modal/dot_blue.svg"
            text="Disponible"
            textClassName="whitespace-nowrap text-skyBlue2"
            onClick={() => {
              searchFilter({ ...searchFilter, vehiculeStatus: "disponible" });
              handleFilter(2);
            }}
            isActive={filterState[2]}
          />
          <RideBadge
            containerClassName="flex flex-row item-center justify-center  border-2 border-[#F6F7FF] rounded-lg p-1 cursor-pointer"
            imgWidth="10px"
            imgSrc="/icons/veicule_info_modal/dot_red.svg"
            text="En course"
            textClassName="whitespace-nowrap text-festiveFennec"
            onClick={() => {
              searchFilter({ ...searchFilter, vehiculeStatus: "encourse" });
              handleFilter(3);
            }}
            isActive={filterState[3]}
          />
        </div>
      </div>
    </div>
  );
}

export default TransportTrackingFilter;
