import moment from "moment";
import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import TransportRegulationCard from "./TransportRegulationCard";

function TransportRegulationList({ transports }) {
  return (
    <div style={{ height: "410px" }} className={`custom-scrollbar`}>
      {transports.length > 0 &&
        transports
          .map((item, i) => {
            const prevStartDate = i > 0 ? transports[i - 1].startDate : null;
            const isSameAsPrevDay =
              prevStartDate &&
              moment(prevStartDate).isSame(item.startDate, "d");
            return (
              <div style={{ width: "320px" }} key={item.id}>
                {!isSameAsPrevDay && (
                  <VisibilitySensor offset={{ top: 300 }} partialVisibility>
                    <div
                      className="capitalize"
                      id={moment(item.startDate).format("YYYY-MM-DD")}
                      style={{
                        fontSize: 17,
                        fontWeight: "bold",
                        marginBottom: 10,
                        marginTop: i !== 0 ? 5 : 0,
                        color: "#02196C"
                      }}
                    >
                      {moment(item.startDate).format("dddd DD MMMM YYYY")}
                    </div>
                  </VisibilitySensor>
                )}

                <TransportRegulationCard key={item.id} transport={item} />
              </div>
            );
          })}
    </div>
  );
}

export default TransportRegulationList;
