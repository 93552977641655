import Palette from "../styles/Palette";

export const STATUS = {
  PENDING: {
    name: "En attente",
    style: {
      backgroundColor: Palette.blueTranslucent,
      borderColor: Palette.blueSemiTranslucent,
      color: Palette.blue,
    },
  },
  ACCEPTED: {
    name: "Accepté",
    style: {
      backgroundColor: Palette.blueTranslucent,
      borderColor: Palette.blueSemiTranslucent,
      color: Palette.blue,
    },
  },
  ASSIGNED: {
    name: "Assigné",
    style: {
      backgroundColor: Palette.blueTranslucent,
      borderColor: Palette.blueSemiTranslucent,
      color: Palette.blue,
    },
  },
  REFUSED: {
    name: "Refusé",
    style: {
      backgroundColor: Palette.redTranslucent,
      color: Palette.red,
    },
  },

  CANCELED: {
    name: "Annulé par l'",
    style: {
      backgroundColor: Palette.redTranslucent,
      borderColor: Palette.red,
      color: Palette.red,
    },
  },

  IN_PROGRESS: {
    name: "En cours",
    style: {
      backgroundColor: Palette.salmonTranslucent,
      borderColor: Palette.salmon,
      color: Palette.salmon,
    },
  },
  BOURSE: {
    name: "Bourse",
    style: {
      backgroundColor: Palette.yellowTranslucent,
      borderColor: Palette.yellow,
      color: Palette.yellow,
    },
  },
  ENDED: {
    name: "Terminé",
    style: {
      backgroundColor: Palette.greenTranslucent,
      borderColor: Palette.green,
      color: Palette.green,
    },
  },
};
