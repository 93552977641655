import React from "react";
import Palette from "../styles/Colors";
import styled from "styled-components";
import Fonts from "../styles/fonts";
import { containerFlex, containerFlexCenterCenter } from "../styles/main";
import Loading from "./Loading";
import useDataFromRef from "../hooks/useDataFromRef";
import { STATUS } from "../helpers/status";

const Row = styled.tbody(({ isSelected }) => ({
  transition: "all 0.2s ease-in-out",
  backgroundColor: isSelected ? Palette.lightBlue : "transparent",
  "&:hover": {
    backgroundColor: isSelected ? Palette.halfBlue : Palette.halfBlue,
  },
}));

const Title = styled.th(({ style }) => ({
  ...Fonts.medium(12, Palette.darkBlue),
  textAlign: "center",
  padding: "8px 16px",
  color: Palette.darkBlue,
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    cursor: "pointer",
    color: Palette.blueGrey,
  },
  ...style,
}));

export default function Table({
  columns = [],
  data = [],
  selected,
  setSelected,
  containerStyle,
  loading,
}) {
  // const terminatedStatus = ["ENDED", "REFUSED", "CANCELED"];

  // const { data: allerTransport } = useDataFromRef({
  //   ref: Transports.doc(goToTransportId),
  //   refreshArray: [goToTransportId],
  //   format: formatTransportDoc,
  //   condition: !!goToTransportId,
  //   simpleRef: true,
  //   listener: true,
  // });

  // if (goToTransportId && !terminatedStatus.includes(allerTransport?.status)) {
  //   console.log(
  //     "//////////////// STATUS DU TRANSPORT ALLER avec pour returnTransportId",
  //     allerTransport?.status
  //   );
  //   return null;
  // }

  return (
    <div
      style={{
        ...containerFlex,
        position: "relative",
        whiteSpace: "nowrap",
        overflow: "auto",
        ...containerStyle,
        flex: 1,
      }}
    >
      <table
        style={{
          border: 0,
          position: "relative",
          height: data?.length && !loading ? undefined : "100%",
        }}
      >
        <thead
          style={{
            zIndex: 1,
            textTransform: "uppercase",
            backgroundColor: "rgb(250,250,250)",
            height: 30,
          }}
        >
          <tr>
            {columns.map(({ title, key }, index) => {
              return (
                <Title onClick={() => {}} key={`${index}-${key}`}>
                  <div>{title}</div>
                </Title>
              );
            })}
          </tr>
        </thead>

        {loading ? (
          <div
            style={{
              ...containerFlex,
              position: "absolute",
              top: 30,
              right: 0,
              left: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading />
          </div>
        ) : data?.length ? (
          data.map((item, index) => {
            const isSelected = selected?.id === item?.id;
            return (
              <Row
                {...{ isSelected }}
                key={`${index}-${item.id}`}
                style={{
                  height: 50,
                  ...Fonts.medium(12),
                  cursor: "pointer",
                  backgroundColor: STATUS[item?.status]?.style?.backgroundColor,
                }}
                onClick={() => {
                  if (isSelected) {
                    setSelected(null);
                  } else {
                    setSelected(item);
                  }
                }}
              >
                <tr>
                  {columns.map(({ key, render, style }, index2) => {
                    return (
                      <td
                        key={`${index}-${index2}-${key}`}
                        style={{
                          padding: "0 16px",
                          minHeight: 50,
                          ...style,
                        }}
                      >
                        <div
                          style={{
                            ...containerFlexCenterCenter,
                            height: "100%",
                            // backgroundColor:
                            //   STATUS[item?.status]?.style?.backgroundColor,
                          }}
                        >
                          {render?.(item)}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </Row>
            );
          })
        ) : (
          <div
            style={{
              ...containerFlex,
              position: "absolute",
              top: 100,
              right: 0,
              left: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{ ...Fonts.bold(21, Palette.darkBlue), marginBottom: 10 }}
            >
              Aucun transport pour cette date
            </div>
            <div style={{ ...Fonts.regular(16, Palette.darkBlue) }}>
              Sélectionnez une autre date ou ajustez les filtres
            </div>
          </div>
        )}
      </table>
    </div>
  );
}
