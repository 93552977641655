import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import { useHistory, useLocation } from "react-router-dom";

import PatientInformations from "./PatientInformations";
import TransportInformations from "./TransportInformations";
import Place from "./Place";
import Button from "../../../../components/Button";
import Palette from "../../../../styles/Palette";
import { toast } from "react-hot-toast";
import processTransportForm from "../../../../actions/Transports";
import firebase from "firebase/compat/app";
import Colors from "../../../../styles/Colors";
import Planning from "./Planning";
import { useAuth } from "../../../../providers/Auth";
import { format } from "date-fns";
import { formatTransportData } from "../../../../services/Firebase";

export default () => {
  const { user } = useAuth();

  const role = user?.role;

  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const transportId = params.get("editTransport");

  const [recurringView, setReccuringView] = useState("PLACE");
  const [selectedDates, setSelectedDates] = useState([]);

  const [patient, setPatient] = useState({});
  const [transportInformations, setTransportInformations] = useState({});
  const [from, setFrom] = useState({});
  const [to, setTo] = useState({});

  const patientInformationsRef = useRef();
  const transportInformationsRef = useRef();
  const planningRef = useRef();
  const fromRef = useRef();
  const toRef = useRef();

  const next = async () => {
    try {
      patientInformationsRef.current?.validateInputs();
      transportInformationsRef.current?.validateInputs();
      if (transportInformations?.recurringType === "RECURRING") {
        planningRef.current?.validateInputs();
      }
      fromRef.current?.validateInputs();
      toRef.current?.validateInputs();
      await processTransportForm({
        transportInformations,
        patient,
        from,
        to,
        complement: {},
        regulatedBy: firebase.auth().currentUser.uid,
        role: role,
        transportId,
        dates: selectedDates,
      });
      if (transportId !== "new" && transportId !== null) {
        toast.success("Transport modifié.");
      } else {
        toast.success("Transport(s) créé(s).");
      }
      setReccuringView("PLACE");
      setSelectedDates([]);
      setPatient({});
      setTransportInformations({});
      setFrom({});
      setTo({});
      history.push(location.pathname);
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };

  const onClose = () => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir quitter la création de ce transport ? Toutes les données seront perdues."
      )
    ) {
      setSelectedDates([]);
      setPatient({});
      setTransportInformations({});
      setFrom({});
      setTo({});
      history.goBack();
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (transportId !== "new" && transportId !== null) {
      setIsLoading(true);
      firebase
        .firestore()
        .collection("transports")
        .doc(transportId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            // Assurez-vous que formatTransportData retourne une promesse
            formatTransportData(data)
              .then((formatedTransportData) => {
                setPatient(data.patient);
                setTransportInformations(formatedTransportData);
                setFrom(data.from);
                setTo(data.to);
                if (data.transportInformations?.recurringType === "RECURRING") {
                  setReccuringView("PLANNING");
                  setSelectedDates(data.dates);
                }
              })
              .catch((error) => {
                console.error(
                  "Erreur lors du formatage des données de transport:",
                  error
                );
              });
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération du document:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [transportId]);

  return (
    !!transportId && (
      <Modal
        showPrevButton={false}
        style={{ width: 1200 }}
        title={
          transportId !== "new"
            ? `Transport ${transportId}`
            : `Nouveau transport`
        }
        onClose={onClose}
      >
        <div style={{ position: "relative" }}>
          {isLoading ? (
            <p>Chargement...</p>
          ) : (
            <>
              <PatientInformations
                edit={transportId !== "new"}
                ref={patientInformationsRef}
                containerStyle={{ marginBottom: 20 }}
                {...{ patient, setPatient }}
              />
              <div className={"containerRow"}>
                <div style={{ flex: 1 }}>
                  <TransportInformations
                    edit={transportId !== "new"}
                    ref={transportInformationsRef}
                    {...{
                      transport: transportInformations,
                      setTransport: setTransportInformations,
                    }}
                  />
                </div>
                <div style={{ flex: 2, marginLeft: 20, position: "relative" }}>
                  {transportInformations?.recurringType === "RECURRING" && (
                    <div
                      className={"containerRow"}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: -10,
                        height: 30,
                        width: "calc(50% - 10px)",
                        fontWeight: 600,
                      }}
                    >
                      {[
                        { name: "Planning", value: "PLANNING" },
                        { name: "Trajet", value: "PLACE" },
                      ].map((s) => {
                        const isSelected = s.value === recurringView;
                        return (
                          <div
                            key={s.value}
                            onClick={() => {
                              setReccuringView(s.value);
                            }}
                            className={"flex"}
                            style={{
                              cursor: "pointer",
                              color: isSelected
                                ? Palette.blue
                                : Colors.text.secondary,
                              borderBottom: `${isSelected ? 4 : 2}px solid ${
                                isSelected ? Palette.blue : Palette.lightGrey
                              }`,
                              textAlign: "center",
                            }}
                          >
                            {s.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {recurringView === "PLANNING" &&
                  transportInformations?.recurringType === "RECURRING" ? (
                    <Planning
                      ref={planningRef}
                      {...{ selectedDates, setSelectedDates }}
                    />
                  ) : (
                    <>
                      <Place
                        ref={fromRef}
                        type="START"
                        containerStyle={{ marginBottom: 20 }}
                        {...{
                          place: from,
                          setPlace: setFrom,
                          patient,
                        }}
                      />
                      <Place
                        ref={toRef}
                        type="END"
                        {...{ place: to, setPlace: setTo, patient }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div
                className={"containerRow"}
                style={{
                  justifyContent: "flex-end",
                  position: "sticky",
                  bottom: 0,
                  right: 0,
                  backgroundColor: Palette.white,
                  paddingTop: 10,
                  paddingLeft: 10,
                }}
              >
                <Button
                  variant={"secondary"}
                  text={"Annuler"}
                  onClick={onClose}
                  containerStyle={{ marginRight: 10 }}
                />
                <Button text={"Suivant"} onClick={next} />
              </div>
            </>
          )}
        </div>
      </Modal>
    )
  );
};
