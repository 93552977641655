//HOSTAL FACTURATION

import Palette from "./Palette";

export const scaleFromFigma = (s) => s;

const base = (fontSize, color) => ({
  fontSize: scaleFromFigma(fontSize),
  color: color || Palette.darkBlue,
});

const Fonts = {
  bold: (fontSize, color) => ({
    ...base(fontSize, color),
    fontWeight: "bold",
  }),
  semibold: (fontSize, color) => ({
    ...base(fontSize, color),
    fontWeight: "600",
  }),
  medium: (fontSize, color) => ({
    ...base(fontSize, color),
    fontWeight: "500",
  }),
  regular: (fontSize, color) => ({
    ...base(fontSize, color),
    fontWeight: "400",
  }),
};

export default Fonts;
