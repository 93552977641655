import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import moment from "moment";
import Input from "../../../../components/Input";
import Palette from "../../../../styles/Palette";
import DriverType from "../../../../components/DriverType";
import TransportTypeSelector from "../../../../components/TransportTypeSelector";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { REASONS } from "../../../../data/transports";
import Checkbox from "../../../../components/Checkbox";
import { formatTransportDoc, Transports } from "../../../../services/Firebase";
import useDataFromRef from "../../../../hooks/useDataFromRef";

registerLocale("fr", fr);

const DateInput = forwardRef(({ onClick: onFocus, ...props }, ref) => (
  <Input {...{ onFocus, ref }} {...props} />
));

export default forwardRef(
  ({ edit, transport = {}, setTransport = () => {} }, ref) => {
    const [carType, setCarType] = useState(transport?.carType || "VSL");
    const [recurringType, setRecurringType] = useState(
      transport?.recurringType || "SINGLE"
    );
    const [reason, setReason] = useState(transport?.reason || "");
    const [returnTransportId, setReturnTransportId] = useState(
      transport?.returnTransportId || ""
    );

    const { data: dataReturnTransport } = useDataFromRef({
      ref: !!returnTransportId && Transports.doc(returnTransportId),
      refreshArray: [returnTransportId],
      format: formatTransportDoc,
      condition: !!returnTransportId,
      simpleRef: true,
      listener: true,
    });

    // Transport

    // const [startDate, setStartDate] = useState(moment().minute(0).toDate());
    // const [endDate, setEndDate] = useState(moment().minute(30).toDate());
    // console.log("DATE /////// : ", transport?.startDate, transport?.endDate);
    // Transport;

    const [startDate, setStartDate] = useState(
      transport?.startDate
        ? moment(transport?.startDate).toDate()
        : moment().startOf("hour").toDate()
    );
    const [endDate, setEndDate] = useState(
      transport?.endDate
        ? moment(transport?.endDate).toDate()
        : moment().startOf("hour").add(30, "minutes").toDate()
    );

    useEffect(() => {
      if (startDate && endDate) {
        const date = moment(startDate).format("DD/MM/YYYY");
        const hour = moment(endDate).format("HH:mm");
        setEndDate(moment(`${date} ${hour}`, "DD/MM/YYYY HH:mm").toDate());
      }
    }, [startDate]);

    // !Transport

    // Return transport
    const [isReturn, setHasReturn] = useState(
      transport?.returnTransportId ? true : false
    );
    const [returnDate, setReturnDate] = useState(
      dataReturnTransport && edit === true
        ? moment(dataReturnTransport.startDate).toDate()
        : moment(startDate).add(1, "d").toDate()
    );

    const [returnStartDate, setReturnStartDate] = useState(
      moment().add(1, "d").toDate()
    );
    const [returnEndDate, setReturnEndDate] = useState(
      moment().add(1, "d").toDate()
    );
    const [returnHour, setReturnHour] = useState(
      moment().add(1, "hour").minute(0).toDate()
    );

    useEffect(() => {
      const date = moment(returnDate).format("DD/MM/YYYY");
      const returnStartHour = moment().hour(23).minute(45).format("HH:mm");
      const returnEndHour = moment().hour(23).minute(55).format("HH:mm");
      setReturnStartDate(
        moment(`${date} ${returnStartHour}`, "DD/MM/YYYY HH:mm").toDate()
      );
      setReturnEndDate(
        moment(`${date} ${returnEndHour}`, "DD/MM/YYYY HH:mm").toDate()
      );
    }, [returnDate]);
    // !Return transport

    // SAMU
    const [isSamu, setIsSamu] = useState(transport?.samuNumber ? -1 : false);

    const [samuNumber, setSamuNumber] = useState(
      transport ? transport?.samuNumber : ""
    );
    // !SAMU

    const roundedStartDate = moment(startDate).startOf("day");
    const roundedReturnDate = moment(returnDate).startOf("day");

    const validateInputs = () => {
      if (!reason) throw new Error("La raison du transport est requise");
      if (isSamu && !samuNumber)
        throw new Error("Le numéro du samu est requis");
      if (moment(startDate).isAfter(endDate))
        throw new Error(
          "L'heure de prise en charge doit être antérieure à l'heure de rendez-vous"
        );
      if (isReturn) {
        if (recurringType === "SINGLE") {
          if (roundedStartDate.isSame(roundedReturnDate) === false) {
            if (moment(startDate).isAfter(returnDate)) {
              throw new Error("L'aller doit être antérieur au retour");
            }
          }
        } else {
          const todayDate = moment().format("DD/MM/YYYY");
          const tmpEndHour = moment(endDate).format("HH:mm");
          const tmpReturnHour = moment(returnHour).format("HH:mm");

          const tmpEndDate = moment(
            `${todayDate} ${tmpEndHour}`,
            "DD/MM/YYYY HH:mm"
          ).toDate();
          const tmpReturnDate = moment(
            `${todayDate} ${tmpReturnHour}`,
            "DD/MM/YYYY HH:mm"
          ).toDate();

          if (moment(tmpEndDate).isSameOrAfter(tmpReturnDate)) {
            throw new Error("L'aller doit être antérieur au retour");
          }
        }
      }
    };

    useEffect(() => {
      if (isReturn !== false && dataReturnTransport) {
        console.log("RETURN TRANSPORT : ", dataReturnTransport.startDate);
      }
    }, [dataReturnTransport, isReturn]);

    const deps = [
      carType,
      returnTransportId,
      recurringType,
      startDate,
      endDate,
      reason,
      isReturn,
      returnDate,
      isSamu,
      samuNumber,
      returnHour,
    ];

    useImperativeHandle(
      ref,
      () => ({
        validateInputs,
      }),
      deps
    );

    useEffect(() => {
      if (isSamu === false) {
        setSamuNumber("");
      }
    }, [isSamu]);

    useEffect(() => {
      // if (edit === true && transport?.id !== undefined) return;
      const toAdd = {
        carType,
        reason,
        recurringType,
        startDate,
        endDate,
        samuNumber,
      };
      if (isReturn) {
        if (recurringType === "SINGLE") {
          console.log("RETURN DATE : ", returnDate, returnEndDate);
          toAdd.returnDate = returnStartDate;
          toAdd.returnEndDate = returnEndDate;
        } else {
          toAdd.returnHour = returnHour;
        }
      }
      if (isSamu) {
        toAdd.samuNumber = samuNumber;
      }
      setTransport(toAdd);
    }, deps);

    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontSize: 20,
            fontWeight: 600,
            color: Palette.red,
            marginBottom: 10,
          }}
        >
          Détails du transport
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DriverType
              onSelect={setCarType}
              selected={carType}
              containerStyle={{ marginBottom: 10 }}
            />
            <TransportTypeSelector
              selected={recurringType}
              onSelect={setRecurringType}
              containerStyle={{
                marginBottom: 10,
              }}
            />
          </div>
          <div style={{ display: "flex", marginBottom: 10 }}>
            {recurringType === "SINGLE" && (
              <div className={"flex"} style={{ marginRight: 10 }}>
                <DatePicker
                  required
                  locale="fr"
                  dateFormat="d/MM/yyyy"
                  showTimeSelect={false}
                  onChange={setStartDate}
                  selected={startDate}
                  placeholder="Date de l'aller"
                  customInput={<DateInput iconRight={"/icons/date.png"} />}
                />
              </div>
            )}
            <div className={"flex"}>
              <Input
                dropdown
                bottom
                placeholder={"Raison"}
                items={REASONS}
                value={reason}
                onChange={setReason}
              />
            </div>
          </div>
          <div className={"containerRow"} style={{ marginBottom: 10 }}>
            <div style={{ display: "flex", flex: 1, marginRight: 10 }}>
              <DatePicker
                required
                locale="fr"
                dateFormat="HH:mm"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                onChange={(date) => {
                  setStartDate(date);
                  // Mettre à jour automatiquement l'heure de fin pour qu'elle soit 30 minutes plus tard
                  setEndDate(moment(date).add(30, "minutes").toDate());
                }}
                selected={startDate}
                placeholder="Heure"
                customInput={
                  <DateInput
                    onLeft={() => (
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          marginRight: 10,
                        }}
                      >
                        PEC
                      </div>
                    )}
                  />
                }
              />
            </div>
            <div style={{ display: "flex", flex: 1 }}>
              <DatePicker
                required
                locale="fr"
                dateFormat="HH:mm"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                onChange={(date) => {
                  // Vérifier que la nouvelle heure de RDV est au moins 30 minutes après PEC
                  if (
                    moment(date).isSameOrAfter(
                      moment(startDate).add(30, "minutes")
                    )
                  ) {
                    setEndDate(date);
                  } else {
                    // Si non, définir RDV 30 minutes après PEC
                    setEndDate(moment(startDate).add(30, "minutes").toDate());
                  }
                }}
                selected={endDate}
                placeholder="Heure"
                customInput={
                  <DateInput
                    onLeft={() => (
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          marginRight: 10,
                        }}
                      >
                        RDV
                      </div>
                    )}
                  />
                }
              />
            </div>
          </div>
          <div className={"containerRow"}>
            <div className={"flex"}>
              <Checkbox
                value={isReturn}
                onChange={setHasReturn}
                name={"Transport Retour"}
              />
              {isReturn &&
                (recurringType === "SINGLE" ? (
                  <DatePicker
                    required
                    locale="fr"
                    dateFormat="d/MM/yyyy"
                    showTimeSelect={false}
                    onChange={setReturnDate}
                    selected={returnDate}
                    placeholder="Date du retour"
                    customInput={
                      <DateInput
                        iconRight={"/icons/date.png"}
                        style={{ marginTop: 10 }}
                      />
                    }
                  />
                ) : (
                  <DatePicker
                    required
                    locale="fr"
                    dateFormat="HH:mm"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    onChange={setReturnHour}
                    selected={returnHour}
                    placeholder="Heure"
                    customInput={
                      <DateInput
                        style={{ marginTop: 10 }}
                        onLeft={() => (
                          <div
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              marginRight: 10,
                            }}
                          >
                            PEC
                          </div>
                        )}
                      />
                    }
                  />
                ))}
            </div>
            <div className={"flex"} style={{ marginLeft: 10 }}>
              <Checkbox value={isSamu} onChange={setIsSamu} name={"SAMU"} />
              {isSamu && (
                <Input
                  required
                  value={samuNumber}
                  onChange={setSamuNumber}
                  placeholder={"SAMU"}
                  style={{ marginTop: 10 }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
