import React from "react";
import Palette from "../styles/Palette";

export default function SegmentedControl({
  items = [],
  selected,
  onSelect,
  containerStyle,
}) {
  return (
    <div
      className={"containerRow"}
      style={{
        backgroundColor: Palette.grey,
        padding: 4,
        borderRadius: 7.5,
        height: 26,
        ...containerStyle,
      }}
    >
      {items.map(({ text }, index) => {
        const isSelected = selected === index;

        return (
          <div
            onClick={() => onSelect(index)}
            className={"containerCenter"}
            key={index}
            style={{
              backgroundColor: isSelected ? Palette.blue : undefined,
              fontSize: 14,
              fontWeight: "500",
              padding: 5,
              width: 175,
              borderRadius: 5,
              color: isSelected ? Palette.white : undefined,
              cursor: "pointer",
            }}
          >
            {text}
          </div>
        );
      })}
    </div>
  );
}
