import React from "react";
import Palette from "../styles/Palette";
import Global from "../styles/Global";

export default ({ visible = false, children, containerStyle }) => {
  if (!visible) return null;
  return (
    <div
      className={"container"}
      style={{
        zIndex: 1,
        position: "absolute",
        top: "calc(100% + 10px)",
        left: 0,
        right: 0,
        borderRadius: 10,
        padding: 20,
        backgroundColor: Palette.white,
        ...Global.bigShadow,
        ...containerStyle,
      }}
    >
      {children}
    </div>
  );
};
