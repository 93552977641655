import React from "react";

function RideBadgeTrackingTransport({
  containerClassName,
  imgSrc,
  imgWidth,
  imgClassName,
  textClassName,
  color,
  text,
  onClick,
  isActive = false
}) {
  return (
    <div
      onClick={onClick}
      className={`${containerClassName} ${isActive ? "bg-[#F6F7FF]" : ""}`}
    >
      <img width={imgWidth} src={imgSrc} className={imgClassName} />
      <p
        className={textClassName}
        style={{
          color: { color },
          marginLeft: 2,
          fontSize: 9,
          fontWeight: "bold"
        }}
      >
        {text}
      </p>
    </div>
  );
}

export default RideBadgeTrackingTransport;
