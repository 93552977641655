import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Input from "../../../../components/Input";
import DateTimePicker from "react-datetime-picker";
import "../../../../styles/DateTimePicker.css";

import InputPlacesAuto from "../../../../components/InputPlacesAuto";
import SegmentedControl from "../../../../components/SegmentedControl";
import algoliasearch from "algoliasearch/lite";
import firebase from "firebase/compat/app";
import moment from "moment";
import { PatientList } from "../../Patients";
import Loading from "../../../../components/Loading";
import { config } from "../../../../config";

const client = algoliasearch(config.algolia.appId, config.algolia.apiKey);
const index = client.initIndex("patients");

// eslint-disable-next-line no-unused-vars
function SearchPatient({ patient, setPatient }) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(
    patient?.id ? `${patient.lastName} ${patient.firstName}` : ""
  );
  const [searchResults, setSearchResults] = useState([]);
  const { uid } = firebase.auth().currentUser;

  useEffect(() => {
    handleSearch(search);
  }, [search]);

  function handleSearch(text) {
    if (text.length > 1) {
      setLoading(true);
      index
        .search(text)
        .then(({ hits }) =>
          setSearchResults(
            hits
              .filter((p) => p.createdBy === uid)
              .map((h) => {
                const tmp = h;
                tmp.id = tmp.objectID;
                tmp.birthDate = moment(tmp.birthDate).toDate();
                delete tmp.objectID;
                return tmp;
              })
          )
        )
        .finally(() => setLoading(false));
    } else {
      setSearchResults([]);
    }
  }

  return (
    <div
      style={{
        flex: 1,
        marginBottom: 20,
      }}
    >
      <Input
        required
        value={search}
        onChange={setSearch}
        placeholder={"Rechercher un patient..."}
        iconRight={"/icons/search.png"}
        style={{ marginBottom: 10 }}
      />
      <div style={{ position: "relative" }}>
        <PatientList
          selected={patient}
          patients={searchResults}
          onClick={setPatient}
          containerStyle={{ minHeight: "20vh", padding: 0 }}
        />
        {loading && (
          <Loading
            absolute
            containerStyle={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
          />
        )}
      </div>
    </div>
  );
}

const AddPatient = ({
  patient = {},
  setPatient = () => {},
  containerStyle,
}) => {
  const [gender, setGender] = useState(patient?.gender || null);
  const [firstName, setFirstName] = useState(patient?.firstName || null);
  const [lastName, setLastName] = useState(patient?.lastName || null);
  const [birthDate, setBirthDate] = useState(patient?.birthDate || null);
  const [socialSecurityNumber, setSocialSecurityNumber] = useState(
    patient?.socialSecurityNumber || null
  );
  const [phoneNumber, setPhoneNumber] = useState(patient?.phoneNumber || null);
  const [address, setAddress] = useState(patient?.address?.address || null);
  const [postalCode, setPostalCode] = useState(
    patient?.address?.postalCode || null
  );
  const [city, setCity] = useState(patient?.address?.city || null);
  const [complement, setComplement] = useState(
    patient?.address?.complement || null
  );
  const [moreNotes, setMoreNotes] = useState(patient?.moreNotes || null);

  const handleAutocomplete = ({ streetNumber, route, city, postalCode }) => {
    setAddress(`${streetNumber || ""} ${route || ""}`);
    setCity(city || "");
    setPostalCode(postalCode || "");
  };

  const deps = [
    gender,
    firstName,
    lastName,
    birthDate,
    socialSecurityNumber,
    address,
    postalCode,
    city,
    phoneNumber,
    complement,
    moreNotes,
  ];

  useEffect(() => {
    const tmp = {
      gender,
      firstName,
      lastName,
      birthDate,
      socialSecurityNumber,
      phoneNumber,
      address: {
        address,
        postalCode,
        city,
        complement,
        moreNotes,
      },
    };
    if (patient?.id) tmp.id = patient.id;
    setPatient(tmp);
  }, deps);

  return (
    <div className={"container flex"} style={containerStyle}>
      <div className={"containerRow"}>
        <div className={"container flex"}>
          <div className={"containerRow"} style={{ marginBottom: 10 }}>
            <Input
              label={"Sexe"}
              required
              dropdown
              items={[
                { name: "M", value: "male" },
                { name: "Mme", value: "female" },
              ]}
              value={gender}
              onChange={setGender}
              style={{ marginRight: 10 }}
            />
            <Input
              label={"Prénom"}
              required
              value={firstName}
              onChange={setFirstName}
              placeholder={"Prénom"}
              style={{ marginRight: 10 }}
              className={"flex"}
            />
            <Input
              label={"Nom"}
              required
              value={lastName}
              onChange={setLastName}
              placeholder={"Nom"}
              className={"flex"}
            />
          </div>
          <>
            <div style={{ fontStyle: 15, fontWeight: 500, marginBottom: 5 }}>
              Date de naissance *
            </div>
            <DateTimePicker
              required
              disableClock
              disableCalendar
              onChange={setBirthDate}
              value={birthDate}
              format="d/M/yyyy"
              placeholder="Date de naissance"
              label="Date de naissance"
            />
          </>
          <Input
            value={phoneNumber}
            onChange={setPhoneNumber}
            placeholder={"Numéro de téléphone"}
            label={"Numéro de téléphone"}
            style={{ marginBottom: 10 }}
          />
          <Input
            value={socialSecurityNumber}
            onChange={setSocialSecurityNumber}
            placeholder={"Numéro de sécurité sociale"}
            label={"Numéro de sécurité sociale"}
            style={{ marginBottom: 10 }}
          />
        </div>
        <div
          className={"container flex"}
          style={{
            marginLeft: 20,
          }}
        >
          <InputPlacesAuto
            required
            label={"Rue"}
            placeholder={"Rue"}
            style={{ marginBottom: 10 }}
            value={address}
            onChange={setAddress}
            onSelect={handleAutocomplete}
            iconRight={"/icons/search.png"}
          />
          <div className={"containerRow"} style={{ marginBottom: 10 }}>
            <Input
              required
              value={postalCode}
              onChange={setPostalCode}
              placeholder={"Code postal"}
              label={"Code postal"}
              style={{ marginRight: 10 }}
            />
            <Input
              required
              value={city}
              onChange={setCity}
              placeholder={"Ville"}
              label={"Ville"}
            />
          </div>
          <Input
            value={complement}
            onChange={setComplement}
            placeholder={"Complément"}
            label={"Complément"}
            style={{ marginBottom: 10 }}
          />
          <Input
            multiline
            placeholder={"Autres informations..."}
            label={"Autres informations"}
            value={moreNotes}
            onChange={setMoreNotes}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(({ patient, setPatient, showHeader = true }, ref) => {
  const [type, setType] = useState(patient?.id && showHeader ? 1 : 0);
  const Component = type === 0 ? AddPatient : SearchPatient;

  const validateInputs = () => {
    if (!patient.gender) throw new Error("Le sexe du patient est requis");
    if (!patient.firstName) throw new Error("Le prénom du patient est requis");
    if (!patient.lastName) throw new Error("Le nom du patient est requis");
    if (!patient.birthDate)
      throw new Error("La date de naissance du patient est requise");
  };

  useImperativeHandle(
    ref,
    () => ({
      validateInputs,
    }),
    [patient]
  );

  return (
    <div>
      {showHeader && (
        <>
          <div
            style={{
              fontSize: 24,
              fontWeight: 500,
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            Qui est le patient?
          </div>
          <div className={"containerCenter"}>
            <SegmentedControl
              onSelect={setType}
              selected={type}
              items={[{ text: "Ajouter" }, { text: "Rechercher" }]}
              containerStyle={{ marginBottom: 20 }}
            />
          </div>
        </>
      )}
      <Component {...{ patient, setPatient }} />
    </div>
  );
});
