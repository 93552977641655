import { Card } from "@components/shadcn/ui/card";
import moment from "moment";
import React from "react";
import RideBadge from "./RideBadge";
function TransportRegulationCard({ transport }) {
  const {
    carType,
    startDate,
    endDate,
    status,
    regulatedBy,
    driverStatus,
    isAssign,
    patient
  } = transport;
  const { firstName, lastName } = transport.patient;
  const { postalCode: postalCodeFrom, city: cityFrom } = transport.from;
  const { postalCode: postalCodeTo, city: cityTo } = transport.to;
  const regulationCardstyle = [
    {
      cardStyle: "bg-white border-blueWaiting border-2 relative mb-5 p-3",
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "/icons/veicule_info_modal/dot_blue.svg",
      text: "A réguler",
      textClassName: "whitespace-nowrap text-skyBlue2 leading-[10px]",
      subTextClassName: "",
      subtext: ""
    },
    {
      cardStyle: "bg-cosmicLatte border-blueWaiting border-2 relative mb-5 p-3",
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "/icons/transport_regulation/check.svg",
      text: "Terminé",
      textClassName: "whitespace-nowrap text-chateauGreen leading-[10px]",
      subTextClassName: "font-thin ext-chateauGreen text-end",
      subtext: ""
    },
    {
      cardStyle:
        "bg-lavenderBlush border-blueWaiting border-2 relative mb-5 p-3",
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "/icons/transport_regulation/x.svg",
      text: "Annulé",
      textClassName: "whitespace-nowrap text-pink leading-[10px]",
      subTextClassName: "font-thin ext-pink text-end",
      subtext: ""
    },
    {
      cardStyle: "bg-cornSilk border-blueWaiting border-2 relative mb-5 p-3",
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "/icons/transport_regulation/target.svg",
      text: "Bourse",
      textClassName: "whitespace-nowrap text-selectiveYellow leading-[10px]",
      subTextClassName: "font-thin ext-selectiveYellow text-end",
      subtext: ""
    },
    {
      cardStyle: "bg-snow80 border-blueWaiting border-2 relative mb-5 p-3",
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "",
      text: "En cours",
      textClassName: "whitespace-nowrap text-festiveFennec leading-[10px]",
      subTextClassName: "font-thin ext-festiveFennec text-end",
      subtext: ""
    },
    {
      cardStyle:
        "bg-skyBlue10 border-skyBlue50 border-2 relative mb-5 shadow-[2px_4px_4px_0px_#00000025] p-3",
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "",
      text: "Nouveau",
      textClassName: "whitespace-nowrap text-neonBlue leading-[10px]",
      subTextClassName: "font-thin ext-neonBlue text-end",
      subtext: ""
    },
    {
      cardStyle:
        "bg-skyBlue10 border-skyBlue50 border-2 relative mb-5 shadow-[2px_4px_4px_0px_#00000025] p-3",
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "",
      text: "Attribué",
      textClassName: "whitespace-nowrap text-neonBlue",
      subTextClassName: "font-thin ext-neonBlue text-center",
      subText: "En attente"
    },
    {
      cardStyle:
        "bg-skyBlue10 border-skyBlue50 border-2 relative mb-5 shadow-[2px_4px_4px_0px_#00000025] p-3",
      containerClassName:
        "flex flex-row item-center justify-end text-end w-full",
      imgSrc: "",
      text: "Attribué",
      textClassName: "whitespace-nowrap text-neonBlue",
      subTextClassName: "font-thin ext-neonBlue text-center",
      subText: "Accepté"
    }
  ];
  const regulationCardstyleFilter = (status) => {
    if (status === "ACCEPTED" && !isAssign) {
      return regulationCardstyle[0];
    }
    if (status === "ACCEPTED" && driverStatus === "PENDING") {
      return regulationCardstyle[6];
    }
    if (status === "ACCEPTED" && driverStatus === "ACCEPTED") {
      return regulationCardstyle[7];
    }
    if (status === "ENDED") {
      return regulationCardstyle[1];
    }
    if (status === "IN_PROGRESS") {
      return regulationCardstyle[4];
    }
    if (status === "PENDING" && regulatedBy === "BOURSE") {
      return regulationCardstyle[3];
    }
  };
  const {
    cardStyle,
    containerClassName,
    imgSrc,
    imgClassName,
    text,
    textClassName,
    subTextClassName,
    subText
  } = regulationCardstyleFilter(status);
  return (
    <Card className={cardStyle}>
      <div className="w-full  flex flex-col">
        <div className="w-full h-auto  flex flex-row items-start">
          <div className="w-1/12">
            {carType === "VSL" && (
              <img
                className="mt-1"
                width={25}
                src="/icons/veicule_info_modal/vslcar.svg"
              />
            )}
            {carType === "AMBULANCE" && (
              <img width={25} src="/icons/veicule_info_modal/ambulance.svg" />
            )}
            {carType === "AMBULANCE" ? (
              <p
                style={{
                  height: "fit-content",
                  fontSize: 11,
                  fontStyle: "italic",
                  color: "#4951ff",
                  fontWeight: "bold",
                  paddingTop: 2
                }}
              >
                AMB
              </p>
            ) : (
              <p
                style={{
                  height: "fit-content",
                  fontSize: 11,
                  fontStyle: "italic",
                  color: "#FFB960",
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingTop: 2
                }}
              >
                {carType}
              </p>
            )}
          </div>
          <div className="w-7/12 pl-2 flex flex-col items-start">
            <div className="flex">
              <p
                style={{
                  height: "fit-content",
                  fontSize: 10,
                  lineHeight: 1.9,
                  fontWeight: "bold",
                  color: "#DB2C73",
                  marginRight: 3,
                  fontStyle: "italic"
                }}
              >
                {patient.gender === "male" ? "Mr" : "Mme"}
              </p>
              <p
                style={{
                  height: "fit-content",
                  fontSize: 16,
                  lineHeight: 1,
                  fontWeight: "bold",
                  color: "#02196C"
                }}
              >
                {`${firstName.substring(0, 1)}.${lastName}`}
              </p>
            </div>
            <div>
              <div className="flex flex-row items-baseline">
                {transport?.returnTransportId && (
                  <img width={15} src="/icons/veicule_info_modal/ar.svg" />
                )}

                <p
                  style={{
                    marginRight: 4,
                    fontSize: 12,
                    color: "#02196C60",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    height: "fit-content",
                    lineHeight: 1
                  }}
                >
                  Transport <span style={{ color: "#DB2C73" }}> Aller</span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-4/12  h-100">
            <div className="flex justify-end flex-col whitespace-nowrap">
              <RideBadge
                containerClassName={containerClassName}
                imgWidth="8px"
                imgSrc={imgSrc}
                imgClassName={imgClassName}
                text={text}
                textClassName={textClassName}
              />
              <p
                style={{ fontSize: "11px", lineHeight: 1 }}
                className={subTextClassName}
              >
                {subText}
                {/* {status === "ACCEPTED"
                  ? "Accepté à "
                  : "En Attente "}
                {`${moment().format("LT")}`} */}
              </p>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <div className="w-8">
              <p
                style={{ fontSize: 11, lineHeight: 1.5 }}
                className="text-skyBlue2 font-bold text-sm"
              >
                {moment(startDate).format("LT")}
              </p>
            </div>
            <div className="w-8">
              <p
                style={{ fontSize: 11, lineHeight: 1.5 }}
                className="text-pink font-bold text-sm"
              >
                {moment(endDate).format("LT")}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingInline: 4
            }}
          >
            <img width={12} src="/icons/transport_regulation/trip.svg" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center"
            }}
          >
            <div className="flex">
              <p
                style={{ fontSize: 11, lineHeight: 1.5 }}
                className="text-skyBlue90"
              >
                {`${postalCodeFrom} - ${cityFrom}`}
              </p>
            </div>
            <div className="flex">
              <p
                style={{ fontSize: 11, lineHeight: 1.5 }}
                className="text-pink90"
              >
                {`${postalCodeTo} - ${cityTo}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default TransportRegulationCard;
