"use client";

import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../shadcn/ui/card";
import { Button } from "../shadcn/ui/button";
import { Input } from "../shadcn/ui/input";
import { Label } from "../shadcn/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn/ui/select";
import {
  CarIcon,
  AmbulanceIcon,
  EyeIcon,
  EyeOffIcon,
  Trash2Icon,
} from "lucide-react";
import { Badge } from "@components/shadcn/ui/badge";

function VehiculeForm({ onClose }) {
  const [typeSelected, setTypeSelected] = useState("VSL");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <Card className="w-[400px] bg-white shadow-lg rounded-2xl overflow-hidden">
      <CardHeader
        style={{
          padding: 16,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <Badge
            style={{
              borderRadius: 9999,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 8,
            }}
            className="bg-borderV2 text-nightBlue"
          >
            <span style={{ fontWeight: "600", fontSize: 14 }}>34</span>
          </Badge>
          <CardTitle style={{ fontSize: 24, fontWeight: "700" }}>
            Véhicule
          </CardTitle>
        </div>
        <div style={{ display: "flex", gap: 8 }}>
          <Button
            //TODO: resize icons
            style={{
              borderRadius: 16,
              backgroundColor: typeSelected === "VSL" ? "#F7F9FC" : "#F6F7FF",
              padding: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: 50,
              height: 50,
              border:
                typeSelected === "VSL"
                  ? "2px solid #02196C"
                  : "1px solid #DEE0F3",
            }}
            onClick={() => setTypeSelected("VSL")}
          >
            <CarIcon
              style={{
                color: "#2563EB",
              }}
            />
            <span className="text-[10px] font-bold text-yellow-500 italic">
              VSL
            </span>
          </Button>
          <Button
            style={{
              borderRadius: 16,
              backgroundColor: typeSelected === "AMB" ? "#F7F9FC" : "#F6F7FF",
              padding: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: 50,
              height: 50,
              border:
                typeSelected === "AMB"
                  ? "2px solid #02196C"
                  : "1px solid #DEE0F3",
            }}
            onClick={() => setTypeSelected("AMB")}
          >
            <AmbulanceIcon
              style={{
                color: "#2563EB",
              }}
            />
            <span className="text-[10px] font-bold text-blue-600 italic">
              AMB
            </span>
          </Button>
        </div>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <Label htmlFor="immatriculation" className="text-formGrey">
            Plaque d&apos;immatriculation
          </Label>
          <Input id="immatriculation" placeholder="Immatriculation" />
        </div>
        <div>
          <Label htmlFor="telephone" className="text-formGrey">
            Numéro de téléphone
          </Label>
          <Input id="telephone" placeholder="Num Tél" />
        </div>
        <div>
          <Label htmlFor="email" className="text-formGrey">
            Email
          </Label>
          <Input id="email" type="email" placeholder="Email" />
        </div>
        <div className="flex flex-col gap-2 ">
          <Label htmlFor="password" className="text-formGrey ">
            Mot de passe
          </Label>
          <div className="relative">
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="********"
            />
            <Button
              variant="ghost"
              size="sm"
              className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <EyeOffIcon className="h-4 w-4" />
              ) : (
                <EyeIcon className="h-4 w-4" />
              )}
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <Label htmlFor="equipier1" className="text-formGrey">
              Equipier 1
            </Label>
            <Select>
              <SelectTrigger id="equipier1">
                <SelectValue placeholder="ID - Nom.Prénom" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="equipier1">ID - Nom.Prénom</SelectItem>
                {/* Add more options as needed */}
              </SelectContent>
            </Select>
          </div>
          <div className="col-span-1">
            <Label htmlFor="equipier2" className="text-formGrey">
              Equipier 2 - DEA
            </Label>
            <Select>
              <SelectTrigger id="equipier2">
                <SelectValue placeholder="ID - Nom.Prénom" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="equipier2">ID - Nom.Prénom</SelectItem>
                {/* Add more options as needed */}
              </SelectContent>
            </Select>
          </div>
        </div>
      </CardContent>
      <CardFooter
        className=" p-4 flex justify-between"
        // TODO: add checkIncon and function delete
      >
        <div className="flex gap-4">
          <Button variant="outline" onClick={onClose}>
            Fermer
          </Button>
          <Button className="bg-blue-600 text-white">Valider</Button>
        </div>
        <Button variant="ghost" size="icon" className="bg-red-100">
          <Trash2Icon className="h-6 w-6 text-red-500" />
        </Button>
      </CardFooter>
    </Card>
  );
}

export default VehiculeForm;
