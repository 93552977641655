import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Palette from "../../../../styles/Palette";
import Input from "../../../../components/Input";
import DateTimePicker from "react-datetime-picker";
import "../../../../styles/DateTimePicker.css";
import InputPlacesAuto from "../../../../components/InputPlacesAuto";

export default forwardRef(
  ({ edit, patient = {}, setPatient = () => {}, containerStyle }, ref) => {
    const [gender, setGender] = useState(patient?.gender || 0);
    const [firstName, setFirstName] = useState(patient?.firstName || "");
    const [lastName, setLastName] = useState(patient?.lastName || "");
    const [birthDate, setBirthDate] = useState(patient?.birthDate);
    const [socialSecurityNumber, setSocialSecurityNumber] = useState(
      patient?.socialSecurityNumber || ""
    );
    const [phoneNumber, setPhoneNumber] = useState(
      patient?.phoneNumber || null
    );
    const [address, setAddress] = useState(patient?.address?.address || null);
    const [postalCode, setPostalCode] = useState(
      patient?.address?.postalCode || null
    );
    const [city, setCity] = useState(patient?.address?.city || null);
    const [complement, setComplement] = useState(
      patient?.address?.complement || null
    );
    const [moreNotes, setMoreNotes] = useState(patient?.moreNotes || null);

    const handleAutocomplete = ({ streetNumber, route, city, postalCode }) => {
      setAddress(`${streetNumber || ""} ${route || ""}`);
      setCity(city || "");
      setPostalCode(postalCode || "");
    };

    const deps = [
      gender,
      firstName,
      lastName,
      birthDate,
      socialSecurityNumber,
      address,
      postalCode,
      city,
      phoneNumber,
      complement,
      moreNotes,
    ];

    const validateInputs = () => {
      if (!patient.gender) throw new Error("Le sexe du patient est requis");
      if (!patient.firstName)
        throw new Error("Le prénom du patient est requis");
      if (!patient.lastName) throw new Error("Le nom du patient est requis");
      if (!patient.birthDate)
        throw new Error("La date de naissance du patient est requise");
      if (!patient.socialSecurityNumber)
        throw new Error("Le numéro sécurité sociale du patient est requis");
    };

    useImperativeHandle(
      ref,
      () => ({
        validateInputs,
      }),
      deps
    );

    useEffect(() => {
      // if (edit === true && patient?.id !== undefined) return;
      setPatient({
        id: patient?.id || undefined,
        gender,
        firstName,
        lastName,
        birthDate,
        socialSecurityNumber,
        phoneNumber,
        address: {
          address,
          postalCode,
          city,
          complement,
          moreNotes,
        },
      });
    }, deps);

    return (
      <div className={"container flex"} style={containerStyle}>
        <div
          style={{
            fontSize: 20,
            fontWeight: 600,
            color: Palette.red,
            marginBottom: 10,
          }}
        >
          Patient
        </div>
        <div className={"containerRow"}>
          <div className={"container flex"}>
            <div className={"containerRow"} style={{ marginBottom: 10 }}>
              <Input
                height={40}
                required
                dropdown
                items={[
                  { name: "M", value: "male" },
                  { name: "Mme", value: "female" },
                ]}
                value={gender}
                onChange={setGender}
                style={{ marginRight: 10 }}
              />
              <Input
                height={40}
                required
                value={firstName}
                onChange={setFirstName}
                placeholder={"Prénom"}
                style={{ marginRight: 10 }}
                className={"flex"}
              />
              <Input
                height={40}
                required
                value={lastName}
                onChange={setLastName}
                placeholder={"Nom"}
                className={"flex"}
              />
            </div>
            <DateTimePicker
              required
              disableClock
              disableCalendar
              onChange={setBirthDate}
              value={birthDate}
              format="d/M/yyyy"
              placeholder="Date de naissance"
            />
            <Input
              value={socialSecurityNumber}
              onChange={setSocialSecurityNumber}
              placeholder={"Numéro de sécurité sociale"}
              style={{ marginBottom: 10 }}
            />
          </div>
          <div
            className={"container flex"}
            style={{
              marginLeft: 20,
            }}
          >
            <InputPlacesAuto
              placeholder={"Rue"}
              style={{ marginBottom: 10 }}
              value={address}
              onChange={setAddress}
              onSelect={handleAutocomplete}
              iconRight={"/icons/search.png"}
            />
            <div className={"containerRow"} style={{ marginBottom: 10 }}>
              <Input
                required
                value={postalCode}
                onChange={setPostalCode}
                placeholder={"Code postal"}
              />
              <Input
                required
                value={city}
                onChange={setCity}
                placeholder={"Ville"}
                style={{ marginLeft: 10 }}
              />
            </div>
            <Input
              value={phoneNumber}
              onChange={setPhoneNumber}
              placeholder={"Numéro de téléphone"}
            />
          </div>
          <div
            className={"container flex"}
            style={{
              marginLeft: 20,
            }}
          >
            <Input
              value={complement}
              onChange={setComplement}
              placeholder={"Complément"}
              style={{ marginBottom: 10 }}
            />
            <Input
              multiline
              placeholder={"Autres informations..."}
              value={moreNotes}
              onChange={setMoreNotes}
            />
          </div>
        </div>
      </div>
    );
  }
);
