import { config } from "../config";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";

// UTILS //
if (!firebase.apps.filter(({ name_ }) => name_ === "[DEFAULT]").length) {
  firebase.initializeApp(config.firebase);
}
const cleanObject = (obj) => {
  const cleanedObj = {};
  for (const key in obj) {
    if (
      obj[key] !== undefined &&
      obj[key] !== null &&
      obj[key] !== firebase.firestore.FieldValue.delete() &&
      typeof obj[key] !== "function"
    ) {
      cleanedObj[key] = obj[key];
    }
  }
  return cleanedObj;
};

// END UTILS //

export const Transports = firebase.firestore().collection("transports");
export const Patients = firebase.firestore().collection("patients");
export const Users = firebase.firestore().collection("users");
export const Groups = firebase.firestore().collection("groups");
export const Notifications = firebase.firestore().collection("notifications");
export const Employees = firebase.firestore().collection("employees");
export const Vehicles = firebase.firestore().collection("vehicles");

export const getUsersByRole = async (role) => {
  const snapshot = await Users.where("role", "==", role).get();
  return snapshot.docs.map((doc) => doc.id);
};

// Notifications //

// REMINDER TYPE NOTIFICATION :
// "NEW_TRANSPORT", "TRANSPORT_ACCEPTED",
// "TRANSPORT_ATTRIBUTED_PENDING", "TRANSPORT_ATTRIBUTED_ACCEPTED",
// "TRANSPORT_IN_PROGRESS_TO_PATIENT", "TRANSPORT_IN_PROGRESS_TO_PLACE",
// "TRANSPORT_ENDED",
// "TRANSPORT_REFUSED", "TRANSPORT_CANCELED",
// "TRANSPORT_BOURSE",

export const createNotification = async (transport, type, sendTo) => {
  const { uid } = firebase.auth().currentUser;

  const regulatorUsers = await getUsersByRole("REGULATOR");
  const hospitalUsers = await getUsersByRole("HOSPITAL");
  const driverUsers = await getUsersByRole("DRIVER");

  switch (sendTo) {
    case "ALL":
      sendTo = [...regulatorUsers, ...hospitalUsers, ...driverUsers];
      break;
    case "REGULATOR & HOSPITAL":
      sendTo = [...regulatorUsers, ...hospitalUsers];
      break;
    case "REGULATOR":
      sendTo = regulatorUsers;
      break;
    case "HOSPITAL":
      sendTo = hospitalUsers;
      break;
    case "DRIVER":
      sendTo = driverUsers;
      break;
    default:
      console.error("Invalid type provided");
      return;
  }

  // console.log("Step 7: Transport received by createNotification:", transport);

  // Forcer l'inclusion des valeurs de bourseType et bourseGroup
  const notificationData = {
    transportId: transport?.id,
    carType: transport?.carType,
    cancelBy: transport?.cancelBy
      ? transport?.cancelBy === "REGULATOR"
        ? "transporteur"
        : "partenaire de santé"
      : "",
    driver: transport?.driver ? transport?.driver : "",
    bourseType: transport?.bourseType || "",
    bourseGroup: transport?.bourseGroup || "",
    patient: {
      gender: transport?.patient?.gender,
      firstName: transport?.patient?.firstName,
      lastName: transport?.patient?.lastName,
    },
    startDate: transport?.startDate,
    from: {
      postalCode: transport?.from?.postalCode,
      city: transport?.from?.city,
    },
    to: { postalCode: transport?.to?.postalCode, city: transport?.to?.city },
  };

  // Vérification explicite des valeurs de bourseType et bourseGroup
  console.log(
    "Step 8: Before forcing values, notificationData:",
    notificationData
  );
  notificationData.bourseType = transport?.bourseType || "NOT SET";
  notificationData.bourseGroup = transport?.bourseGroup || "NOT SET";
  console.log(
    "Step 9: After forcing values, notificationData:",
    notificationData
  );

  const cleanedNotificationData = cleanObject(notificationData);

  console.log("Step 10: Cleaned notification data:", cleanedNotificationData);

  const notification = {
    type: type,
    data: cleanedNotificationData,
    sendTo: sendTo,
    readBy: [],
    createdAt: firebase.firestore.Timestamp.now(),
  };

  try {
    await Notifications.add(notification);
    console.log("Step 11: Notification added successfully");
  } catch (error) {
    console.error("Error adding notification:", error);
  }
};
// END Notifications //

// FORMAT FUNCTIONS //

export const { arrayUnion, arrayRemove, increment } =
  firebase.firestore.FieldValue;

export const formatDoc = (doc) => {
  return { ...doc.data(), id: doc.id };
};

export const formatTransportData = async (doc) => {
  const toReturn = {
    ...doc,
    id: doc.id,
    startDate: doc.startDate?.toDate(),
    endDate: doc.endDate?.toDate(),
  };
  if (toReturn.demDate) {
    toReturn.demDate = toReturn.demDate.toDate();
  }
  if (toReturn.patient?.birthDate) {
    toReturn.patient.birthDate = toReturn.patient.birthDate.toDate();
  }
  // if (toReturn.returnTransportId) {
  //   const returnTransport = await Transports.doc(
  //     toReturn.returnTransportId
  //   ).get();
  //   console.log("formatTransportData toReturn : ", toReturn.returnTransportId);
  //   toReturn.returnTransport = await formatTransportDoc({...returnTransport.data(), id: returnTransport.id});
  // }

  return toReturn;
};

export const formatTransportDoc = async (docs) => {
  // console.log("formatTransportDoc docs : ", docs);
  if (Array.isArray(docs)) {
    return await Promise.all(docs.map((doc) => formatTransportData(doc)));
  } else {
    return await formatTransportData(docs);
  }
};

export const formatPatientData = async (doc) => {
  const isAlgolia = !!doc.objectID;
  if (isAlgolia) {
    doc.id = doc.objectID;
    delete doc._highlightResult;
    delete doc.objectID;
    return {
      ...doc,
      birthDate: doc.birthDate.toDate(),
    };
  } else {
    return {
      ...doc,
      id: doc.id,
      birthDate: doc.birthDate.toDate(),
    };
  }
};

export const formatPatientDoc = async (docs) => {
  if (Array.isArray(docs)) {
    return await Promise.all(docs.map((doc) => formatPatientData(doc)));
  } else {
    return formatPatientData(docs);
  }
};

// END FORMAT FUNCTIONS //

// TRANSPORTS //

export async function getTransport(id) {
  try {
    const snap = await Transports.doc(id).get();
    return formatTransportDoc(snap);
  } catch (e) {
    console.log(e);
  }
}

export async function updateTransport(transportId, data) {
  try {
    console.log(
      `Mise à jour du transport ${transportId} avec les données :`,
      data
    );
    await Transports.doc(transportId).update(data);
    console.log(`Transport ${transportId} mis à jour avec succès`);
  } catch (e) {
    console.log(`Erreur lors de la mise à jour du transport ${transportId}`);
    console.log(e);
    throw e; // Rejeter l'erreur pour qu'elle soit capturée par l'appelant
  }
}

export async function addTransports(transports = [], role) {
  const { uid } = firebase.auth().currentUser;
  const batch = firebase.firestore().batch();
  transports.forEach((transport) => {
    batch.set(Transports.doc(transport.id), {
      ...transport,
      createdBy: uid,
      regulatedBy: role === "REGULATOR" ? uid : "BOURSE",
      bourseType: role === "REGULATOR" ? null : "PUBLIC",
      status: role === "REGULATOR" ? "ACCEPTED" : "PENDING",
      isAssign: role === "REGULATOR" ? false : null,
    });
    // createNotification(transport, "NEW_TRANSPORT", "REGULATOR & HOSPITAL");
  });
  return await batch.commit();
}

export async function acceptTransport(transportId, toUpdate, transport) {
  const { uid } = firebase.auth().currentUser;

  try {
    await updateTransport(transportId, {
      status: "ACCEPTED",
      isAssign: false,
      regulatedBy: uid,
      bourseType: firebase.firestore.FieldValue.delete(),
      bourseGroup: firebase.firestore.FieldValue.delete(),
      ...toUpdate,
    });
    // await createNotification(
    //   transport,
    //   "TRANSPORT_ACCEPTED",
    //   "REGULATOR & HOSPITAL"
    // );
    // console.log(`Notification créée pour le transport ${transportId}`);
  } catch (e) {
    console.log("ERROR ON FUNCTION acceptTransport");
    console.log(e);
  }
}

export async function assignTransport(transportId, toUpdate, transport, user) {
  const { uid } = firebase.auth().currentUser;
  console.log(
    "assigning transport OK values transportID & toUpdate :",
    transportId,
    toUpdate
  );

  // Créer les données de mise à jour
  const updateData = {
    status: "ACCEPTED",
    isAssign: true,
    regulatedBy: uid,
    driverStatus: "PENDING",
    bourseType: firebase.firestore.FieldValue.delete(),
    bourseGroup: firebase.firestore.FieldValue.delete(),
    refusedBy: firebase.firestore.FieldValue.delete(),
    driver: user,
    ...toUpdate,
  };

  // Créer les données de notification
  const notificationData = {
    ...transport,
    status: "ACCEPTED",
    isAssign: true,
    regulatedBy: uid,
    bourseType: "",
    bourseGroup: "",
    refusedBy: [],
    driver: user,
  };

  // Nettoyer les données de notification
  const cleanedNotificationData = cleanObject(notificationData);

  try {
    // Mettre à jour le transport
    await updateTransport(transportId, updateData);

    // Créer la notification avec les données nettoyées
    // await createNotification(
    //   cleanedNotificationData,
    //   "TRANSPORT_ATTRIBUTED_PENDING",
    //   "REGULATOR"
    // );
    // console.log(`Notification créée pour le transport ${transportId}`);
  } catch (e) {
    console.log("ERROR ON FUNCTION assignTransport");
    console.log(e);
  }
}
export async function refuseTransport(transportId, toUpdate, transport) {
  const { uid } = firebase.auth().currentUser;
  console.log("refusing transport OK values transportID & toUpdate :", {
    transportId,
    toUpdate,
    transport,
  });
  try {
    // await createNotification(transport, "TRANSPORT_REFUSED", "REGULATOR");
    await updateTransport(transportId, {
      status: "REFUSED",
      regulatedBy: uid,
      bourseType: firebase.firestore.FieldValue.delete(),
      bourseGroup: firebase.firestore.FieldValue.delete(),
      refusedBy: firebase.firestore.FieldValue.arrayUnion(uid),
      ...toUpdate,
    });
    // console.log(`Notification créée pour le transport ${transportId}`);
  } catch (e) {
    console.log("ERROR ON FUNCTION refuseTransport");
    console.log(e);
  }
}

export async function cancelTransport(transportId, userRole, transport) {
  console.log("transport", transport);

  // Créer les données de mise à jour
  const updateData = {
    status: "CANCELED",
    cancelBy: userRole,
  };

  // Créer les données de notification
  const notificationData = {
    ...transport,
    status: "CANCELED",
    cancelBy: userRole,
  };

  // Nettoyer les données de notification
  const cleanedNotificationData = cleanObject(notificationData);

  try {
    // Mettre à jour le transport
    await updateTransport(transportId, updateData);

    // Créer la notification avec les données nettoyées
    // await createNotification(
    //   cleanedNotificationData,
    //   "TRANSPORT_CANCELED",
    //   "REGULATOR & HOSPITAL"
    // );
    // console.log(`Notification créée pour le transport ${transportId}`);
  } catch (e) {
    console.log("ERROR ON FUNCTION cancelTransport");
    console.log(e);
  }
}
// Fonction pour assigner un transport à la bourse
export const assignBourseTransport = async (
  transport,
  selected,
  selectedGroup
) => {
  const updateData = {
    regulatedBy: "BOURSE",
    driver: null,
    driverStatus: null,
    assignedTo: null,
    status: "PENDING",
    bourseType: selected === "PUBLIC" ? "PUBLIC" : "PRIVATE",
    bourseGroup: selected !== "PUBLIC" ? selectedGroup.id : null,
  };

  try {
    if (transport.returnTransportId) {
      const updateReturnData = { ...updateData };

      await updateTransport(transport.id, updateData);
      // await createNotification(
      //   {
      //     ...transport,
      //     bourseType: updateData.bourseType,
      //     bourseGroup: updateData.bourseGroup,
      //   },
      //   "TRANSPORT_BOURSE",
      //   "REGULATOR & HOSPITAL"
      // );

      await updateTransport(transport.returnTransportId, updateReturnData);
      // await createNotification(
      //   {
      //     ...transport,
      //     id: transport.returnTransportId,
      //     bourseType: updateData.bourseType,
      //     bourseGroup: updateData.bourseGroup,
      //   },
      //   "TRANSPORT_BOURSE",
      //   "REGULATOR & HOSPITAL"
      // );

      // console.log(
      //   `Notification créée pour le transport ${transport.id} et ${transport.returnTransportId}`
      // );
    } else {
      await updateTransport(transport.id, updateData);
      // await createNotification(
      //   {
      //     ...transport,
      //     bourseType: updateData.bourseType,
      //     bourseGroup: updateData.bourseGroup,
      //   },
      //   "TRANSPORT_BOURSE",
      //   "REGULATOR & HOSPITAL"
      // );

      // console.log(`Notification créée pour le transport ${transport.id}`);
    }
  } catch (error) {
    console.error("Erreur lors de l'attribution à la bourse:", error);
    throw error;
  }
};
// END TRANSPORTS //

// PATIENTS //

export async function getPatient(id) {
  try {
    const snap = await Patients.doc(id).get();
    return formatPatientDoc(snap);
  } catch (e) {
    console.log(e);
  }
}

export async function addPatient(patient) {
  delete patient.id;
  const { uid } = firebase.auth().currentUser;
  console.log(patient);
  return await Patients.add({ ...patient, createdBy: uid });
}

// END PATIENTS //

// USERS //

export async function getUser(id) {
  try {
    const snap = await Users.doc(id).get();
    return formatDoc(snap);
  } catch (e) {
    console.log(e);
  }
}

export async function getRegulatorFor(id) {
  try {
    const regulatorSnap = await Users.doc(id).get();
    if (regulatorSnap.exists) {
      return formatDoc(regulatorSnap);
    } else return null;
  } catch (e) {
    console.log(e);
  }
}

// END USERS //

export default firebase;
