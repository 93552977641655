import { useZustandStore } from "@helpers/store";
import React from "react";
import TransportTrackingCard from "../organisms/TransportTrackingCard";
import TransportDriverTrackingCard from "./TransportDriverTrackingCard";
export default function TransportTrackingList({
  transports = [],
  drivers = [],
  containerStyle
}) {
  const setAvailableDriver = useZustandStore(
    (state) => state.setAvailableDriver
  );
  const setTrackedVehiculeTransport = useZustandStore(
    (state) => state.setTrackedVehiculeTransport
  );
  const setIsOpen = useZustandStore((state) => state.setIsOpen);
  const allNextTransports = useZustandStore((state) => state.allNextTransports);

  const filteredDrivers = drivers.filter((driver) => {
    // Check if there is any transport with this driver assigned
    return !transports.some(
      (transport) =>
        transport.assignedTo === driver.uid &&
        (transport.driverStatus === "RETRIEVING_PATIENT" ||
          transport.driverStatus === "DEPOSITING_PATIENT")
    );
  });
  return (
    <div
      className={"container relative flex !p-0"}
      style={{
        ...containerStyle,
        paddingBottom: 60
      }}
    >
      {transports.length > 0 && (
        <>
          {transports.map((d, i, a) => {
            return (
              <div
                onClick={() => {
                  setTrackedVehiculeTransport(d);
                  setIsOpen(true);
                }}
                key={d.id}
                style={{ position: "relative" }}
              >
                <div>
                  {d.driver &&
                    (d.driverStatus === "RETRIEVING_PATIENT" ||
                      d.driverStatus === "DEPOSITING_PATIENT") && (
                      <TransportTrackingCard transport={d} />
                    )}
                </div>
              </div>
            );
          })}
        </>
      )}
      {
        <>
          {filteredDrivers &&
            filteredDrivers.map((driver, i) => {
              const nextDriverTransports = allNextTransports.filter(
                (item) => item?.driver?.id === driver?.id
              );

              return (
                <div
                  onClick={(e) => setAvailableDriver(driver)}
                  key={driver?.id}
                  style={{ position: "relative" }}
                >
                  <TransportDriverTrackingCard
                    user={driver}
                    nextDriverTransports={nextDriverTransports}
                  />
                </div>
              );
            })}
        </>
      }
    </div>
  );
}
