import Palette from "../styles/Palette";

export default {
  BOURSE: {
    value: "BOURSE",
    name: "Bourse",
    shortName: "Bourse",
    backgroundColor: Palette.yellowTranslucent,
    color: Palette.yellow,
  },
  PENDING: {
    value: "PENDING",
    name: "En attente",
    shortName: "En attente",
    backgroundColor: Palette.halfBlue,
    color: Palette.blue,
  },
  ENDED: {
    value: "ENDED",
    name: "Terminée",
    shortName: "Terminée",
    backgroundColor: Palette.halfGreen,
    color: Palette.green,
  },
  IN_PROGRESS: {
    value: "IN_PROGRESS",
    name: "En cours",
    shortName: "En cours",
    backgroundColor: Palette.salmonTranslucent,
    color: Palette.salmon,
  },
  CANCELED: {
    value: "CANCELED",
    name: "Annulée",
    shortName: "Annulée",
    backgroundColor: Palette.halfRed,
    color: Palette.red,
  },
};
