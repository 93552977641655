import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import moment from "moment";
import Input from "../../../../components/Input";
import DriverType from "../../../../components/DriverType";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Checkbox from "../../../../components/Checkbox";
import Colors from "../../../../styles/Colors";
import { REASONS } from "../../../../data/transports";
import Palette from "../../../../styles/Palette";

registerLocale("fr", fr);

const DateInput = forwardRef(({ onClick: onFocus, ...props }, ref) => (
  <Input {...{ onFocus, ref }} {...props} />
));

export default forwardRef(({ transport, setTransport, transportId }, ref) => {
  const [carType, setCarType] = useState(transport?.carType || "VSL");
  const [reason, setReason] = useState(transport?.reason || null);

  // Transport
  const [startDate, setStartDate] = useState(
    transport?.startDate
      ? moment(transport?.startDate).toDate()
      : moment().minute(0).second(0).millisecond(0).toDate()
  );
  const [endDate, setEndDate] = useState(
    transport?.endDate
      ? moment(transport?.endDate).toDate()
      : moment().minute(30).second(0).millisecond(0).toDate()
  );

  useEffect(() => {
    if (startDate && endDate) {
      const date = moment(startDate).format("DD/MM/YYYY");
      const hour = moment(endDate).format("HH:mm");
      setEndDate(moment(`${date} ${hour}`, "DD/MM/YYYY HH:mm").toDate());
    }
  }, [startDate]);
  // !Transport

  // Return transport
  // const [isReturn, setHasReturn] = useState(
  //   transportId !== "new" ? -1 : !!transport?.returnDate
  // );

  const [isReturn, setHasReturn] = useState(transport?.id ? -1 : false);

  const [returnDate, setReturnDate] = useState(
    transport?.returnDate || moment(startDate).add(1, "d").toDate()
  );

  const [returnStartDate, setReturnStartDate] = useState(
    moment().add(1, "d").toDate()
  );
  const [returnEndDate, setReturnEndDate] = useState(
    moment().add(1, "d").toDate()
  );

  useEffect(() => {
    const date = moment(returnDate).format("DD/MM/YYYY");
    const returnStartHour = moment().hour(23).minute(45).format("HH:mm");
    const returnEndHour = moment().hour(23).minute(55).format("HH:mm");
    setReturnStartDate(
      moment(`${date} ${returnStartHour}`, "DD/MM/YYYY HH:mm").toDate()
    );
    setReturnEndDate(
      moment(`${date} ${returnEndHour}`, "DD/MM/YYYY HH:mm").toDate()
    );
  }, [returnDate]);
  // !Return transport

  // SAMU
  const [isSamu, setIsSamu] = useState(!!transport?.samuNumber);
  const [samuNumber, setSamuNumber] = useState(transport?.samuNumber || null);
  // !SAMU

  const roundedStartDate = moment(startDate).startOf("day");
  const roundedReturnDate = moment(returnDate).startOf("day");
  const validateInputs = () => {
    if (!reason) throw new Error("La raison du transport est requise");
    if (isSamu && !samuNumber) throw new Error("Le numéro du samu est requis");
    const ISSAME = roundedStartDate.isSame(roundedReturnDate);
    if (
      roundedStartDate.isSame(roundedReturnDate) === false &&
      isReturn === true
    ) {
      if (moment(startDate).isAfter(returnDate)) {
        throw new Error("L'aller doit être antérieur au retour");
      }
    }
    // PEC can t be after the RDV
    if (moment(startDate).isAfter(endDate)) {
      throw new Error(
        "L'heure de prise en charge doit être antérieure à l'heure de rendez-vous"
      );
    }

    if (roundedStartDate.isSame(roundedReturnDate) === false) {
      if (moment(startDate).isAfter(returnDate) && isReturn === true) {
        throw new Error("L'aller doit être antérieur au retour");
      }
    }
  };

  const deps = [
    carType,
    startDate,
    endDate,
    reason,
    isReturn,
    isSamu,
    samuNumber,
    returnStartDate,
    returnEndDate,
  ];

  useImperativeHandle(
    ref,
    () => ({
      validateInputs,
    }),
    deps
  );

  useEffect(() => {
    const toAdd = { carType, startDate, endDate, reason };
    if (isReturn === true) {
      toAdd.returnDate = returnStartDate;
      toAdd.returnEndDate = returnEndDate;
    }
    if (isSamu) {
      toAdd.samuNumber = samuNumber;
    }
    setTransport(toAdd);
  }, deps);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          fontSize: 24,
          fontWeight: 500,
          textAlign: "center",
          marginBottom: 50,
        }}
      >
        Prise en charge du patient
      </div>
      <div className={"containerRow"}>
        <div className={"container"} style={{ flex: 1.25 }}>
          <div style={{ fontSize: 14, marginBottom: 10 }}>
            {"Type d'intervention"}
          </div>

          <DriverType
            onSelect={setCarType}
            selected={carType}
            containerStyle={{ marginBottom: 30, height: 88 }}
          />

          <div style={{ fontSize: 14, marginBottom: 10 }}>
            {"Raison d’intervention"}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {REASONS.map((c, i) => {
              const isSelected = reason === c.value;
              return (
                <div
                  onClick={() => setReason(c.value)}
                  key={i}
                  className={"containerRow"}
                  style={{
                    height: 58,
                    width: 198,
                    backgroundColor: isSelected
                      ? Palette.blueTranslucent
                      : Palette.white,
                    border: `1px solid ${
                      isSelected ? Palette.blue : Colors.input.border
                    }`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 10,
                    borderRadius: 5,
                    cursor: "pointer",
                  }}
                >
                  {c.icon && (
                    <img
                      src={
                        isSelected
                          ? c.icon.replace(".png", "_active.png")
                          : c.icon
                      }
                      style={{
                        width: 27,
                        height: 27,
                        marginRight: 10,
                      }}
                    />
                  )}
                  <div
                    style={{
                      textAlign: "center",
                      color: isSelected ? Palette.blue : "#02196C",
                      fontWeight: 500,
                    }}
                  >
                    {c.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{
            height: "100%",
            width: 1,
            backgroundColor: Colors.input.border,
            marginLeft: 30,
            marginRight: 30,
          }}
        />
        <div className={"container flex"}>
          <div style={{ fontSize: 14, marginBottom: 10 }}>
            {"Date de la prise en charge"}
          </div>
          <DatePicker
            required
            locale="fr"
            dateFormat="d/MM/yyyy"
            showTimeSelect={false}
            onChange={setStartDate}
            selected={startDate}
            placeholder="Date de l'aller"
            customInput={
              <DateInput
                iconRight={"/icons/date.png"}
                style={{ marginBottom: 10 }}
              />
            }
          />
          <div className={"containerRow"} style={{ marginBottom: 10 }}>
            <div style={{ marginRight: 10 }} className={"flex"}>
              <DatePicker
                required
                locale="fr"
                dateFormat="HH:mm"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                onChange={setStartDate}
                selected={startDate}
                placeholder="Heure"
                customInput={
                  <DateInput
                    onLeft={() => (
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          marginRight: 10,
                        }}
                      >
                        PEC
                      </div>
                    )}
                  />
                }
              />
            </div>
            <div style={{ display: "flex", flex: 1 }}>
              <DatePicker
                required
                locale="fr"
                dateFormat="HH:mm"
                showTimeSelect
                showTimeSelectOnly
                onChange={setEndDate}
                timeIntervals={15}
                selected={endDate}
                placeholder="Heure"
                customInput={
                  <DateInput
                    onLeft={() => (
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          marginRight: 10,
                        }}
                      >
                        RDV
                      </div>
                    )}
                  />
                }
              />
            </div>
          </div>
          <div className={"containerRow"}>
            {isReturn !== -1 && (
              <div className={"flex"} style={{ marginRight: 10 }}>
                <Checkbox
                  value={isReturn}
                  onChange={setHasReturn}
                  name={"Transport Retour"}
                />
                {isReturn && (
                  <DatePicker
                    required
                    locale="fr"
                    dateFormat="d/MM/yyyy"
                    showTimeSelect={false}
                    onChange={setReturnDate}
                    selected={returnDate}
                    placeholder="Date du retour"
                    customInput={
                      <DateInput
                        iconRight={"/icons/date.png"}
                        style={{ marginTop: 10 }}
                      />
                    }
                  />
                )}
              </div>
            )}
            <div className={"flex"}>
              <Checkbox value={isSamu} onChange={setIsSamu} name={"SAMU"} />
              {isSamu && (
                <Input
                  required
                  value={samuNumber}
                  onChange={setSamuNumber}
                  placeholder={"SAMU"}
                  style={{ marginTop: 10 }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
