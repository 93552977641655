import Palette from "../styles/Palette";
import Colors from "../styles/Colors";
import React from "react";

const addressTypes = [
  { name: "Domicile", value: "HOME", icon: "/icons/addressTypes/home" },
  {
    name: "ETB Santé",
    value: "ESTABLISHMENT",
    icon: "/icons/addressTypes/establishment",
  },
  { name: "Médecin", value: "DOCTOR", icon: "/icons/addressTypes/doctor" },
];

export default ({
  onSelect,
  selected = "HOME",
  containerStyle,
  style,
  showLabels,
}) => {
  return (
    <div style={{ display: "flex", ...containerStyle }}>
      {addressTypes.map(({ name, icon, value }, i) => {
        const isSelected = value === selected;
        const isLast = i === addressTypes.length - 1;
        return (
          <div
            className={"flex"}
            onClick={() => onSelect(value)}
            key={i}
            style={{
              cursor: "pointer",
              height: 40,
              minWidth: 43,
              borderRadius: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: isLast ? 0 : 5,
              border: `1px solid ${
                isSelected ? Palette.blue : Colors.input.border
              }`,
              backgroundColor: isSelected
                ? Palette.blueTranslucent
                : Palette.white,
              ...style,
            }}
          >
            <img
              src={`${icon}${isSelected ? "_active" : ""}.png`}
              style={{ width: 20, height: 20 }}
            />
            {showLabels && (
              <div
                style={{
                  marginLeft: 5,
                  fontSize: 13,
                  fontWeight: 600,
                  color: isSelected ? Palette.blue : undefined,
                }}
              >
                {name}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
