import React, { useState } from "react";
import Global from "../../styles/Global";
import Colors from "../../styles/Colors";
import Button from "../Button";
import Palette from "../../styles/Palette";
import { AiFillEye, AiOutlineDownload } from "react-icons/ai";

export default ({ transport, containerStyle }) => {
  const [activeTab, setActiveTab] = useState("Compléments");

  const downloadFile = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Erreur lors du téléchargement:", error);
      alert("Une erreur est survenue lors du téléchargement du fichier.");
    }
  };

  const renderComplementsTab = () => (
    <div
      style={{ display: "flex", flexDirection: "column", gap: 10, padding: 15 }}
    >
      <div style={{ fontSize: 14, fontWeight: 500, marginBottom: 20 }}>
        À prévoir
      </div>
      {transport?.additionalNeeds?.map((need, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
        >
          <img
            src={need.icon}
            style={{ width: 30, height: 30, marginRight: 10 }}
            alt={need.name}
          />
          <div style={{ fontSize: 13, color: Colors.text.secondary }}>
            {need.name}
          </div>
        </div>
      ))}

      <div
        style={{
          fontSize: 14,
          fontWeight: 500,
          marginBottom: 5,
          marginTop: 20,
        }}
      >
        Accompagnateur(s)
      </div>
      {transport?.companions?.map((companion, index) => (
        <div key={index} style={{ color: Colors.text.secondary, fontSize: 13 }}>
          {companion}
        </div>
      ))}

      <div
        style={{
          fontSize: 14,
          fontWeight: 500,
          marginBottom: 5,
          marginTop: 20,
        }}
      >
        Informations
      </div>
      <div style={{ color: Colors.text.secondary, fontSize: 13 }}>
        {transport?.moreNotes}
      </div>
    </div>
  );

  const renderDocumentsTab = () => (
    <div
      style={{ display: "flex", flexDirection: "column", gap: 15, padding: 15 }}
    >
      {transport?.documents?.map((doc, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 13,
              color: Colors.text.secondary,
              fontWeight: 500,
            }}
          >
            {doc.name}
          </div>
          {doc.name === "Commentaire" ? (
            <div
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: Palette.text7,
              }}
            >
              {doc.content}
            </div>
          ) : (
            <div style={{ display: "flex", gap: 10 }}>
              <Button
                text="Voir"
                onClick={() => window.open(doc.url, "_blank")}
                style={{
                  backgroundColor: Palette.lightBlue,
                  height: 20,
                  padding: "2px 10px",
                  border: `1px solid ${Palette.gray9}`,
                  color: Colors.text.secondary,
                  fontSize: 12,
                }}
                IconRight={AiFillEye}
                iconStyle={{
                  size: 18,
                  color: Colors.text.secondary,
                }}
              />
              <Button
                text=""
                onClick={() => downloadFile(doc.url, doc.name)}
                style={{
                  backgroundColor: Palette.lightBlue,
                  height: 20,
                  padding: "2px 10px",
                  border: `1px solid ${Palette.gray9}`,
                  color: Colors.text.secondary,
                  fontSize: 12,
                }}
                IconRight={AiOutlineDownload}
                iconStyle={{
                  size: 18,
                  color: Colors.text.secondary,
                }}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div style={{ ...Global.box, ...containerStyle, padding: 0 }}>
        <div style={{ display: "flex" }}>
          {["Compléments", "Documents"].map((tab) => (
            <div
              key={tab}
              onClick={() => setActiveTab(tab)}
              style={{
                flex: 1,
                textAlign: "center",
                padding: "10px 0",
                cursor: "pointer",
                borderBottom: `2px solid ${
                  activeTab === tab ? Palette.blue : "transparent"
                }`,
                color:
                  activeTab === tab
                    ? Colors.text.primary
                    : Colors.text.secondary,
                fontWeight: activeTab === tab ? 500 : 400,
              }}
            >
              {tab}
            </div>
          ))}
        </div>
        <div>
          {activeTab === "Compléments"
            ? renderComplementsTab()
            : renderDocumentsTab()}
        </div>
      </div>
    </>
  );
};
