import Palette from "../styles/Palette";
import React from "react";

export default function ItinaryLine({
  size = 7,
  horizontal,
  width = 100,
  regulator,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: horizontal ? "row" : "column",
        alignItems: "center",
        marginTop: 3,
        marginBottom: 3,
        marginRight: horizontal ? undefined : 10,
      }}
    >
      {!regulator ? (
        <div
          style={{
            height: size,
            width: size,
            backgroundColor: Palette.white,
            border: `${size / 4}px solid ${Palette.blue}`,
            borderRadius: 999,
          }}
        />
      ) : (
        <div
          className={"marker"}
          style={{ height: size, width: size, backgroundColor: Palette.blue }}
        />
      )}
      <div
        style={{
          borderBottom: `${horizontal ? size / 5 : 0}px dashed ${
            regulator ? Palette.salmon : Palette.red
          }`,
          borderRight: `${!horizontal ? size / 5 : 0}px dashed ${
            regulator ? Palette.salmon : Palette.red
          }`,
          height: horizontal ? 0 : undefined,
          width: horizontal ? width : 0,
          flex: 1,
        }}
      />
      {!regulator ? (
        <div
          style={{
            height: size,
            width: size,
            backgroundColor: Palette.white,
            border: `${size / 4}px solid ${Palette.blue}`,
            borderRadius: 999,
          }}
        />
      ) : (
        <div
          style={{
            height: size,
            width: size,
            backgroundColor: Palette.salmon,
            border: `${size / 8}px solid ${Palette.white}`,
            borderRadius: 999,
          }}
        />
      )}
    </div>
  );
}
