import React from "react";

function TransportTrackingContainer({ children }) {
  return (
    <div
      className={"container"}
      style={{
        position: "absolute",
        left: 122,
        top: 100,
        zIndex: 1,
        borderRadius: 10,
        width: "350px",
        height: "533px",
        backgroundColor: "white",
        padding: 15,
        boxSizing: "border-box",
      }}
    >
      {children}
    </div>
  );
}

export default TransportTrackingContainer;
