import React from "react";
import { containerFlexCenterCenter } from "../styles/main";

export default function IconButton({
  icon: Icon,
  onClick,
  containerStyle = {},
  iconProps = {},
}) {
  return (
    <div
      onClick={onClick}
      style={{
        ...containerFlexCenterCenter,
        borderRadius: 10,
        cursor: "pointer",
        width: 50,
        height: 50,
        boxSizing: "border-box",
        ...containerStyle,
      }}
    >
      <Icon size={24} {...iconProps} />
    </div>
  );
}
