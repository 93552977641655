import React, { createContext, useContext, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import Loading from "../components/Loading";
import {
  formatDoc,
  getRegulatorFor,
  getUser,
  Notifications,
  Users,
} from "../services/Firebase";
import useDataFromRef from "../hooks/useDataFromRef";
import moment from "moment";

export const authContext = createContext(undefined);

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {!auth.authenticating ? children : <Loading absolute />}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [assignedAttributes, setAssignedAttributes] = useState([]);
  const [groups, setGroups] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [authenticating, setAuthenticating] = useState(true);

  const initialize = () => {
    return firebase.auth().onAuthStateChanged(async (u) => {
      if (u) {
        const userData = await getUser(u.uid);
        console.log(userData);
        if (userData?.role === "HOSPITAL") {
          userData.regulator = await getRegulatorFor(userData.regulatedBy);
        }
        setUser(userData);
      } else {
        setUser(null);
      }
      if (authenticating) {
        setAuthenticating(false);
      }
    });
  };

  useEffect(() => {
    if (user) {
      const attributesSub = Users.doc(user.id)
        .collection("attributes")
        .onSnapshot((snap) => {
          setAttributes(snap.docs.map(formatDoc));
        });
      const assignedAttributesSub = Users.doc(user.id)
        .collection("assignedAttributes")
        .onSnapshot((snap) => {
          setAssignedAttributes(snap.docs.map(formatDoc));
        });
      const groupsSub = firebase
        .firestore()
        .collection("groups")
        .where("members", "array-contains", user.id)
        .onSnapshot((snap) => {
          setGroups(snap.docs.map(formatDoc));
        });
      return () => {
        attributesSub();
        assignedAttributesSub();
        groupsSub();
      };
    } else {
      setAttributes([]);
    }
  }, [user]);

  useEffect(() => {
    const sub = initialize();
    return () => sub();
  }, []);

  const signIn = async ({ email, password }) => {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signOut = async () => {
    await firebase.auth().signOut();
  };

  function getAttributeForTransport(transportId) {
    if (transportId) {
      const assigned = assignedAttributes?.find((a) => a.id === transportId);
      if (assigned) {
        const attribute = attributes?.find(
          (a) => a.id === assigned.attributeId
        );
        if (attribute) {
          return attribute;
        }
      }
    }
    return null;
  }

  const getTransportStatus = async (transportId) => {
    console.log("transportId", transportId);
    try {
      const status = await firebase
        .firestore()
        .collection("transports")
        .where("id", "==", transportId)
        .get();
      console.log("status IN AUTH", status.docs[0].data().status);
      return status.docs[0].data();
    } catch (e) {
      console.log(e);
    }
  };

  const { data: notifications } = useDataFromRef({
    ref:
      user?.id &&
      Notifications.where("notReadBy", "array-contains", user.id).orderBy(
        "createdAt", // You can change this to "data.startDate" if you want to order by start date
        "desc"
      ),
    initialState: [],
    listener: true,
    refreshArray: [user?.id],
    condition: !!user?.id,
    format: (data) => {
      const filteredNotificationsData = data.map((doc) => {
        // Assuming startDate is stored under the "data" field in the document
        const startDate = doc.data.transport.startDate
          ? doc.data.transport.startDate.toDate()
          : null;

        // Formatting the start date
        const formattedDate = startDate
          ? moment(startDate).format("DD MMMM YYYY à HH:mm")
          : "Date non spécifiée";

        const capitalizedDate =
          formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

        return {
          ...doc,
          startDate: capitalizedDate,
        };
      });
      return filteredNotificationsData;
    },
  });

  return {
    authenticating,
    user,
    attributes,
    assignedAttributes,
    getAttributeForTransport,
    getTransportStatus,
    groups,
    signIn,
    signOut,
    notifications,
  };
}
