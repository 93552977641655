import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import Palette from "../../../styles/Palette";
import { useAuth } from "../../../providers/Auth";
import { RiDeleteBin4Line } from "react-icons/ri";
import Button from "../../../components/Button";
import firebase, { Groups, Users } from "../../../services/Firebase";
import Input from "../../../components/Input";
import { toast } from "react-hot-toast";
import Loading from "../../../components/Loading";
import useDataFromRef from "../../../hooks/useDataFromRef";
import Colors from "../../../styles/Colors";
import createGroup from "../../../actions/Groups";

function AddGroupModal({ setView }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const addGroup = async () => {
    try {
      if (name?.length < 5) {
        throw new Error("Le nom du groupe doit être de 5 caractères minimum.");
      }
      setLoading(true);
      await createGroup({ name });
      toast.success(`Le groupe ${name} à bien été ajouté`);
      setView("VIEW_GROUPS");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      onPrevClick={() => setView("VIEW_GROUPS")}
      onClose={() => {
        if (!loading) setView("VIEW_GROUPS");
      }}
      style={{ width: 500 }}
      title={"Ajouter un groupe"}
      onFooter={() => (
        <div className={"containerRow"} style={{ justifyContent: "flex-end" }}>
          <Button
            containerStyle={{ marginRight: 10 }}
            variant={"secondary"}
            onClick={() => setView("VIEW_GROUPS")}
            text={"Annuler"}
          />
          <Button onClick={addGroup} text={"Ajouter"} />
        </div>
      )}
    >
      <div className={"container"} style={{ marginBottom: 10 }}>
        <Input
          label={"Nom du groupe"}
          required
          value={name}
          onChange={setName}
        />
        {/*<Input label={"Couleur"} required value={color} onChange={setColor} />*/}
        {loading && <Loading absolute />}
      </div>
    </Modal>
  );
}

function AddMemberModal({ selected, setView }) {
  const [mail, setMail] = useState();
  const [loading, setLoading] = useState(false);

  async function addMember() {
    try {
      setLoading(true);
      const { data } = await firebase
        .functions()
        .httpsCallable("groups-addOrDeleteByMail")({
        mail,
        groupId: selected,
      });
      toast.success(data.message);
      setView("VIEW_GROUPS");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      onPrevClick={() => setView("VIEW_GROUPS")}
      onClose={() => {
        if (!loading) setView("VIEW_GROUPS");
      }}
      style={{ width: 500 }}
      title={"Ajouter un membre"}
      onFooter={() => (
        <div className={"containerRow"} style={{ justifyContent: "flex-end" }}>
          <Button
            containerStyle={{ marginRight: 10 }}
            variant={"secondary"}
            onClick={() => setView("VIEW_GROUPS")}
            text={"Annuler"}
          />
          <Button onClick={addMember} text={"Inviter"} />
        </div>
      )}
    >
      <div className={"container"} style={{ marginBottom: 10 }}>
        <Input
          label={"Email"}
          required
          placeholder={"john@doe.com"}
          value={mail}
          onChange={setMail}
        />
        {loading && <Loading absolute />}
      </div>
    </Modal>
  );
}

export default ({ history, location }) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();

  const { data: selectedGroup } = useDataFromRef({
    ref: selected && Groups.doc(selected),
    listener: true,
    initialState: null,
    simpleRef: true,
    condition: !!selected,
    refreshArray: [selected],
  });

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [type, setType] = useState("ALL");
  const [view, setView] = useState("VIEW_GROUPS");
  const params = new URLSearchParams(location.search);
  const isVisible = !!params.get("editGroup");
  const { groups, user } = useAuth();

  async function deleteGroup() {
    try {
      setLoading(true);
      setSelected(null);
      await Groups.doc(selected).delete();
      toast.success("Groupe supprimé");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }

  async function deleteMember(mail) {
    try {
      setLoading(true);
      const { data } = await firebase
        .functions()
        .httpsCallable("groups-addOrDeleteByMail")({
        mail,
        groupId: selected,
      });
      toast.success(data.message);
      setView("VIEW_GROUPS");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isVisible) {
      if (selectedGroup) {
        const membersPromise = selectedGroup?.members.map(
          (memberId) =>
            new Promise((resolve, reject) => {
              Users.doc(memberId)
                .get()
                .then((m) => {
                  resolve({
                    id: m.id,
                    name: m.get("firstName") || m.get("name"),
                    mail: m.get("mail"),
                    role: m.get("role"),
                  });
                })
                .catch(reject);
            })
        );
        Promise.all(membersPromise).then(setSelectedMembers);
      } else {
        setSelectedMembers([]);
      }
    }
  }, [isVisible, selectedGroup]);

  if (!isVisible) return null;

  if (view === "ADD_MEMBER") {
    return <AddMemberModal {...{ selected, setView }} />;
  }

  if (view === "ADD_GROUP") {
    return <AddGroupModal {...{ setView }} />;
  }

  return (
    <Modal
      title={"Gérer mes groupes"}
      onRight={() => (
        <Button text={"Nouveau groupe"} onClick={() => setView("ADD_GROUP")} />
      )}
    >
      <div className={"container"} style={{ height: 500 }}>
        <div
          className={"containerRow"}
          style={{
            height: 30,
            width: 350,
            fontWeight: 600,
            alignSelf: "center",
            marginBottom: 20,
          }}
        >
          {[
            { name: "Tous", value: "ALL" },
            { name: "Mes groupes", value: "OWNED" },
          ].map((s) => {
            const isSelected = s.value === type;
            return (
              <div
                key={s.value}
                onClick={() => {
                  setType(s.value);
                }}
                className={"flex"}
                style={{
                  cursor: "pointer",
                  color: isSelected ? Palette.blue : Colors.text.secondary,
                  borderBottom: `${isSelected ? 4 : 2}px solid ${
                    isSelected ? Palette.blue : Palette.lightGrey
                  }`,
                  textAlign: "center",
                }}
              >
                {s.name}
              </div>
            );
          })}
        </div>
        <div className={"flex containerRow"}>
          <div className={"container"} style={{ flex: 2, overflowY: "auto" }}>
            {groups
              .filter((g) =>
                type === "OWNED" ? g.createdBy === user.id : true
              )
              .map((g) => {
                const isSelected = g.id === selected;
                return (
                  <div key={g.id}>
                    <div
                      onClick={() => setSelected(g.id)}
                      className={"containerRow"}
                      style={{
                        cursor: "pointer",
                        height: 50,
                        alignItems: "center",
                        padding: 10,
                        boxSizing: "border-box",
                        borderRadius: 10,
                        backgroundColor: isSelected
                          ? Palette.blueTranslucent
                          : "transparent",
                      }}
                    >
                      <div
                        style={{
                          height: 32,
                          width: 32,
                          borderRadius: 16,
                          backgroundColor: g.color,
                          marginRight: 10,
                        }}
                      />
                      <div style={{ fontWeight: 500 }}>{g.name}</div>
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        height: 1,
                        backgroundColor: Palette.grey,
                      }}
                    />
                  </div>
                );
              })}
          </div>
          {selected && selectedGroup && (
            <div
              className={"container"}
              style={{
                flex: 1.5,
                marginLeft: 10,
                borderLeft: `1px solid ${Palette.grey}`,
              }}
            >
              <div
                style={{
                  overflowY: "auto",
                }}
                className={"flex container"}
              >
                {selectedMembers?.map((m) => {
                  const isMe = m.id === user.id;
                  return (
                    <div key={m.id}>
                      <div
                        className={"containerRow"}
                        style={{
                          boxSizing: "border-box",
                          height: 64,
                          alignItems: "center",
                          justifyContent: "space-between",
                          paddingLeft: 20,
                          fontWeight: 500,
                        }}
                      >
                        <div>
                          <div>{m.name}</div>
                          <div
                            style={{ fontSize: 13, color: Palette.darkGrey }}
                          >
                            {m.role === "REGULATOR"
                              ? "Société de transport"
                              : "Hôpital"}
                          </div>
                        </div>
                        {!isMe && selectedGroup?.createdBy === user.id && (
                          <Button
                            onClick={async () => {
                              if (
                                window.confirm(
                                  `Êtes-vous sûr de vouloir supprimer ${m.name} du groupe ${selectedGroup?.name} ?`
                                )
                              ) {
                                await deleteMember(m.mail);
                              }
                            }}
                            size={"smallIcon"}
                            variant={"secondaryError"}
                            IconLeft={RiDeleteBin4Line}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          height: 1,
                          backgroundColor: Palette.grey,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <div className={"containerRow"} style={{ paddingLeft: 30 }}>
                {selectedGroup.createdBy === user.id ? (
                  <>
                    <Button
                      onClick={() => setView("ADD_MEMBER")}
                      text={"Inviter dans le groupe"}
                      containerStyle={{ marginRight: 10 }}
                    />
                    <Button
                      variant={"primaryError"}
                      onClick={async () => {
                        if (
                          window.confirm(
                            `Êtes-vous sûr de vouloir supprimer le groupe ${selectedGroup?.name} ? Les transports en attente attribués à ce groupe passeront en bourse publique`
                          )
                        ) {
                          await deleteGroup();
                        }
                      }}
                      text={"Supprimer ce groupe"}
                    />
                  </>
                ) : (
                  <Button
                    fullWidth
                    variant={"primaryError"}
                    onClick={async () => {
                      if (
                        window.confirm(
                          `Êtes-vous sûr de vouloir quitter ce groupe ?`
                        )
                      ) {
                        await deleteMember(user.mail);
                        setSelected(null);
                      }
                    }}
                    text={"Quitter ce groupe"}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        {loading && <Loading absolute />}
      </div>
    </Modal>
  );
};
