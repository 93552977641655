import React from "react";
import Palette from "../styles/Palette";

export default ({ value, onChange, name, style }) => {
  const isSelected = !!value;
  return (
    <div
      onClick={() => onChange(!value)}
      className={"containerRow"}
      style={{ cursor: "pointer", alignItems: "center", ...style }}
    >
      <div
        style={{
          width: 12,
          height: 12,
          borderRadius: 2,
          // border: `1px solid ${Colors.input.border}`,
          backgroundColor: "rgba(222, 224, 243, 1)",
          marginRight: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isSelected && (
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 2,
              backgroundColor: Palette.blue,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={"/icons/check.png"} style={{ width: 6, height: 6 }} />
          </div>
        )}
      </div>
      <div style={{ fontSize: 14 }}>{name}</div>
    </div>
  );
};
