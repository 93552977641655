import React from "react";
import Modal from "../../../components/Modal";
import { useGlobal } from "reactn";
import Palette from "../../../styles/Palette";
import Button from "../../../components/Button";
import { toast } from "react-hot-toast";
import { refuseTransport } from "../../../services/Firebase";

export default () => {
  const [transport, setTransport] = useGlobal("transportToRefuse");
  const isVisible = !!transport;
  if (!isVisible) return null;

  console.log("TRANSPORT TO REFUSE: ", transport);

  const onRefuse = async () => {
    try {
      await refuseTransport(transport.id, status, transport);
      toast.success("Transport refusé.");
      await setTransport(null);
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <Modal
      onClose={() => setTransport(null)}
      style={{ width: 334 }}
      showPrevButton={false}
      onTitle={() => (
        <div
          className={"containerCenter"}
          style={{ fontSize: 15, fontWeight: 600, textAlign: "center" }}
        >
          <div style={{ marginBottom: 5 }}>
            Etes-vous sûr de vouloir refuser
          </div>
          <div style={{ color: Palette.red }}>
            Transport {transport.id?.slice(0, 5)}
          </div>
        </div>
      )}
    >
      <div className={"container"} style={{ width: 165, alignSelf: "center" }}>
        <Button
          variant={"primaryError"}
          text={"Refuser"}
          containerStyle={{ marginBottom: 5 }}
          onClick={onRefuse}
        />
        <Button
          variant={"secondary"}
          text={"Retour"}
          onClick={() => setTransport(null)}
        />
      </div>
    </Modal>
  );
};
