import React from "react";
import {
  containerFlexCenterCenter,
  containerFlexRowCenterCenter,
} from "../../styles/main";
import Fonts from "../../styles/fonts";
import invoiceStatus from "../../constants/tripStatus";

export default ({ value = [], onChange }) => {
  return (
    <div
      style={{
        ...containerFlexRowCenterCenter,
        height: 35,
        minWidth: 550,
        gap: 5,
      }}
    >
      {Object.values(invoiceStatus).map(
        ({ value: val, shortName, backgroundColor, color }) => {
          const isSelected = value.includes(val);
          return (
            <div
              onClick={() => {
                if (isSelected) {
                  onChange(value.filter((v) => v !== val));
                } else {
                  onChange([...value, val]);
                }
              }}
              key={val}
              style={{
                ...containerFlexCenterCenter,
                ...Fonts.regular(14, isSelected ? color : "#7D7D7D"),
                backgroundColor: isSelected ? backgroundColor : "#DADADA",
                padding: "0 10px",
                borderRadius: 5,
                height: "100%",
                flex: 1,
                border: `1px solid ${isSelected ? color : "#7D7D7D"}`,
                cursor: "pointer",
              }}
            >
              {shortName}
            </div>
          );
        }
      )}
    </div>
  );
};
