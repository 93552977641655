import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { useGlobal } from "reactn";
import Palette from "../../../styles/Palette";
import Button from "../../../components/Button";
import { toast } from "react-hot-toast";
import { cancelTransport, Transports } from "../../../services/Firebase";
import { useAuth } from "../../../providers/Auth";
import AssignBourseModal from "./AssignBourseModal";
import Transport from "../Transport";

export default () => {
  const [transport, setTransport] = useGlobal("transportToCancelOrBourse");
  const { user } = useAuth();

  const [showAssignBourseModal, setShowAssignBourseModal] = useState(false);
  const isVisible = !!transport;

  if (!isVisible) return null;

  return (
    <Modal
      onClose={() => setTransport(null)}
      style={{ width: 334 }}
      showPrevButton={false}
      onTitle={() => (
        <div
          className={"containerCenter"}
          style={{ fontSize: 15, fontWeight: 600, textAlign: "center" }}
        >
          <div style={{ marginBottom: 5 }}>
            Etes-vous sûr de vouloir effectuer cette action ?
          </div>
          <div style={{ color: Palette.red }}>
            Transport {transport.id?.slice(0, 5)}
          </div>
        </div>
      )}
    >
      <div className={"container"} style={{ width: 165, alignSelf: "center" }}>
        <Button
          onClick={async () => {
            console.log("Annuler");
            if (
              window.confirm("Êtes-vous sûr de vouloir annuler ce transport ?")
            ) {
              if (transport?.createdBy === user?.id) {
                console.log("TRANSPORT " + transport.id + " DELETED");
                try {
                  await Transports.doc(transport.id).delete();
                  console.log(`Transport ${transport.id} supprimé`);
                } catch (error) {
                  console.error(
                    `Erreur lors de la suppression du transport ${transport.id} : `,
                    error
                  );
                }
              } else {
                try {
                  if (transport.returnTransportId) {
                    await cancelTransport(transport.id, transport, user.role);
                    await cancelTransport(
                      transport.returnTransportId,

                      user.role,
                      transport
                    );
                  } else {
                    console.log(
                      "TRANSPORT " + transport.id + " CANCELED_BY_" + user.role
                    );
                    await cancelTransport(transport.id, user.role, transport);
                  }
                  setTransport(null);
                } catch (error) {
                  console.error(
                    `Erreur lors de l'annulation du transport : `,
                    error
                  );
                }
              }
            }
          }}
          text="Annuler"
          variant={"primaryError"}
          fullWidth
          containerStyle={{
            marginBottom: 10,
          }}
        />
        <Button
          onClick={() => {
            console.log("Attribuer en BOURSE");
            if (
              window.confirm(
                "Êtes-vous sûr de vouloir attribuer ce transport en bourse ?"
              )
            ) {
              console.log("TRANSPORT " + transport.id + " BOURSE");
              setShowAssignBourseModal(true); // Changez l'état pour afficher la modal
            }
          }}
          text="Attribuer en BOURSE"
          variant={"primary"}
          containerStyle={{
            // marginRight: 5,
            // width: 200,
            marginBottom: 10,
          }}
        />
        {/* <Button
          variant={"primaryError"}
          text={"Refuser"}
          containerStyle={{ marginBottom: 5 }}
          onClick={onRefuse}
        /> */}
        <Button
          variant={"secondary"}
          text={"Retour"}
          onClick={() => setTransport(null)}
        />
      </div>
      {showAssignBourseModal && (
        <AssignBourseModal
          transport={transport}
          setShowAssignBourseModal={setShowAssignBourseModal}
        />
      )}
    </Modal>
  );
};
