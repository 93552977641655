import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../../components/Button";
import TransportResume from "../../../components/TransportResume";
import Palette from "../../../styles/Palette";
import moment from "moment";
import { toast } from "react-hot-toast";
import Colors from "../../../styles/Colors";
import {
  getTransport,
  updateTransport,
} from "../../../services/Firebase";

export default () => {
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [transport, setTransport] = useState();
  const [startDate, setStartDate] = useState();
  const params = new URLSearchParams(location.search);
  const isVisible = !!params.get("regulatedTransportRequest");
  const transportId = params.get("regulatedTransportRequest");
  const [viewState, setViewState] = useState("EDIT"); // EDIT / CONFIRM

  useEffect(() => {
    if (isVisible && transportId !== "new") {
      setLoading(true);
      getTransport(transportId)
        .then((t) => {
          setTransport(t);
        })
        .finally(() => setLoading(false));
    }
  }, [transportId]);

  const onPressEdit = async () => {
    try {
      if (viewState === "EDIT") {
        if (moment(startDate).isSame(transport?.startDate))
          throw new Error(
            "Vous n'avez pas apporté de modification à l'heure de prise en charge"
          );
        if (moment(startDate).isSameOrAfter(transport?.endDate))
          throw new Error(
            "L'heure de prise en charge doit être antérieure à l'heure de rendez-vous"
          );
        setViewState("CONFIRM");
      } else if (viewState === "CONFIRM") {
        await updateTransport(transportId, { startDate });
        toast.success("Transport mis à jour.");
        history.push(location.pathname);
        setViewState("EDIT");
      }
    } catch (e) {
      toast.error(e.message);
    }
    console.log(startDate);
  };

  if (!isVisible) return null;

  if (viewState === "EDIT")
    return (
      <Modal
        style={{ width: 500 }}
        onTitle={() => (
          <div
            className={"containerCenter"}
            style={{
              fontSize: 20,
              fontWeight: 500,
            }}
          >
            <div style={{ marginRight: 10 }}>Voulez-vous modifier le</div>
            <div style={{ color: Palette.blue }}>
              transport {transportId?.slice(0, 5)}
            </div>
          </div>
        )}
        onFooter={() => (
          <div
            className={"containerCenter"}
            style={{ width: 166, justifyContent: "center" }}
          >
            <Button
              text={"Modifier"}
              containerStyle={{ height: 40, marginBottom: 10 }}
              fullWidth
              onClick={onPressEdit}
            />

            <Button
              text={"Annuler"}
              variant={"secondary"}
              containerStyle={{ height: 40 }}
              fullWidth
              onClick={() => history.goBack()}
            />
          </div>
        )}
      >
        {!loading && (
          <div
            className={"container"}
            style={{ marginBottom: 20, flex: 1, alignItems: "center" }}
          >
            <TransportResume
              editable
              transport={transport}
              onChange={setStartDate}
              variant={"MODAL"}
              containerStyle={{ width: 400 }}
            />
          </div>
        )}
      </Modal>
    );

  if (viewState === "CONFIRM")
    return (
      <Modal
        style={{ width: 334 }}
        showPrevButton={false}
        onTitle={() => (
          <div
            className={"containerCenter"}
            style={{
              fontSize: 15,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Etes-vous sûr de vouloir changer l’horaire de PEC ?
          </div>
        )}
        onFooter={() => (
          <div
            className={"containerCenter"}
            style={{ width: 166, justifyContent: "center" }}
          >
            <Button
              text={"Modifier"}
              containerStyle={{ height: 40, marginBottom: 10 }}
              fullWidth
              onClick={onPressEdit}
            />

            <Button
              onClick={() => setViewState("EDIT")}
              text={"Annuler"}
              variant={"secondary"}
              containerStyle={{ height: 40 }}
              fullWidth
            />
          </div>
        )}
      >
        {!loading && (
          <div
            className={"containerRowCenter"}
            style={{ marginBottom: 20, flex: 1, alignItems: "center" }}
          >
            <div className={"containerCenter"}>
              <div style={{ fontSize: 12, color: Colors.text.secondary }}>
                AVANT
              </div>
              <div
                style={{ fontSize: 15, fontWeight: 500, color: Palette.red }}
              >
                {moment(transport?.startDate).format("HH:mm")}
              </div>
            </div>
            <img
              src={"/icons/arrow.png"}
              style={{
                marginLeft: 10,
                marginRight: 10,
                width: 12,
                height: 14,
                transform: "rotate(90deg)",
              }}
            />
            <div className={"containerCenter"}>
              <div style={{ fontSize: 12, color: Colors.text.secondary }}>
                APRES
              </div>
              <div
                style={{ fontSize: 15, fontWeight: 500, color: Palette.red }}
              >
                {moment(startDate).format("HH:mm")}
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
};
