import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../shadcn/ui/card";
import { Button } from "../shadcn/ui/button";
import { Input } from "../shadcn/ui/input";
import { Label } from "../shadcn/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn/ui/select";
import { UserIcon, UserPlusIcon, Trash2Icon } from "lucide-react";
import { Badge } from "@components/shadcn/ui/badge";
import { DatePicker } from "@components/Picker/DatePicker";
import InputPlacesAuto from "@components/InputPlacesAuto";
import { toast } from "react-hot-toast";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import {
  addEmployee,
  updateEmployee,
  deleteEmployee,
} from "@actions/Employees";

function AmbulancierForm({ onClose }) {
  // Données de développement modifiées
  const devData = {
    type: "DEA",
    gender: "M",
    lastName: "Dupont",
    firstName: "Jean",
    birthDate: "1990-01-01",
    socialSecurity: "1900175789456",
    address: {
      street: "123 Rue de la Paix",
      postalCode: "75001",
      city: "Paris",
    },
    phone: "0612345678",
    documents: {
      diploma: "http://example.com/diploma.pdf",
      drivingLicense: "http://example.com/license.pdf",
      healthCard: "",
      rib: "http://example.com/rib.pdf",
    },
  };

  const [selectedType, setSelectedType] = useState(devData.type);
  const [documents, setDocuments] = useState(devData.documents);
  const [employee, setEmployee] = useState(devData);

  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");

  const fileInputRefs = {
    diploma: useRef(null),
    drivingLicense: useRef(null),
    healthCard: useRef(null),
    rib: useRef(null),
  };

  // Utiliser useEffect pour pré-remplir le formulaire avec les données de développement
  useEffect(() => {
    setEmployee(devData);
    setDocuments(devData.documents);
    setSelectedType(devData.type);
  }, []);

  const handleAutocomplete = ({ streetNumber, route, city, postalCode }) => {
    setEmployee((prev) => ({
      ...prev,
      address: {
        street: `${streetNumber || ""} ${route || ""}`,
        postalCode: postalCode || "",
        city: city || "",
      },
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "street" || id === "postalCode" || id === "city") {
      setEmployee((prev) => ({
        ...prev,
        address: { ...prev.address, [id]: value },
      }));
    } else if (id === "firstName" || id === "lastName") {
      const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
      setEmployee((prev) => ({ ...prev, [id]: capitalizedValue }));
    } else if (id === "phone") {
      // Limiter à 10 chiffres et ne permettre que des chiffres
      const phoneNumber = value.replace(/\D/g, "").slice(0, 10);
      setEmployee((prev) => ({ ...prev, [id]: phoneNumber }));
    } else if (id === "socialSecurity") {
      // Limiter à 13 chiffres et ne permettre que des chiffres
      const socialSecurityNumber = value.replace(/\D/g, "").slice(0, 13);
      setEmployee((prev) => ({ ...prev, [id]: socialSecurityNumber }));
    } else {
      setEmployee((prev) => ({ ...prev, [id]: value }));
    }
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
    setEmployee((prev) => ({ ...prev, type }));
  };

  const handleDocumentChange = (documentType) => {
    if (documents[documentType]) {
      // Si le document existe déjà, on le supprime
      setDocuments((prev) => ({ ...prev, [documentType]: "" }));
      setEmployee((prevEmployee) => ({
        ...prevEmployee,
        documents: { ...prevEmployee.documents, [documentType]: "" },
      }));
    } else {
      // Sinon, on ouvre le sélecteur de fichier
      fileInputRefs[documentType].current.click();
    }
  };

  const handleFileChange = async (documentType, event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (
        allowedTypes.includes(file.type) ||
        (fileExtension === "jpg" && file.type === "image/jpeg")
      ) {
        try {
          // Simuler l'upload et obtenir une URL
          const fakeUrl = `http://example.com/${documentType}_${Date.now()}.${fileExtension}`;

          setDocuments((prev) => ({ ...prev, [documentType]: fakeUrl }));
          setEmployee((prevEmployee) => ({
            ...prevEmployee,
            documents: { ...prevEmployee.documents, [documentType]: fakeUrl },
          }));

          toast.success(`Document ${documentType} uploadé avec succès`);
        } catch (error) {
          console.error("Erreur lors de l'upload:", error);
          toast.error("Erreur lors de l'upload du document");
        }
      } else {
        toast.error(
          "Type de fichier non autorisé. Veuillez sélectionner un fichier .png, .jpeg, .jpg ou .pdf."
        );
        event.target.value = "";
      }
    }
  };

  const validateForm = () => {
    // Mapping des champs avec leurs placeholders
    const fieldPlaceholders = {
      gender: "Sexe",
      lastName: "Nom",
      firstName: "Prénom",
      birthDate: "D.naissance",
      socialSecurity: "NSS",
      phone: "Numéro Tel",
      street: "Rue",
      postalCode: "Code postal",
      city: "Ville",
    };

    // Vérification des champs obligatoires
    const requiredFields = [
      "gender",
      "lastName",
      "firstName",
      "birthDate",
      "socialSecurity",
      "phone",
    ];
    const addressFields = ["street", "postalCode", "city"];

    requiredFields.forEach((field) => {
      if (!employee[field]) {
        throw new Error(
          `Le champ ${fieldPlaceholders[field]} est obligatoire.`
        );
      }
    });

    addressFields.forEach((field) => {
      if (!employee.address[field]) {
        throw new Error(
          `Le champ ${fieldPlaceholders[field]} est obligatoire.`
        );
      }
    });

    // Vérification du numéro de sécurité sociale
    if (employee.socialSecurity.length < 13) {
      throw new Error(
        `Le ${fieldPlaceholders.socialSecurity} doit contenir au moins 13 chiffres.`
      );
    }

    // Vérification du numéro de téléphone
    if (employee.phone.length !== 10) {
      throw new Error(
        `Le ${fieldPlaceholders.phone} doit contenir exactement 10 chiffres.`
      );
    }
  };

  const handleSubmit = async () => {
    try {
      validateForm();

      // Création ou mise à jour de l'employé dans Firestore

      const employeeData = {
        ...employee,
        documents: documents,
      };

      if (employee.id) {
        // Mise à jour d'un employé existant
        await updateEmployee(employee.id, employeeData);
      } else {
        // Création d'un nouvel employé
        await addEmployee(employeeData);
      }

      toast.success("Informations de l'employé enregistrées avec succès !");
      onClose(); // Fermer le formulaire après soumission réussie
    } catch (error) {
      console.error("Erreur lors de la soumission:", error);
      toast.error(error.message);
    }
  };

  return (
    <Card className="w-[400px] bg-white shadow-lg rounded-2xl overflow-hidden">
      <CardHeader
        style={{
          padding: 16,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <Badge
            style={{
              borderRadius: 9999,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 8,
            }}
            className="bg-borderV2 text-nightBlue"
          >
            <span style={{ fontWeight: "600", fontSize: 14 }}>34</span>
          </Badge>
          <CardTitle style={{ fontSize: 24, fontWeight: "700" }}>
            Ambulancier
          </CardTitle>
        </div>
        <div style={{ display: "flex", gap: 8 }}>
          <Button
            //TODO: resize icons
            style={{
              borderRadius: 16,
              backgroundColor: selectedType === "DEA" ? "#F7F9FC" : "#F6F7FF",
              //TODO: adapt hexa
              padding: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: 50,
              height: 50,
              border:
                selectedType === "DEA"
                  ? "2px solid #02196C"
                  : "1px solid #DEE0F3",
              //TODO: adapt hexa
            }}
            onClick={() => handleTypeChange("DEA")}
          >
            <UserPlusIcon
              style={{
                color: "#2563EB",
              }}
            />
            <span className="text-[10px] font-semibold text-red-500 italic">
              DEA
            </span>
          </Button>
          <Button
            style={{
              borderRadius: 16,
              backgroundColor: selectedType === "AUX" ? "#F7F9FC" : "#F6F7FF",
              padding: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: 50,
              height: 50,
              border:
                selectedType === "AUX"
                  ? "2px solid #02196C"
                  : "1px solid #DEE0F3",
              //TODO: adapt hexa
            }}
            onClick={() => handleTypeChange("AUX")}
          >
            <UserIcon
              style={{
                color: "#2563EB",
              }}
            />
            <span className="text-[10px] font-semibold text-[#6B7280] italic">
              AUX
            </span>
          </Button>
        </div>
      </CardHeader>
      <CardContent className="p-4 space-y-4">
        <div className="grid grid-cols-8 gap-3">
          <div className="col-span-2">
            <Label htmlFor="gender" className="text-formGrey">
              Sexe
            </Label>
            <Select
              onValueChange={(value) =>
                setEmployee((prev) => ({ ...prev, gender: value }))
              }
            >
              <SelectTrigger id="gender">
                <SelectValue placeholder="M" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="m">M</SelectItem>
                <SelectItem value="mme">Mme</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="col-span-3">
            <Label htmlFor="lastName" className="text-formGrey">
              Nom
            </Label>
            <Input
              id="lastName"
              placeholder="Nom"
              value={employee.lastName}
              onChange={handleInputChange}
              className="h-10"
            />
          </div>
          <div className="col-span-3">
            <Label htmlFor="firstName" className="text-formGrey">
              Prénom
            </Label>
            <Input
              id="firstName"
              placeholder="Prénom"
              value={employee.firstName}
              onChange={handleInputChange}
              className="h-10"
            />
          </div>
        </div>

        <div className="grid grid-cols-7 gap-4">
          <div className="col-span-3">
            <Label htmlFor="birthDate" className="text-formGrey">
              Date de naissance
            </Label>
            <DatePicker
              placeholder="D.naissance"
              date={employee.birthDate}
              setDate={(value) =>
                setEmployee((prev) => ({ ...prev, birthDate: value }))
              }
              className="w-full"
            />
          </div>
          <div className="col-span-4">
            <Label htmlFor="socialSecurity" className="text-formGrey">
              N°Sécurité Sociale
            </Label>
            <Input
              id="socialSecurity"
              placeholder="NSS"
              type="number"
              className="no-spinner"
              maxLength={13}
              value={employee.socialSecurity}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div>
          <Label htmlFor="street" className="text-formGrey">
            Adresse & Téléphone
          </Label>
          <InputPlacesAuto
            required
            placeholder="Rue"
            style={{ marginBottom: 10 }}
            value={employee.address.street}
            onChange={(value) =>
              handleInputChange({ target: { id: "street", value } })
            }
            onSelect={handleAutocomplete}
            iconRight="/icons/search.png"
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <Input
              id="postalCode"
              placeholder="Code postal"
              value={employee.address.postalCode}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <Input
              id="city"
              placeholder="Ville"
              value={employee.address.city}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <Input
              id="phone"
              placeholder="Numéro Tel"
              value={employee.phone}
              onChange={handleInputChange}
              maxLength={10}
              type="tel"
              pattern="[0-9]*"
            />
          </div>
        </div>
        <div>
          <Label className="text-formGrey">Documents</Label>
          <div className="mt-2 grid grid-cols-2 gap-2">
            {Object.entries(documents).map(([docType, url]) => (
              <React.Fragment key={docType}>
                <Button
                  variant="outline"
                  className={`justify-between ${
                    url
                      ? "bg-greenValid text-greenValid-text"
                      : "bg-blueWaiting"
                  } border-2 
                  ${
                    url
                      ? "border-greenValid-border"
                      : "border-blueWaiting-border"
                  }`}
                  onClick={() => handleDocumentChange(docType)}
                >
                  <span
                    className={`font-bold ${
                      url ? "text-greenValid-text" : "text-blueWaiting-text"
                    }`}
                  >
                    {docType === "diploma" && "Diplôme"}
                    {docType === "drivingLicense" && "Permis"}
                    {docType === "healthCard" && "Carte vitale"}
                    {docType === "rib" && "RIB"}
                  </span>
                  {url ? (
                    <span>✓</span>
                  ) : (
                    <span className="text-lg font-regular text-blueWaiting-text ">
                      +
                    </span>
                  )}
                </Button>
                <input
                  type="file"
                  ref={fileInputRefs[docType]}
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(docType, e)}
                  accept=".png,.jpeg,.jpg,.pdf"
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </CardContent>
      <CardFooter
        style={{
          padding: 16,
          display: "flex",
          justifyContent: "space-between",
        }}
        // TODO: add checkIncon and function delete
      >
        <div style={{ display: "flex", gap: 16 }}>
          <Button
            variant="outline"
            onClick={onClose}
            style={{ borderColor: "#D1D5DB", color: "#374151" }}
          >
            Fermer
          </Button>
          <Button
            onClick={handleSubmit}
            style={{ backgroundColor: "#2563EB", color: "white" }}
          >
            Valider
          </Button>
        </div>
        <Button
          variant="ghost"
          size="icon"
          style={{ backgroundColor: "#FEE2E2" }}
        >
          <Trash2Icon style={{ height: 24, width: 24, color: "#EF4444" }} />
        </Button>
      </CardFooter>
    </Card>
  );
}

export default AmbulancierForm;
