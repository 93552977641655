import React, { useEffect, useState } from "react";
import moment from "moment";
import ItinaryLine from "./ItinaryLine";
import { useHistory } from "react-router-dom";
import Colors from "../styles/Colors";
import Global from "../styles/Global";
import Palette from "../styles/Palette";
import TransportStatusTag from "./TransportStatusTag";
import { useAuth } from "../providers/Auth";
import useDataFromRef from "../hooks/useDataFromRef";
import { formatTransportDoc, Transports } from "../services/Firebase";

export default function TransportDetailsCard({
  transport: {
    id,
    startDate,
    endDate,
    returnTransportId = undefined,
    from,
    to,
    carType,
    goToTransportId,
  },
  transport,
  onClick,
  containerStyle,
  details = false,
  isReturn,
}) {
  const history = useHistory();
  const uid = useAuth().user.uid;
  const terminatedStatus = ["ENDED", "REFUSED", "CANCELED"];

  const { data: allerTransport } = useDataFromRef({
    ref: Transports.doc(goToTransportId),
    refreshArray: [goToTransportId],
    format: formatTransportDoc,
    condition: !!goToTransportId,
    simpleRef: true,
    listener: true,
  });

  if (goToTransportId && !terminatedStatus.includes(allerTransport?.status)) {
    console.log(
      "//////////////// STATUS DU TRANSPORT ALLER avec pour returnTransportId",
      allerTransport?.status
    );
    return null;
  }

  return (
    <>
      <div
        onClick={() => {
          onClick
            ? onClick(transport)
            : history.push({
                search: `?transport=${id}`,
              });
        }}
        style={{
          minHeight: 50,
          cursor: "pointer",
          position: "relative",
          ...containerStyle,
          ...Global.box,
        }}
      >
        <div className={"containerRowSpaceBetween"} style={{ marginRight: 10 }}>
          <div
            className={"container flex"}
            style={{
              marginRight: 20,
              alignItems: "flex-end",
              textAlign: "right",
            }}
          >
            <div
              style={{
                fontSize: 17,
                fontWeight: 500,
                marginBottom: 5,
                color: Palette.red,
              }}
            >
              {goToTransportId && transport?.status === "ACCEPTED"
                ? "-"
                : moment(startDate).format("HH:mm")}
            </div>
            <div style={{ fontSize: 17 }}>{from?.name}</div>
            <div style={{ fontSize: 14, color: Colors.text.secondary }}>
              {from?.address}
            </div>
            <div style={{ fontSize: 14, color: Colors.text.secondary }}>
              {from?.postalCode} {from?.city}
            </div>
          </div>
          <div className={"container"} style={{ alignItems: "center" }}>
            <img
              src={`/icons/${
                carType === "AMBULANCE" ? "ambulance" : "car"
              }.png`}
              style={{ width: 21, height: 21 }}
            />
            <ItinaryLine horizontal size={16} />
            {!goToTransportId ? (
              <div
                style={{
                  fontSize: 12,
                  color: Palette.red,
                  fontWeight: 500,
                }}
              >
                Aller
                {!!returnTransportId &&
                  (transport?.status === "PENDING" ||
                    transport?.status === "ACCEPTED") &&
                  transport?.isAssign !== true &&
                  " - Retour"}
                {!transport?.returnTransportId ? " Simple" : ""}
              </div>
            ) : (
              <div
                style={{
                  fontSize: 12,
                  color: Palette.red,
                  fontWeight: 500,
                }}
              >
                Retour
              </div>
            )}

            {/* //   <div style={{ fontSize: 12, color: Palette.red, fontWeight: 500 }}>
          //   ALLER{!!returnTransportId && " - RETOUR"}
          // </div> */}
          </div>
          <div style={{ marginLeft: 20 }} className={"container flex"}>
            <div
              style={{
                fontSize: 17,
                fontWeight: 500,
                marginBottom: 5,
                color: Palette.red,
              }}
            >
              {goToTransportId && transport?.status === "ACCEPTED"
                ? "-"
                : moment(endDate).format("HH:mm")}
            </div>
            <div style={{ fontSize: 17 }}>{to?.name}</div>
            <div style={{ fontSize: 14, color: Colors.text.secondary }}>
              {to?.address}
            </div>
            <div style={{ fontSize: 14, color: Colors.text.secondary }}>
              {to?.postalCode} {to?.city}
            </div>
          </div>
          {details ? (
            <div className={"containerRowSpaceBetween flex"}>
              <div className={"container"} style={{ fontSize: 14 }}>
                <div style={{ fontWeight: 700 }}>Patient</div>
                <div>
                  {transport.patient?.gender === "male" ? "M" : "Mme"}{" "}
                  {transport.patient?.firstName} {transport.patient?.lastName}
                </div>
              </div>
              {transport.regulator && (
                <div className={"container"} style={{ fontSize: 14 }}>
                  <div style={{ fontWeight: 700 }}>Transporteur</div>
                  <div>{transport.regulator?.name}</div>
                </div>
              )}
              {transport.status && (
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TransportStatusTag transport={transport} user={uid} />
                </div>
              )}
            </div>
          ) : (
            transport.status && (
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TransportStatusTag transport={transport} user={uid} />
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}
