import firebase from "firebase/compat/app";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import MarqueeText from "react-marquee-text";
import "react-marquee-text/dist/styles.css";
import { useHistory } from "react-router-dom";
import { useGlobal } from "reactn";
import { STATUS } from "../helpers/status";
import useDataFromRef from "../hooks/useDataFromRef";
import { useAuth } from "../providers/Auth";
import { formatTransportDoc, Transports } from "../services/Firebase";
import Global from "../styles/Global";
import Palette from "../styles/Palette";
import ItinaryLine from "./ItinaryLine";

export default ({
  transport: {
    patient = {},
    id,
    startDate,
    endDate,
    from,
    to,
    carType,
    status,
    goToTransportId,
    returnTransportId
  },
  transport,
  onClick,
  containerStyle,
  selected,
  isBourse,
  isReturn
}) => {
  const { attributes, assignedAttributes, getAttributeForTransport, groups } =
    useAuth();
  const attribute = useMemo(
    () => getAttributeForTransport(id),
    [transport, attributes, assignedAttributes]
  );
  const history = useHistory();
  const { uid } = firebase.auth().currentUser;
  const [selectedItem, setSelectedItem] = React.useState(null);

  const [transportSelected, setTransportSelected] =
    useGlobal("transportSelected");

  useEffect(() => {
    // console.log("transportSelected", transportSelected?.id);
    if (transportSelected?.id != id) {
      setSelectedItem(null);
    }
  }, [transportSelected]);

  const handleClickFrom = () => {
    console.log("selectedItem IN FROM", selectedItem);
    setSelectedItem("from");
  };

  const handleClickTo = () => {
    console.log("selectedItem IN TO", selectedItem);
    setSelectedItem("to");
  };

  const terminatedStatus = ["ENDED", "REFUSED", "CANCELED"];

  const { data: allerTransport } = useDataFromRef({
    ref: Transports.doc(goToTransportId),
    refreshArray: [goToTransportId],
    format: formatTransportDoc,
    condition: !!goToTransportId,
    simpleRef: true,
    listener: true
  });

  if (goToTransportId && !terminatedStatus.includes(allerTransport?.status)) {
    console.log(
      "//////////////// STATUS DU TRANSPORT ALLER avec pour returnTransportId",
      allerTransport?.status
    );
    return null;
  }

  return (
    <>
      <div
        onClick={() => {
          onClick
            ? onClick(transport)
            : history.push({
                search: `?transport=${id}`
              });
        }}
        style={{
          ...Global.box,
          minHeight: 76,
          flex: 1,
          cursor: "pointer",
          padding: 0,
          position: "relative",
          border: `2px solid`,
          // borderColor: STATUS[status]?.style.borderColor || "#C0D7FF",
          borderColor:
            status === "BOURSE" && transport?.groupType === "PUBLIC"
              ? Palette.yellow // Bourse Public => Jaune
              : // Bourse Private => Violet
              status === "BOURSE" && transport?.groupType === "PRIVATE"
              ? Palette.purple
              : STATUS[status]?.style?.borderColor || "#C0D7FF",
          overflow: "hidden",
          borderRadius: 15,
          ...containerStyle
        }}
      >
        <div className={"containerRow"}>
          <div
            className={"container"}
            style={{
              width: 80,
              boxSizing: "border-box",
              padding: 10,
              borderRight: `2px solid`,
              fontSize: 11,
              fontWeight: 700,
              justifyContent: "center",
              textAlign: "center",
              borderColor: containerStyle?.borderColor || "#C0D7FF",
              ...(attribute ? { backgroundColor: attribute.color } : {})
            }}
          >
            {isBourse
              ? groups.find((g) => g.id === transport.bourseGroup)?.name ||
                "PUBLIC"
              : `${patient?.gender === "male" ? "M" : "Mme"} ${
                  patient?.firstName
                } ${patient?.lastName}`}
          </div>
          <div
            className={"containerRowSpaceBetween flex flex-col"}
            style={{ padding: 10, boxSizing: "border-box" }}
          >
            <div
              className={"container flex items-end flex-row h-10"}
              style={{}}
            >
              <div
                style={{
                  fontSize: 15,
                  fontWeight: 500,
                  color: Palette.blue,
                  lineHeight: 1,
                  paddingRight: 3
                }}
              >
                {goToTransportId && transport?.status === "ACCEPTED"
                  ? "-"
                  : moment(startDate).format("HH:mm")}
              </div>
              <div
                onClick={() => {
                  handleClickFrom();
                }}
                style={{
                  position: "relative",
                  minWidth: 100,
                  maxWidth: 100,
                  overflow: "hidden",
                  fontSize: 13,
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                  color: "blue",
                  paddingInline: "4px",
                  lineHeight: 1
                }}
              >
                {selectedItem === "from" ? (
                  <MarqueeText duration={4} pauseOnHover direction="right">
                    {from.name}
                  </MarqueeText>
                ) : selectedItem === "from" ? (
                  from.name
                ) : from.name.length > 12 ? (
                  from.name.substring(0, 12) + "..."
                ) : (
                  from.name
                )}
              </div>
              <div
                style={{ fontSize: 12, color: Palette.salmon }}
                className="flex whitespace-nowrap "
              >
                <p
                  style={{ lineHeight: 1 }}
                >{`${from.postalCode} ${from.city}`}</p>
              </div>
            </div>
            <div
              className={"container"}
              style={{ alignItems: "center", marginBlock: 8 }}
            >
              <img
                src={`/icons/${
                  carType === "AMBULANCE" ? "ambulance" : "car"
                }.png`}
                style={{ width: 21, height: 21, position: "absolute" }}
              />
              <ItinaryLine horizontal size={16} width={60} regulator />
              {!goToTransportId ? (
                <div
                  style={{
                    fontSize: 12,
                    color: Palette.red,
                    fontWeight: 500
                  }}
                >
                  Aller
                  {!!returnTransportId &&
                    (transport?.status === "PENDING" ||
                      transport?.status === "ACCEPTED") &&
                    transport?.isAssign !== true &&
                    " - Retour"}
                  {!transport?.returnTransportId ? " Simple" : ""}
                </div>
              ) : (
                <div
                  style={{
                    fontSize: 12,
                    color: Palette.red,
                    fontWeight: 500
                  }}
                >
                  Retour
                </div>
              )}
            </div>
            <div
              className={"container flex items-end flex-row h-10"}
              style={{}}
            >
              <div
                style={{
                  fontSize: 15,
                  fontWeight: 500,
                  color: Palette.blue,
                  lineHeight: 1,
                  paddingRight: 3
                }}
              >
                {goToTransportId && transport?.status === "ACCEPTED"
                  ? "-"
                  : moment(endDate).format("HH:mm")}
              </div>
              <div
                onClick={() => {
                  handleClickFrom();
                }}
                style={{
                  position: "relative",
                  minWidth: 100,
                  maxWidth: 100,
                  overflow: "hidden",
                  fontSize: 13,
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                  color: "blue",
                  paddingInline: "4px",
                  lineHeight: 1
                }}
              >
                {selectedItem === "to" ? (
                  <MarqueeText duration={4} pauseOnHover direction="right">
                    {to.name}
                  </MarqueeText>
                ) : selectedItem === "to" ? (
                  to.name
                ) : to.name.length > 12 ? (
                  to.name.substring(0, 12) + "..."
                ) : (
                  to.name
                )}
              </div>
              <div
                style={{ fontSize: 12, color: Palette.salmon }}
                className="flex whitespace-nowrap "
              >
                <p style={{ lineHeight: 1 }}>{`${to.postalCode} ${to.city}`}</p>
              </div>
            </div>
          </div>

          {transport?.assignedTo &&
            ["PENDING", "ACCEPTED"].includes(transport?.driverStatus) && (
              <img
                src={`/icons/${
                  transport?.driverStatus === "PENDING"
                    ? "iconHourglass"
                    : "iconChecked"
                }.png`}
                style={{
                  position: "absolute",
                  right: transport?.driverStatus === "PENDING" ? 226 : 232,
                  top: transport?.driverStatus === "PENDING" ? -5 : 0,
                  width: transport?.driverStatus === "PENDING" ? 34 : 24,
                  height: transport?.driverStatus === "PENDING" ? 34 : 24,
                  padding: 5
                }}
              />
            )}
          <div
            onClick={() => {
              history.push({
                search: `?transport=${id}`
              });
            }}
            style={{ position: "absolute", left: 5, top: 5 }}
          >
            <div
              style={{
                padding: 2,
                width: 10,
                height: 10,
                borderColor: Palette.black,
                borderWidth: 2,
                borderStyle: "solid",
                backgroundColor: Palette.grey,
                borderRadius: 20,
                color: Palette.black,
                textAlign: "center",
                fontWeight: 700,
                fontSize: 10
              }}
            >
              i
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
