import React from "react";
import ReactTooltip from "react-tooltip";
import Colors from "../styles/Colors";
import Palette from "../styles/Palette";

const sizes = {
  big: {
    height: 58,
    fontSize: 16,
    fontWeight: "600"
  },
  small: {
    height: 44,
    fontSize: 14,
    fontWeight: "600"
  },
  icon: {
    height: 44,
    width: 44,
    padding: 0
  },
  smallIcon: {
    height: 32,
    width: 32,
    fontSize: 24,
    padding: 0
  }
};

const variants = {
  primary: {
    backgroundColor: Palette.blue,
    color: Palette.white
  },
  primaryError: {
    backgroundColor: Palette.red,
    color: Palette.white
  },
  primarySuccess: {
    backgroundColor: Palette.green,
    color: Palette.white
  },
  primaryInfo: {
    backgroundColor: Palette.salmon,
    color: Palette.white
  },
  secondary: {
    backgroundColor: Palette.blueTranslucent,
    color: Palette.blue
  },
  secondarySuccess: {
    backgroundColor: Palette.greenTranslucent,
    color: Palette.green
  },
  secondaryError: {
    backgroundColor: Palette.redTranslucent,
    color: Palette.red
  },
  secondaryBorder: {
    backgroundColor: "transparent",
    border: `1px solid ${Colors.input.border}`
  },
  icon: {
    backgroundColor: "transparent",
    color: Palette.darkBlue,
    border: "none"
  },
  notifIcon: {
    backgroundColor: "transparent",
    border: "none",
    padding: 0
  },
  transportTrackingBtns: {
    backgroundColor: "transparent",
    border: "solid 2px #F6F7FF",
    padding: 0
  },
  activeFilter: {
    backgroundColor: "#F6F7FF",
    border: "solid 2px #F6F7FF",
    padding: 0
  }
};

export default function Button({
  text,
  onClick,
  fullWidth,
  variant = "primary",
  size = "small",
  style,
  imgLeft,
  imgRight,
  IconLeft,
  IconRight,
  iconStyle,
  containerStyle,
  tooltip,
  imgTop,
  textClassName,
}) {
  return (
    <>
      <button
        data-tip={tooltip}
        data-effect="solid"
        {...{ onClick }}
        style={{
          margin: 0,
          paddingLeft: 20,
          paddingRight: 20,
          width: fullWidth ? "100%" : null,
          cursor: "pointer",
          borderRadius: 5,
          border: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...sizes[size],
          ...variants[variant],
          ...containerStyle,
          ...style,
        }}
      >
        {imgTop && (
          <img
            src={imgTop}
            style={{
              width: sizes[size].fontSize,
              height: sizes[size].fontSize,
              ...iconStyle
            }}
          />
        )}
        {imgLeft && (
          <img
            src={imgLeft}
            style={{
              width: sizes[size].fontSize,
              height: sizes[size].fontSize,
              marginRight: text ? 10 : 0,
              ...iconStyle
            }}
          />
        )}
        {IconLeft && (
          <IconLeft
            color={iconStyle ? iconStyle.color : variants[variant].color}
            size={iconStyle ? iconStyle.size : sizes[size].fontSize}
            style={{ marginRight: text ? 10 : 0, ...iconStyle }}
          />
        )}
        <span className={textClassName}>{text}</span>
        {imgRight && (
          <img
            src={imgRight}
            style={{
              width: sizes[size].fontSize,
              height: sizes[size].fontSize,
              marginLeft: text ? 10 : 0,
              ...iconStyle
            }}
          />
        )}
        {IconRight && (
          <IconRight
            color={iconStyle ? iconStyle.color : variants[variant].color}
            size={iconStyle ? iconStyle.size : sizes[size].fontSize}
            style={{ marginLeft: text ? 5 : 0, ...iconStyle }}
          />
        )}
      </button>
      <ReactTooltip />
    </>
  );
}
